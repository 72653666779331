//UTILITIES
import { useContext, useEffect, useRef } from "react";

import { Outlet } from "react-router-dom";
import { SideBarContext } from "../context/SideBarContext";
import LandingFooter from "./LandingFooter";
import { LandingNavbar } from "./LandingNavbar";
//COMPONENT
import NavigationBar from "./NavigationBar";

//CSS
import "./NavigationBar.scss";

export function LayoutWithNavbar(props) {
  const { sideBar, setSideBar } = useContext(SideBarContext);
  const { landing, authentication } = props;
  const outletRef = useRef(null);

  const handleClick = (event) => {
    if (event.type === "click") {
      // Check if click occurred inside sidebar
      if (outletRef.current && outletRef.current.contains(event.target)) {
        // 👇️ call submit function here
        setSideBar(false);
      }
    }
  };

  useEffect(() => {
    // Add event listener to sidebar element
    outletRef.current.addEventListener("click", handleClick);

    // Remove event listener on component unmount
    return () => {
      if (outletRef.current) {
        outletRef.current.removeEventListener("click", handleClick);
      }
    };
  }, []);

  return (
    <div>
      <div>
        {landing ? (
          <LandingNavbar />
        ) : (
          <NavigationBar authentication={authentication} />
        )}
      </div>

      <div
        className={`navigations-bar-outlet ${
          !authentication
            ? landing
              ? "landing-background"
              : `portal-component ${sideBar ? "sideOpen" : "sideClose"}`
            : "authentication-component"
        }`}
      >
        <div ref={outletRef}>
          <Outlet />
        </div>
        {landing && (
          <div className="landing-footer-component">
            <LandingFooter />
          </div>
        )}
      </div>
    </div>
  );
}
export default LayoutWithNavbar;
