//REACT
import React from "react";
import PropTypes from "prop-types";
import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import palette from "../../assets/theme/color.scss";
import TableCustomHeader from "./TableHeader";

import NoDataFound from "../response-messages/NoDataFound";
import LoaderComponent, {
  TableSkeleton,
} from "../loader-component/LoaderComponent";

//CSS
import "./TableComponent.scss";
import { dataPerPage } from "../../core/variables/CoreVariables";

const TableComponent = (props) => {
  const {
    children,
    requestSort,
    sorted,
    orderBy,
    tableHeaders,
    actions,
    tableData,
    sortedBy,
    loading,
    handleAdd,
  } = props;

  //FUNCTIONS

  const handleSortRequest = () => {
    if (sorted) requestSort();
  };

  return (
    <div className="table-section">
      <Paper className="table-paper">
        <TableContainer
          className="table-container-component"
          sx={{
            "&::-webkit-scrollbar":
              tableData?.length !== 0
                ? {
                    width: 4,
                    height: 5,
                  }
                : { display: "none" },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              background: palette.lightPrimaryColor,
              borderRadius: 25,
            },
          }}
        >
          <Table stickyHeader className="initial-table">
            <TableCustomHeader
              sorted={sorted}
              requestSort={handleSortRequest}
              orderBy={orderBy}
              sortedBy={sortedBy}
              headers={tableHeaders}
              actions={actions}
              onAdd={handleAdd}
            />

            {!loading && (
              <TableBody {...props}>
                {tableData?.length !== 0 ? (
                  children
                ) : (
                  <NoDataFound normal={false} />
                )}
              </TableBody>
            )}
          </Table>
          {loading && <TableSkeleton count={dataPerPage} />}
        </TableContainer>
      </Paper>
    </div>
  );
};

export default TableComponent;

TableComponent.defaultProps = {
  actions: true,
  loading: false,
};
TableComponent.propTypes = {
  actions: PropTypes.bool,
  loading: PropTypes.bool,
  tableHeaders: PropTypes.array,
};
