//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useForm, Controller } from "react-hook-form";
import { AlertContext } from "../../../context/AlertContext";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
//COMPONENT
import { getAllCountries } from "../../../core/apis/address";
import { PrimaryButton } from "../../../components/buttons/Buttons";
import {
  FormDropdownList,
  FormInput,
  FormPassword,
  FormPhoneInput,
} from "../../../components/form-component/FormComponent";
import TitleComponent from "../../../components/title-component/TitleComponent";
//CSS
import "./SignUp.scss";

//API
import { RegisterUser } from "../../../core/apis/users";
import { Card, CardContent } from "@mui/material";
import { UserContext } from "../../../context/UserContext";
import {
  nameRequiredLimited,
  passwordSchema,
  phoneNumberSchema,
} from "../../../core/validators/form-validators";


export function SignUp( props ) {
  //VARIABLES
  let navigate = useNavigate();
  const tokenId = useParams()?.token;
  const { updateUser } = useContext( UserContext );
  const { setAlert } = useContext( AlertContext );
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ loadingCountryData, setLoadingCountryData ] = useState( false );
  const [ countryData, setCountryData ] = useState( [] );
  const [ cookies, setCookie ] = useCookies( [ "session", "isSubscribed" ] );


  const HandleSchema = yup.object( {
    first_name: nameRequiredLimited( true, 255 ),
    last_name: nameRequiredLimited( true, 255 ),
    country: yup.object().nullable().required( "Field is required" ),
    phoneNumber: phoneNumberSchema,
    password: passwordSchema,
    password_confirmation: yup
      .string( "Invalid Field" )
      .oneOf( [ yup.ref( "password" ), null ], "Passwords must match" )
      .required( "Field is required" ),
  } );

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm( {
    resolver: yupResolver( HandleSchema ),
  } );

  //FUNCTIONS

  const handleSubmitForm = ( formData ) => {
    setSaveLoading( true );

    let user = {
      token: tokenId.substring( 6 ),
      first_name: formData.first_name,
      last_name: formData.last_name,
      password: formData.password,
      password_confirmation: formData.password_confirmation,
      phone_number: formData.phoneNumber,
      country_slug: formData.country.slug,
      // fiscal_number:"",
      is_company: false,
    };

    if ( user.is_company ) {
      user.company_name = "";
    }

    RegisterUser( user ).then( async ( response ) => {
      if ( response?.data?.success ) {
        setCookie( "session", response?.data?.success, { path: "/" } );
        await updateUser();
        navigate( "/subscriptions" );
      }
      setSaveLoading( false );
      setAlert( {
        text: response.data ? response.data.message : response.message,
        error: response?.data?.success ? false : true,
      } );

      setSaveLoading( false );
    } );
  };

  const keyDownHandler = ( event ) => {
    if ( event.key === "Enter" ) {
      event.preventDefault();

      // 👇️ call submit function here
      handleSubmit();
    }
  };
  const GetCountryData = () => {
    setLoadingCountryData( true );
    getAllCountries().then( ( response ) => {
      if ( response?.data?.success ) {
        setCountryData( response.data.data ? response.data.data : [] );
      }

      setLoadingCountryData( false );
    } );
  };

  useEffect( () => {
    GetCountryData();
    reset( {
      first_name: "",
      last_name: "",
      country: null,
      phoneNumber: null,
      password: "",
      password_confirmation: "",
    } );
  }, [] );

  return (
    <div className="page-container">
      <Card className="signup-container">
        <CardContent className="content">
          <TitleComponent
            title="Sign Up"
            classNames="form-title main-title"
          />
          <form onSubmit={ handleSubmit( handleSubmitForm ) } className="signup-form">
            <div className="signupform-inputs-double-children">

              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormInput
                    required
                    isAuth
                    name="first_name"
                    label="First Name"
                    placeholder={ "Enter first name" }
                    value={ value }
                    onChange={ ( e ) => {
                      onChange( e );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="first_name"
                control={ control }
              />


              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormInput
                    required
                    isAuth
                    name="last_name"
                    label="Last Name"
                    placeholder={ "Enter last name" }
                    value={ value }
                    onChange={ ( e ) => {
                      onChange( e );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="last_name"
                control={ control }
              />



              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormDropdownList
                    required
                    isAuth
                    data={ countryData || [] }
                    name="country"
                    label="Country"
                    loading={ loadingCountryData }
                    placeholder={ "Enter country" }
                    value={ value }
                    onChange={ ( e ) => {
                      onChange( e );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="country"
                control={ control }
              />



              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormPhoneInput
                    required
                    isAuth
                    name="phoneNumber"
                    label="Phone Number"
                    placeholder={ "Enter phone number" }
                    value={ value }
                    onChange={ ( e ) => {
                      onChange( e );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="phoneNumber"
                control={ control }
              />

              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormPassword
                    required
                    isAuth
                    name="password"
                    label="Password"
                    placeholder={ "Enter password" }
                    value={ value }
                    onChange={ ( e ) => {
                      onChange( e );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name={ "password" }
                control={ control }
              />


              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormPassword
                    required
                    isAuth
                    name="password_confirmation"
                    label="Confirm Password"
                    placeholder={ "Enter password" }
                    value={ value }
                    onChange={ ( e ) => {
                      onChange( e );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="password_confirmation"
                control={ control }
              />

            </div>

            <div className="flex-column-wrap signup-action">
              <PrimaryButton
                type="submit"
                text={ "Register" }
                className="signup-button"
                loading={ saveLoading }
                disabled={ saveLoading ? true : false }
              />
              <div className="account-question">
                Already have an account?{ " " }
                <span className="action" onClick={ () => navigate( "/login" ) }>
                  Login
                </span>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default SignUp;
