import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { CardHandle } from "./CardHandle";
import { StripePublishKey } from "../../../core/apis/main";
import { loadStripe } from "@stripe/stripe-js";


const stripePromise = loadStripe( StripePublishKey );


export function StripeContainer( props ) {

    return (
        <Elements stripe={ stripePromise }  >
            <ElementsConsumer>
                { ( { stripe, elements } ) => (
                    <CardHandle { ...props } stripe={ stripe } elements={ elements } />
                ) }

            </ElementsConsumer>

        </Elements>


    )
}

export default StripeContainer;