//UTILITIES
import React, { useState } from "react";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogContent } from "@mui/material";
import { DeleteSVG, PlusIcon, RemoveSVG } from "../../../assets/svg/SVG";

import {
  IconsButton,
  PrimaryButton,
} from "../../../components/buttons/Buttons";
import DialogCustomTitle from "../../../components/dialog-component/DialogCustomTitle";
import { FormInput } from "../../../components/form-component/FormComponent";

//CSS
import "./Sessions.scss";
import ResponseMessage from "../../../components/response-messages/ResponseMessage";
import { SceneURL } from "../../../core/apis/main";
import { sendSessionInvitation } from "../../../core/apis/session";
import { emailSchema } from "../../../core/validators/form-validators";

const HandleSchema = yup.object( {
  emails: yup.array().of(
    yup.object().shape( {
      email: emailSchema,
    } )
  ),
} );

export function SessionInvitation( props ) {
  //VARIABLES
  const { onClose, data, handleResponse, handleStartResponse } = props;
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ message, setMessage ] = useState( "" );
  //INITIATE USE FORM
  const { control, handleSubmit } = useForm( {
    defaultValues: {
      emails: [
        {
          email: "",
        },
      ],
    },
    resolver: yupResolver( HandleSchema ),
  } );

  const {
    fields: emailsFields,
    remove: emailsRemove,
    append: emailsAppend,
  } = useFieldArray( { control, name: "emails" } );

  //FUNCTIONS

  let handleAddFieldOption = () => {
    emailsAppend( { email: "" } );
  };
  let handleRemoveFieldOption = ( i ) => {
    emailsRemove( i );
  };

  const handleSubmitForm = ( formData ) => {
    setSaveLoading( true );
    sendSessionInvitation( {
      emails: formData.emails?.map( ( d ) => d.email ),
      session_link: SceneURL + data?.id,
      session_name: data.name,
    } ).then( ( response ) => {
      if ( response?.data?.success ) {
        handleResponse( response.data.message );
      } else {
        setMessage( response.data ? response.data.message : response.message );
      }
      setSaveLoading( false );
    } );
  };

  return (
    <>
      <Dialog disablebackdropclick="true" fullWidth open={ true }>
        <DialogCustomTitle
          title={ "Session invitation" }
          onClose={ () => onClose() }
        />

        <DialogContent>
          <form onSubmit={ handleSubmit( handleSubmitForm ) }>
            <>
              { emailsFields.map( ( element, index ) => (
                <div
                  className="dynamic-field-inputs"
                  key={ `${ index }-${ emailsFields?.length }` }
                >
                  <div className="dynamic-field-form-input">
                    <Controller
                      render={ ( {
                        field: { onChange, value },
                        fieldState: { error },
                      } ) => (
                        <FormInput
                          required
                          name={ `emails.${ index }.email` }
                          label={ `Email` }
                          placeholder={ "Enter email" }
                          value={ value }
                          onChange={ ( value ) => onChange( value ) }
                          helperText={ error?.message }
                        />
                      ) }
                      name={ `emails.${ index }.email` }
                      control={ control }
                    />
                  </div>
                  { emailsFields.length > 1 ? (
                    <div className="dynamic-field-delete-icon">
                      <IconsButton
                        icon={ <DeleteSVG /> }
                        onClick={ () => handleRemoveFieldOption( index ) }
                      />
                    </div>
                  ) : null }
                </div>
              ) ) }
              <div className="dynamic-field-option-section">
                <div>
                  <PrimaryButton
                    text={ "New Email" }
                    onClick={ handleAddFieldOption }
                  />
                </div>
              </div>
            </>
            <ResponseMessage message={ message } />
            <div className="form-invite-session-footer">
              <PrimaryButton
                text={ "Start session" }
                onClick={ () => handleStartResponse( data ) }
              />
              <PrimaryButton
                variant={ "outlined" }
                text="Cancel"
                onClick={ () => onClose() }
              />
              <PrimaryButton
                text={ "Send" }
                type="submit"
                loading={ saveLoading }
                disabled={ saveLoading ? true : false }
              />
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default SessionInvitation;
