import { DialogTitle, IconButton } from "@mui/material";
import { CloseIcon } from "../../assets/svg/SVG";
import React from "react";
import "./DialogComponent.scss";
export function DialogCustomTitle(props) {
  return (
    <>
      <DialogTitle>
        <div className="dialog-title-section">
          <div>{props.title}</div>
          <div>
            <IconButton aria-label="close" onClick={() => props.onClose()}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
    </>
  );
}
export default DialogCustomTitle;
