import { api } from "./main";
import { APIURL } from "./main";

export const getSubscriptionByID = (payload) => {
  return api
    .get(APIURL + `/admin/get-subscriptions-list-by-id/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllActivePlans = () => {
  return api
    .get(APIURL + "/plans")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getPlanBySlug = (slug) => {
  return api
    .get(APIURL + "/show-plan/" + slug)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const subscribePlan = (body) => {
  return api
    .post(APIURL + "/subscribe", body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const renewPlan = (body) => {
  return api
    .post(APIURL + "/renew", body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const upgradeSubscription = (body) => {
  return api
    .post(APIURL + "/upgrade-subscription", body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const autoRenewPlan = (payload) => {
  return api
    .patch(APIURL + `/auto-renew/${payload?.id}`, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSelfSubscription = () => {
  return api
    .get(APIURL + "/self-subscription")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const checkPromoCodeValidity = (payload) => {
  return api
    .post(APIURL + "/auth/check-promo-code", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const AutoRenewSubscription = (payload) => {
  return api
    .put(APIURL + "/admin/cancel-resume-auto-renew", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const planUsage = (payload) => {
  return api
    .get(APIURL + "/plan-usages", { params: { ...payload } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
