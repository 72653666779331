//UTILITIES
import React from "react";

//COMPONENT
import { PageNotFoundSVG } from "../../assets/svg/SVG";
import "./ResponsePages.scss";
export function NoPageFound(props) {
  return (
    <div className="page-not-found">
      <PageNotFoundSVG />
    </div>
  );
}

export default NoPageFound;
