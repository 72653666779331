//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { SideBarContext } from "../../../context/SideBarContext";
//COMPONENT
import { TrialModal } from "./SubscriptionMessages";
import TitleComponent from "../../../components/title-component/TitleComponent";
import SubscriptionsCards from "../../../components/subscription-card/SubscriptionsCards";
import { Swiper, SwiperSlide } from "swiper/react";
//CSS

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "./Subscriptions.scss";
import SwiperCore, {
  EffectFlip,
  FreeMode,
  Navigation,
  Pagination,
} from "swiper";

//API
import {
  subscribePlan,
  getAllActivePlans,
  upgradeSubscription,
} from "../../../core/apis/subscriptions";

import { useNavigate } from "react-router-dom";
import { PlansSkeleton } from "../../../components/loader-component/LoaderComponent";
import { SubscriptionContext } from "../../../context/SubscriptionContext";

SwiperCore.use( [ EffectFlip, FreeMode, Navigation, Pagination ] );

function useSwiperBreakpoints() {
  let { sideBar } = useContext( SideBarContext );

  const [ breakpoints, setBreakPoints ] = useState( {
    1500: {
      slidesPerView: 3,
    },

    900: {
      slidesPerView: 3,
    },
    700: {
      slidesPerView: 2,
    },
    600: {
      slidesPerView: 2,
    },
    0: {
      slidesPerView: 1,
    },
  } );

  useEffect( () => {
    setBreakPoints( {
      1500: {
        slidesPerView: 3,
      },

      900: {
        slidesPerView: sideBar ? 2 : 3,
      },
      700: {
        slidesPerView: sideBar ? 1 : 2,
      },
      600: {
        slidesPerView: 2,
      },
      0: {
        slidesPerView: 1,
      },
    } );
  }, [ sideBar ] );

  return breakpoints;
}

export function Subscriptions( props ) {
  let { updateUser, user } = useContext( UserContext );
  let { updateSubscriptionItems } = useContext( SubscriptionContext )
  let navigate = useNavigate();
  const [ plans, setPlans ] = useState( [] );
  const [ loading, setLoading ] = useState( false );
  const [ loadingButton, setLoadingButton ] = useState( false );
  const [ openTrialModal, setOpenTrialModal ] = useState( false );
  const [ selectedPlan, setSelectedPlan ] = useState( null );
  const breakpoints = useSwiperBreakpoints();
  const [ freeTrialSpec, setFreeTrialSpec ] = useState( {
    isUpgrade: false,
    hasFreeTrial: false,
  } );
  const [ upgradeNow, setUpgradeNow ] = useState( false );
  let { sideBar } = useContext( SideBarContext );
  const key = sideBar ? "sidebar" + Math.random() : "nosidebar" + Math.random();

  const GetData = () => {
    setLoading( true );
    try {
      getAllActivePlans().then( ( res ) => {
        if ( res?.data?.success ) {
          setPlans( res.data.data );
        }
        setLoading( false );
      } );
    } catch ( e ) {
      setLoading( false );
    }
  };

  useEffect( () => {
    GetData();
  }, [] );

  const SubscribeToPlan = ( planItem, upgradeNow, freeTrialSpec ) => {
    let plan_price_id = planItem.plan_price.reduce( ( prev, current ) => {
      return prev.price < current.price ? prev : current;
    } ).id;
    try {
      setLoadingButton( true );
      if ( freeTrialSpec?.isUpgrade ) {
        if ( freeTrialSpec?.hasFreeTrial ) {
          upgradeSubscription( {
            plan_price_id: plan_price_id,
            ...( freeTrialSpec?.isUpgrade && { upgrade_now: upgradeNow } ),
          } ).then( async ( res ) => {
            if ( res?.data?.success ) {
              await updateUser();
              updateSubscriptionItems() //update plan usage api
              CloseTrialModal();
              navigate( "/user-subscriptions" );
            }
            setLoadingButton( false );
          } );
        } //IF plan doesn't have free trial => the user has to pay even if upgrade now or not
        else {
          navigate( `/checkout/upgrade/${ planItem.slug }` );
          setLoadingButton( false );
        }
      } else
        subscribePlan( {
          plan_price_id: plan_price_id,
        } ).then( async ( res ) => {
          if ( res?.data?.success ) {
            await updateUser();
            updateSubscriptionItems()//update plan usage api
            CloseTrialModal();
            navigate( "/sessions" );
          }
          setLoadingButton( false );
        } );
    } catch ( error ) {
      setLoadingButton( false );
    }
  };

  const OpenTrialModal = ( plan, isUpgrade, hasFreeTrial ) => {
    setSelectedPlan( plan );
    setFreeTrialSpec( { isUpgrade: isUpgrade, hasFreeTrial: hasFreeTrial } );
    setOpenTrialModal( true );
  };

  const CloseTrialModal = () => {
    setSelectedPlan( null );
    setFreeTrialSpec( { isUpgrade: false, hasFreeTrial: false } );
    setOpenTrialModal( false );
  };

  return (
    <div className="subscriptions">
      <div
        style={ {
          display: user?.latest_subscription && "flex",
          marginBottom: "3%",
        } }
      >
        <TitleComponent title="Our Plans" classNames="form-title main-title" />
      </div>
      { loading ? (
        <PlansSkeleton />
      ) : (
        <Swiper
          spaceBetween={ 30 }
          freeMode={ true }
          key={ key }
          navigation={ true }
          breakpoints={ breakpoints }
        >
          { plans?.map( ( plan, index ) => {
            return (
              <SwiperSlide
                key={ index }
                className={ sideBar === true ? "swiper-subscription" : "" }
              >
                <SubscriptionsCards
                  plan={ plan }
                  handleOpenFreeTrial={ OpenTrialModal }
                  userSubscription={ user?.latest_subscription }
                  isSubscribed={ user?.latest_subscription ? true : false }
                />
              </SwiperSlide>
            );
          } ) }
        </Swiper>
      ) }
      { openTrialModal && (
        <TrialModal
          onClose={ () => setOpenTrialModal( false ) }
          closeModal={ CloseTrialModal }
          proceed={ SubscribeToPlan }
          plan={ selectedPlan }
          loading={ loadingButton }
          setUpgradeNow={ setUpgradeNow }
          upgradeNow={ upgradeNow }
          freeTrialSpec={ freeTrialSpec }
        />
      ) }
    </div>
  );
}

export default Subscriptions;
