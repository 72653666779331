//UTLITIES
import { useContext, useRef, useState } from "react";
import * as yup from "yup";
import { AlertContext } from "../../../context/AlertContext";
import { UserContext } from "../../../context/UserContext";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox, FormControlLabel } from "@mui/material";
import { PrimaryButton } from "../../../components/buttons/Buttons";
import {
    FormInput,
    FormStripeCardElement,
} from "../../../components/form-component/FormComponent";
import { SaveCard } from "../../../core/apis/cardManagement";
import { NoticeDialog } from "../../../assets/theme/SpecialComponent";
import DialogCustomTitle from "../../../components/dialog-component/DialogCustomTitle";
import { nameRequiredLimited } from "../../../core/validators/form-validators";


const HandleSchema = yup.object( {
    card_name: nameRequiredLimited( true, 255 ),
    card: yup.string().nullable().required( 'field is required' ),
    default: yup.boolean().default( true ),
    save_card: yup.boolean().default( true ),
} );

//card handle component is to add card using stripe field
export function CardHandle( { closeModal,
    is_checkout,
    formValues,
    handleUnsavedCard,
    handleSaveCard,
} ) {
    const card = useRef();
    const { setAlert } = useContext( AlertContext );
    const { updateUser } = useContext( UserContext );
    const [ saveLoading, setSaveLoading ] = useState( false );
    const stripe = useStripe();
    const elements = useElements();
    const { control, handleSubmit, setError, clearErrors } = useForm( {
        defaultValues: {
            card_name: "",
            default: true,
            card: "",
            save_card: true,
        },
        resolver: yupResolver( HandleSchema ),
    } );


    const handleSubmitForm = async ( formData ) => {
        setSaveLoading( true )
        // we need the payment method id to send the card details securely
        await stripe.createPaymentMethod( {
            type: "card",
            billing_details: {
                name: formData?.card_name,
            },
            card: elements.getElement( CardElement ),
        } ).then( ( StripeRes ) => {
            if ( !StripeRes?.error ) {

                try {
                    const { id } = StripeRes?.paymentMethod
                    //if not checkout:card management or is checkout and user wants to save card

                    if ( formData?.save_card || !is_checkout ) {


                        setSaveLoading( true );
                        // send payment method id to backend to attach it to an existing customer
                        SaveCard( {
                            payment_method_slug: "card",
                            stripe_payment_method_id: id,
                            default: true,
                        } ).then( ( res ) => {
                            if ( res?.data?.success ) {
                                if ( is_checkout ) {
                                    handleSaveCard( {
                                        ...formValues,
                                        card: { ...StripeRes?.paymentMethod?.card, name: StripeRes?.paymentMethod?.billing_details?.name, id: res?.data?.data?.id },
                                    } );
                                } else {
                                    handleSaveCard();

                                }
                                updateUser();
                                closeModal();
                            }
                            setAlert( {
                                text: res.data ? res.data.message : res.message,
                                error: !res?.data?.success,
                            } );
                            setSaveLoading( false );
                        } );
                    } else { //user doesn't want to save card
                        handleUnsavedCard( {
                            ...formValues,
                            card: {
                                ...StripeRes?.paymentMethod?.card,
                                name: StripeRes?.paymentMethod?.billing_details?.name,
                                payment_method_id: id,
                            },

                        } )
                    }
                } catch ( e ) {
                    setSaveLoading( false );
                }
            } else {
                setAlert( {
                    text: StripeRes?.error?.message,
                    error: true,
                } );
                setSaveLoading( false );
            }
        } )
    }

    const handleCardElementChange = ( e ) => {
        //if stripe gives error then display it using useForm error messages
        if ( e?.error ) {
            setError( "card", {
                type: "custom",
                message: e?.error?.message,
            } );
        } else if ( !e?.complete ) {
            setError( "card", {
                type: "custom",
                message: "Field is required",
            } );
        } else {
            clearErrors( 'card' )
        }
    }

    return (
        <NoticeDialog open={ true }>
            <DialogCustomTitle
                title="Credit Card Details"
                onClose={ closeModal }
            />

            <form onSubmit={ handleSubmit( handleSubmitForm ) }>

                <Controller
                    render={ ( { field: { onChange, value }, fieldState: { error } } ) => (
                        <FormInput
                            isAuth
                            required
                            name="card_name"
                            label="Cardholder Name"
                            placeholder={ "Enter cardholder name" }
                            value={ value }
                            onChange={ ( e ) => {
                                onChange( e );
                            } }
                            helperText={ error?.message }
                        />
                    ) }
                    name="card_name"
                    control={ control }
                />

                <Controller
                    name="card"
                    defaultValue={ true }
                    control={ control }
                    render={ ( { field: { onChange, }, fieldState: { error } } ) => {
                        return (
                            <FormStripeCardElement ref={ card } helperText={ error?.message } label='Card Information' required onChange={ ( e ) => {
                                onChange( e?.complete ? "card" : "" )
                                handleCardElementChange( e )
                            } } />

                        );
                    } }
                />

                { is_checkout && (
                    <Controller
                        name="save_card"
                        defaultValue={ true }
                        control={ control }
                        render={ ( { field: { onChange, value }, fieldState: { error } } ) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={ value }
                                            onChange={ ( e ) => onChange( e ) }
                                            name="save_card"
                                        />
                                    }
                                    label="Save Card"
                                />
                            );
                        } }
                    />
                ) }
                <div className="modal-button-action">
                    <PrimaryButton
                        variant={ "outlined" }
                        onClick={ closeModal }
                        text={ "Cancel" }
                        className="save-button"
                    />
                    <PrimaryButton
                        type="submit"
                        text={ "Save" }
                        className="save-button"
                        loading={ saveLoading }
                        disabled={ saveLoading ? true : false }
                    />
                </div>
            </form>
        </NoticeDialog>


    )
}

export default CardHandle;