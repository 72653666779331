//UTILITIES
import { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../../context/AlertContext";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//COMPONENT
import { Skeleton } from "@mui/material";
import { PrimaryButton } from "../../../components/buttons/Buttons";
import {
  FormDropdownList,
  FormInput,
} from "../../../components/form-component/FormComponent";
import { Card, CardContent } from "@mui/material";

//APIs
import { getAllAddresses, getAllCountries } from "../../../core/apis/address";
import {
  getAddressById,
  createAddress,
  updateAddress,
} from "../../../core/apis/address";

const HandleSchema = yup.object( {
  address_line: yup
    .string().nullable()
    .test(
      "len",
      "Field must be of max 100 characters",
      ( val ) => val && val.length <= 100
    )
    .required( "Field is required" ),
  city: yup
    .string().nullable()
    .test(
      "len",
      "Field must be of max 100 characters",
      ( val ) => val && val.length <= 100
    )
    .required( "Field is required" ),
  state: yup
    .string().nullable()
    .test(
      "len",
      "Field must be of max 100 characters",
      ( val ) => val && val.length <= 100
    )
    .required( "Field is required" ),
  postal_code: yup
    .string().nullable()
    .test(
      "len",
      "Field must be of max 100 characters",
      ( val ) => val && val.length <= 100
    )
    .required( "Field is required" ),
  country_slug: yup.object().nullable().required( "Field is required" ),
  default: yup.boolean().default( true ),
} );

export function AddressHandle( {
  is_checkout,
  setFormValues,
  formValues,
  handleStep,
} ) {
  const { setAlert } = useContext( AlertContext );
  const [ loading, setLoading ] = useState( false );
  const [ loadingCountryData, setLoadingCountryData ] = useState( false );
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ address_id, setAddress_id ] = useState( null );
  const [ countryData, setCountryData ] = useState( [] );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm( {
    resolver: yupResolver( HandleSchema ),
  } );

  const GetCountryData = () => {
    setLoadingCountryData( true );
    getAllCountries().then( ( res ) => {
      if ( res.data?.success ) {
        setCountryData( res.data.data ? res.data.data : [] );
      }
      setLoadingCountryData( false );
    } );
  };

  const GetAddressData = ( id ) => {
    getAddressById( id ).then( ( res ) => {
      if ( res?.data?.success ) {
        reset( {
          country_slug: res.data.data.country,
          address_line: res.data.data.address_line,
          city: res.data.data.city,
          state: res.data.data.state,
          postal_code: res.data.data.postal_code,
        } );
      }
      setLoading( false );
    } );
  };

  const nextStep = ( id ) => {
    setFormValues( {
      ...formValues,
      address_id: id,
    } );
    handleStep( { next: true } );
  };

  const prevStep = () => {
    handleStep( { next: false } );
  };

  const GetAddress = async () => {
    setLoading( true );
    let all_address = await GetAllAddressData();
    if ( all_address?.length !== 0 ) {
      setAddress_id( all_address?.[ 0 ]?.id );
      GetAddressData( all_address?.[ 0 ]?.id );
    } else {
      setAddress_id( null );
      reset( {
        country_slug: null,
        address_line: "",
        city: "",
        state: "",
        postal_code: "",
      } );
      setLoading( false );
    }
  };

  const GetAllAddressData = async () => {
    return getAllAddresses().then( ( res ) => {
      if ( !res?.data?.success ) {
        setAlert( {
          text: res?.data ? res?.data?.message : res?.message,
          error: res?.data?.success ? false : true,
        } );
      }
      return res?.data?.data;
    } );
  };
  useEffect( () => {
    GetAddress();

    GetCountryData();
  }, [] );

  const handleSubmitForm = ( data ) => {
    let address = {
      ...data,
      country_slug: data.country_slug.slug,
    };

    try {
      setSaveLoading( true );
      if ( address_id ) {
        updateAddress( address_id, address ).then( ( res ) => {
          if ( res?.data?.success ) {
            GetAddress();
            if ( is_checkout ) nextStep( address_id );
          }
          setAlert( {
            text: res.data ? res.data.message : res.message,
            error: !res?.data?.success,
          } );
          setSaveLoading( false );
        } );
      } else
        createAddress( address ).then( ( res ) => {
          if ( res?.data?.success ) {
            GetAddress();
            if ( is_checkout ) nextStep( res.data.data.id );
          }
          setAlert( {
            text: res.data ? res.data.message : res.message,
            error: !res?.data?.success,
          } );
          setSaveLoading( false );
        } );
    } catch ( e ) {
      setSaveLoading( false );
    }
  };

  return loading ? (
    <Skeleton variant="rectangular" style={ { marginTop: "3%" } } height={ 20 } />
  ) : (
    <form onSubmit={ handleSubmit( handleSubmitForm ) }>
      <Card>
        <CardContent>
          <div className="form-inputs-double-children">
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormDropdownList
                  isAuth
                  data={ countryData || [] }
                  name="country_slug"
                  label="Country"
                  loading={ loadingCountryData }
                  placeholder={ "Enter country" }
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="country_slug"
              defaultValue={ null }
              control={ control }
            />

            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  isAuth
                  name="address_line"
                  label="Address"
                  placeholder={ "Enter Address" }
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="address_line"
              control={ control }
            />

            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  isAuth
                  name="city"
                  label="City"
                  placeholder={ "Enter City" }
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="city"
              control={ control }
            />

            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  isAuth
                  name="state"
                  label="State"
                  placeholder={ "Enter State" }
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="state"
              control={ control }
            />

            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  isAuth
                  name="postal_code"
                  label="Postal Code"
                  placeholder={ "Enter Postal Code" }
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="postal_code"
              control={ control }
            />
          </div>
          { !is_checkout && (
            <div className="modal-button-action">
              <PrimaryButton
                variant={ "outlined" }
                onClick={ () => reset() }
                text={ "Cancel" }
                className="save-button"
              />
              <PrimaryButton
                type="submit"
                text={ "Save" }
                className="save-button"
                loading={ saveLoading }
                disabled={ saveLoading ? true : false }
              />
            </div>
          ) }
        </CardContent>
      </Card>
      { is_checkout && (
        <div className="modal-button-action">
          <PrimaryButton
            onClick={ prevStep }
            variant={ "outlined" }
            text={ "Back" }
            className="save-button"
          />
          <PrimaryButton
            type="submit"
            text={ "Proceed" }
            className="save-button"
            loading={ saveLoading }
            disabled={ saveLoading ? true : false }
          />
        </div>
      ) }
    </form>
  );
}
export default AddressHandle;
