import React, { createContext, useState } from "react";

const SideBarContext = createContext({
  sideBar: true,
  setSideBar: () => {},
});

const SideBarContextProvider = (props) => {
  const { children } = props;
  // the value that will be given to the context
  const [sideBar, setSideBar] = useState(false);

  return (
    // the Provider gives access to the context to its children
    <SideBarContext.Provider value={{ sideBar, setSideBar }}>
      {children}
    </SideBarContext.Provider>
  );
};

export { SideBarContext, SideBarContextProvider };
