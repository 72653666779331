//UTILITIES
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "../context/AlertContext";
import moment from "moment";
//MUI
import {
  SideBarDrawer,
  DrawerHeader,
} from "../../src/assets/theme/SpecialComponent";


//COMPONENT
import NoticeConfirmation from "../components/dialog-component/NoticeConfirmation";
import { SideBarItems } from "../core/variables/SideBarItems";
import CustomizedListItem from "../components/side-bar-component/CustomizedListItem";
import logo from "../assets/images/Ceci_tool_white.png";
import { BurgerSVG, LogoutIcon } from "../assets/svg/SideBarSVG";
import {
  ListItem,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

//CSS
import "./NavigationBar.scss";
import { UserLogout } from "../core/apis/users";
import { IconsButton } from "../components/buttons/Buttons";
import { HomeIcon } from "../assets/svg/SVG";
import { UserContext } from "../context/UserContext";
import { SideBarContext } from "../context/SideBarContext";
import isDateExpired from "../core/functions/checkExpiryDate";
import { useCookies } from "react-cookie";

export function NavigationBar( props ) {
  //VARIABLES
  let { authentication } = props;
  const [ cookies, setCookie, removeCookie ] = useCookies( [ "session", "isSubscribed" ] );
  const { sideBar, setSideBar } = useContext( SideBarContext );
  const { setAlert } = useContext( AlertContext );
  let { user, session } = useContext( UserContext );

  let navigate = useNavigate();

  const [ openConfirm, setOpenConfirm ] = React.useState( false );

  const [ notifymessage, setNotifyMessage ] = useState( "" );
  const [ actionmessage, setActionMessage ] = useState( "" );

  const [ sideBarElements, setSideBarElement ] = useState( SideBarItems );

  //FUNCTIONS
  const handleLogOutResponse = ( type, value ) => {
    if ( type === "yes" ) {
      UserLogout().then( ( response ) => {
        if ( response?.data?.success ) {
          removeCookie( 'session' );
          removeCookie( 'isSubscribed' );
          setOpenConfirm( false );
          setSideBar( false );
          navigate( "/login" );
        } else {
          setAlert( {
            text: response.data ? response.data.message : response.message,
            error: true,
          } );

          setOpenConfirm( false );
        }
      } );
    } else {
      setOpenConfirm( false );
    }
  };
  const handleSideBar = () => {
    setSideBar( !sideBar );
  };
  const handleCloseDrawer = () => {
    setSideBar( false );
  };

  const handleLogout = () => {
    setOpenConfirm( true );
  };

  const calculateDifference = ( date ) => {
    const diffTime = Math.abs( new Date() - new Date( date ) );
    const diffDays = Math.ceil( diffTime / ( 1000 * 60 * 60 * 24 ) );

    return `${ diffDays } ${ diffDays === 1 ? 'day' : "days"
      }`

  };
  const SendLinkToHome = () => {
    navigate( "/home" );
  };

  const checkTimeAndUpdate = () => {
    if ( !authentication && session ) {
      const subscription = user?.latest_subscription;

      if ( !subscription ) {
        setNotifyMessage( "You are not subscribed to any plan yet." );
        setActionMessage( "Subscribe" );
      } else {
        const isTrialExpired = isDateExpired( subscription.trial_ends_at );
        const isSubscriptionExpired = isDateExpired( subscription.package_ends_at );



        if ( !subscription.package_starts_at ) {
          const expiryMessage = isTrialExpired
            ? "Your trial period has expired."
            : `Your trial period will expire in ${ calculateDifference( subscription.trial_ends_at ) } (${ moment( subscription.trial_ends_at ).format( "DD-MM-YYYY" ) }).`;

          setNotifyMessage( expiryMessage );
          setActionMessage( isTrialExpired ? "Upgrade" : `Subscribe ${ subscription?.is_latest_subscription ? "" : "or Upgrade" }` );
        } else {
          const renewalOrExpiry = user?.next_plan || subscription.is_auto_renew
            ? "renewed"
            : "expire";

          const expiryMessage = isSubscriptionExpired
            ? "Your subscription period has expired."
            : `Your subscription will ${ renewalOrExpiry } in ${ calculateDifference( subscription.package_ends_at ) } (${ moment( subscription.package_ends_at ).format( "DD-MM-YYYY" ) }).`;

          setNotifyMessage( expiryMessage );
          setActionMessage( isSubscriptionExpired ? `Renew ${ subscription?.is_latest_subscription ? "" : "or Upgrade" }` : ( user?.next_plan || subscription.is_auto_renew ) ? "Check your next plan" : `Renew ${ subscription?.is_latest_subscription ? "" : "or Upgrade" }` );
        }
      }
    }
  };

  useEffect( () => {
    setSideBarElement( SideBarItems );
    checkTimeAndUpdate();
    const intervalId = setInterval( checkTimeAndUpdate, 6000 ); // Check every 1 minutes

    return () => clearInterval( intervalId ); // Clean up the interval when the component unmounts
  }, [ user ] );



  return (
    <>
      <div className="navigationBar">
        <div className="navigationBar-container">
          { !sideBar && (
            <div className="logo">
              { session && !authentication && (
                <BurgerSVG
                  onClick={ handleSideBar }
                  className="burger cursor-pointer"
                />
              ) }
              <div>
                <img
                  src={ logo }
                  alt="LOGO"
                  onClick={ () =>
                    session
                      ? user?.latest_subscription
                        ? navigate( "/sessions" )
                        : navigate( "/subscriptions" )
                      : navigate( "/home" )
                  }
                />
              </div>
            </div>
          ) }
          { session && !authentication && (
            <div className="side-items">
              <IconsButton
                icon={ <HomeIcon /> }
                onClick={ () => SendLinkToHome() }
              />
            </div>
          ) }
        </div>
      </div>
      { user && notifymessage !== "" && !authentication && (
        <div className={ `attention-note ${ sideBar ? "sideBarOpen" : "" } ` }>
          <div className="message">
            { notifymessage } &nbsp;
            <a
              onClick={ () =>
                user?.latest_subscription
                  ? navigate( "/user-subscriptions" )
                  : navigate( "/subscriptions" )
              }
            >
              { actionmessage }
            </a>
          </div>
        </div>
      ) }

      <SideBarDrawer
        sx={ {
          width: 300,
          flexShrink: 0,
        } }
        variant="persistent"
        anchor="left"
        open={ sideBar && session && !authentication }
        ModalProps={ {
          keepMounted: true,
        } }
      >
        <DrawerHeader className="drawer-header-container">
          <div className="text">
            <img
              src={ logo }
              alt="LOGO"
              onClick={ () =>
                session
                  ? user?.latest_subscription
                    ? navigate( "/sessions" )
                    : navigate( "/subscriptions" )
                  : navigate( "/login" )
              }
            />
          </div>
          { session && (
            <div className="burger">
              <BurgerSVG
                onClick={ handleSideBar }
                className="cursor-pointer"
              /></div>
          ) }
        </DrawerHeader>
        <List className="item-spacing layout-list" >
          <div>
            { sideBarElements
              ?.filter(
                ( i ) =>
                  ( i.is_subscribed && user?.latest_subscription ) ||
                  !i.is_subscribed
              )
              .slice( 0, -1 )
              .map( ( item, index ) => (
                <CustomizedListItem
                  key={ item.id }
                  item={ item }
                  index={ index }
                  length={ sideBarElements?.length }
                  handleCloseDrawer={ handleCloseDrawer }
                />
              ) ) }
          </div>
          <div>
            { sideBarElements
              .filter(
                ( i ) =>
                  ( i.is_subscribed && user?.latest_subscription ) ||
                  !i.is_subscribed
              )
              .slice( -1 )
              .map( ( item, index ) => (
                <CustomizedListItem
                  key={ item.id }
                  item={ item }
                  index={ sideBarElements.length - 1 }
                  length={ sideBarElements.length }
                  handleCloseDrawer={ handleCloseDrawer }
                />
              ) ) }
            <ListItem>
              <ListItemButton className="logout-sidebutton"
                sx={ { pl: 2.5 } }
                onClick={ () => {
                  handleLogout();
                } }
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={ "Log out" } />
              </ListItemButton>
            </ListItem>
          </div>
        </List>
      </SideBarDrawer>
      { openConfirm && (
        <NoticeConfirmation
          message="Are you sure you want to logout ?"
          handleResponse={ handleLogOutResponse }
        />
      ) }
    </>
  );
}
export default NavigationBar;
