//UTILITIES
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
//COMONENT
import video from "../../../assets/video/video_intro.mp4";
import { PrimaryButton } from "../../../components/buttons/Buttons";
import { BulletIcon } from "../../../assets/svg/SVG";
import ConstellatiomImage from "../../../assets/images/constellation_1.png";
import "./Home.scss";
import { UserContext } from "../../../context/UserContext";

export function Home( props ) {
  //VARIABLES
  let navigate = useNavigate();
  const { session } = useContext( UserContext )


  return (
    <div className="home-component">
      <div className="introduction">
        <div className="text">
          <div>
            <b>TOGETHER</b>
            <br></br>WE CAN FIX IT!
          </div>

          { !session && (
            <div>
              <PrimaryButton
                text="Sign Up - Start Free Trial!"
                variant="outlined"
                className="homepageButton"
                onClick={ () =>
                  navigate( "/signup", {
                    state: { subscriptionPlanId: 1, toggle: 0, is_free: true },
                  } )
                }
              />
            </div>
          ) }
        </div>
        <div className="image">
          <img src={ ConstellatiomImage } />
        </div>
      </div>
      <div className="text-introduction">
        <div className="video">
          <video
            className="videoWidth"
            width={ "90%" }
            height={ "100%" }
            autoplay="autoplay"
          >
            <source src={ video } />
          </video>
        </div>
        <div className="text">
          <div className="title">The online board that makes miracles!</div>
          <div className="body">
            <BulletIcon style={ { color: "#0052a2" } } /> A sophisticated online
            tool is now available for you.
          </div>
          <div className="body">
            { " " }
            <BulletIcon style={ { color: "#0052a2" } } /> Very easy to use and a
            very intuitive tool.
          </div>
          <div className="body">
            { " " }
            <BulletIcon style={ { color: "#0052a2" } } /> As a coach, facilitator,
            or consultant, you can take your customers to an upper level of
            perception and understanding.
          </div>
          <div className="body">
            { " " }
            <BulletIcon style={ { color: "#0052a2" } } /> Make the work easy for
            you and more effective for your clients.
          </div>
          <div className="body">
            { " " }
            <BulletIcon style={ { color: "#0052a2" } } />
            It is amazing what you can achieve with such an excellent tool.
          </div>
          <div className="body">
            { " " }
            <BulletIcon style={ { color: "#0052a2" } } />
            An online environment to get surprising insights in one-on-one
            coaching, online workshops, demos, online trainings, and many other
            situations.
          </div>
          <div className="body">
            { " " }
            <BulletIcon style={ { color: "#0052a2" } } />
            Make miracles with this tool and your customers will not forget you…
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
