import { api } from "./main";
import { APIURL } from "./main";

export const SaveCard = (body) => {
  return api
    .post(APIURL + `/save-card`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const GetAllCard = () => {
  return api
    .get(APIURL + `/card`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const UpdateDefaultCard = (id) => {
  return api
    .patch(APIURL + `/update-default-card/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const DeleteCard = (id) => {
  return api
    .delete(APIURL + `/delete-card/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
