//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import { useSearchParams } from "react-router-dom";
import { AlertContext } from "../../../context/AlertContext";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import { groupParamsByKey, objectCleaner } from "../../../core/functions/useDebounce";
//COMPONENT
import LoadMorePagination from "../../../components/table-component/LoadMorePagination.js";
import ImageHandle from "./ImageHandle";
import { imagePerPage } from "../../../core/variables/CoreVariables";
import TableUpsideSection from "../../../components/table-component/TableUpsideSection";
import TitleComponent from "../../../components/title-component/TitleComponent";
import ImageComponent from "./ImageComponent";
import NoDataFound from "../../../components/response-messages/NoDataFound";
import { ImageSkeletonComponent } from "../../../components/loader-component/LoaderComponent";
//DRAG FUNCTIONALITY
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

//API

import { deleteImage, getAllImages } from "../../../core/apis/image";
//CSSS
import "./ImageManagement.scss";
import NoticeConfirmation from "../../../components/dialog-component/NoticeConfirmation";
import isDateExpired from "../../../core/functions/checkExpiryDate";





export function CategoryImages( props ) {
  //VARIABLES
  const { background } = props;
  // const { slug } = useParams(); we changed , no longer categorization
  const slug = background ? "background" : "board";
  const { setAlert } = useContext( AlertContext );
  const { user } = useContext( UserContext );
  const { subscriptionItems, updateSubscriptionItems } = useContext( SubscriptionContext )
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { is_admin } = groupParamsByKey( searchParams ) || {};
  const [ canDrag, setCanDrag ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const [ page, setPage ] = useState( 1 );
  const [ canEdit, setCanEdit ] = useState( false );
  const [ loadMore, setLoadMore ] = useState( false );
  const [ totalPage, setTotalPage ] = useState( 0 );
  const [ loadingDrag, setLoadingDrag ] = useState( false );
  const [ openAddImage, setOpenAddImage ] = useState( false );
  const [ imageToEdit, setImageToEdit ] = useState( null );
  const [ total, setTotal ] = useState( 0 );
  const [ data, setData ] = useState( [] );
  const [ openConfirmation, setOpenConfirmation ] = useState( {
    open: false,
    error: false,
  } );





  //Functions
  // Handle drag movement and setting array values
  // Handle drag movement and setting array values
  const handleDragEnd = ( { active, over } ) => {
    if ( active.id !== over.id ) {
      const dragId = data.find( ( item ) => item.id === active.id );
      const dropId = data.find( ( item ) => item.id === over.id );

      if ( dragId && dropId ) {
        const oldIndex = data.indexOf( dragId );
        const newIndex = data.indexOf( dropId );

        setData( ( items ) => {
          return arrayMove( items, oldIndex, newIndex );
        } );
      }
    }
  };

  //GET PAGINATED DATA (IF WE CLICK VIEW MORE TO COMMENT )
  const GetPaginatedData = ( new_page ) => {
    setLoadMore( true );
    let old = [ ...data ];
    getAllImages( {
      category_slug: slug,
      per_page: imagePerPage,
      page: new_page,
      is_admin: searchParams.get( "is_admin" ) || null,
      user_id: user?.id,
    } ).then( ( response ) => {
      if ( response?.data?.success ) {
        setPage( response.data.data?.current_page );
        if ( response.data.data ) {
          response.data.data.data?.map( ( item ) => old.push( item ) );
        }

        setData( old );
        setTotalPage( response.data.data?.last_page );
      } else {
        setAlert( {
          text: response.data ? response.data.message : response.message,
          error: response?.data?.success ? false : true,
        } );
      }
      setLoadMore( false );
    } );
  };

  const GetData = () => {
    setLoading( true );
    getAllImages( {
      category_slug: slug,
      page: 1,
      per_page: imagePerPage,
      is_admin: searchParams.get( "is_admin" ) || null,
      user_id: user?.id,
    } ).then( ( response ) => {
      if ( response?.data?.success ) {
        setPage( 1 );
        setTotal( response.data.data ? response?.data?.data?.total : 0 );
        setData( response.data.data ? response.data.data?.data : [] );
        setTotalPage( response.data.data ? response?.data?.data?.last_page : 0 );
      } else {
        setAlert( {
          text: response.data ? response.data.message : response.message,
          error: response?.data?.success ? false : true,
        } );
      }

      setLoading( false );
    } );
  };

  useEffect( () => {
    if ( user ) {
      GetData();
    }
  }, [ is_admin, user, slug ] );





  const handleDelete = ( element ) => {
    setImageToEdit( element );
    setOpenConfirmation( { open: true, error: false } );
  };

  const handleDeleteResponse = ( type, payload ) => {
    if ( type === "yes" ) {
      deleteImage( payload?.id ).then( ( response ) => {
        if ( response?.data?.success ) {
          updateSubscriptionItems();
          GetData();

        }
        setAlert( {
          text: response.data ? response.data.message : response.message,
          error: response?.data?.success ? false : true,
        } );
        setOpenConfirmation( {
          open: false,
          error: false,
        } );
      } );
    } else {
      setOpenConfirmation( {
        open: false,
        error: false,
      } );
    }
  };
  const sensors = useSensors( useSensor( PointerSensor ) );


  const handleImageHandleResponse = ( message ) => {
    setOpenAddImage( false );
    let newFilters = {
      is_admin: null
    };
    setSearchParams( objectCleaner( newFilters ) );

    GetData();
  };

  const handleLoadChange = ( type ) => {
    if ( type === "more" ) {
      setPage( page + 1 );
      GetPaginatedData( page + 1 );
    } else {
      setPage( page - 1 );
      GetPaginatedData( page - 1 );
    }
  };

  const handleCloseHandleImage = () => {
    setOpenAddImage( false );
  };
  const handleAddImage = () => {
    let userSub = user?.latest_subscription
    if (
      subscriptionItems?.[ 'uploaded-images' ] &&
      (
        (
          userSub?.package_ends_at && // Check if the user has a package_ends_at
          !isDateExpired( userSub?.package_ends_at ) // Check if the package_ends_at is not expired
        ) ||
        (
          !userSub?.package_ends_at && // Check if there is no package_ends_at
          userSub?.trial_ends_at && // Check if there is a trial_ends_at
          !isDateExpired( userSub?.trial_ends_at ) // Check if the trial_ends_at is not expired
        )
      )
    ) {
      setOpenAddImage( true );
    } else {
      setOpenConfirmation( {
        open: true,
        error: true
      } )
    }


  };

  return (
    <div className="image-management-container">
      <TitleComponent
        title={ `${ slug.charAt( 0 ).toUpperCase() + slug.slice( 1 ) } Images` }
        classNames="page-title main-title"
      />
      <div className="total-data-counts">Total images : { total }</div>
      <TableUpsideSection
        filterByDoneBy
        search={ false }
        addButtonTitle={ "Upload image" }
        addButton={ handleAddImage }
        dragChecked={ canDrag }
        loadingDrag={ loadingDrag }
        type="board-images"
      />
      <div className="margin-up-20">
        { !loading ? (
          data?.length !== 0 ? (
            <>
              <DndContext
                sensors={ sensors }
                collisionDetection={ closestCenter }
                onDragEnd={ handleDragEnd }
              >
                <div className="image-body-container">
                  <SortableContext
                    items={ data || [] }
                    strategy={ rectSortingStrategy }
                  >
                    { data?.map( ( item, index ) => {
                      return (
                        <ImageComponent
                          { ...props }
                          key={ item.id }
                          data={ item }
                          index={ index }
                          handle={ true }
                          canDrag={ canDrag }
                          canEdit={ canEdit }
                          handleDelete={ handleDelete }
                        />
                      );
                    } ) }
                  </SortableContext>
                </div>
              </DndContext>

              <LoadMorePagination
                page={ page }
                loading={ loadMore }
                pageTotal={ totalPage }
                data={ data }
                dataLeft={ data ? total - data.length : 0 }
                handleLoadChange={ handleLoadChange }
              />
            </>
          ) : (
            <NoDataFound />
          )
        ) : (
          <ImageSkeletonComponent count={ 10 } background={ background } />
        ) }
      </div>

      { openAddImage && (
        <ImageHandle
          background={ background }
          category_slug={ slug }
          onClose={ handleCloseHandleImage }
          handleResponse={ handleImageHandleResponse }
        />
      ) }

      { openConfirmation?.open && (
        <NoticeConfirmation
          message={ openConfirmation?.error ? 'To access this feature, kindly review your subscription status' : 'Are you sure you want to delete?' }
          actions={ !openConfirmation?.error }
          handleResponse={ handleDeleteResponse }
          data={ imageToEdit }
        />
      ) }


    </div>
  );
}

export default CategoryImages;
