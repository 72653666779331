import { api } from "./main";

export const getAllCategories = (payload) => {
  return api
    .get(`get-all-categories`, {
      params: { ...payload },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
