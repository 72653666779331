//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import palette from "../../assets/theme/color.scss";
//CSS
import { Card, CardContent, Divider } from "@mui/material";

import "./SubscriptionsCards.scss";
import { PrimaryButton } from "../buttons/Buttons";
import TitleComponent from "../title-component/TitleComponent";
import { useNavigate } from "react-router";
import { CheckCircle } from "../../assets/svg/SVG";
import { SideBarContext } from "../../context/SideBarContext";
import { UserContext } from "../../context/UserContext";

export function SubscriptionsCards( {
  plan,
  handleOpenFreeTrial,
  pricingLayout,
} ) {
  let navigate = useNavigate();
  let { sideBar } = useContext( SideBarContext );
  let { user } = useContext( UserContext );

  const [ userSubscription, setUserSubscription ] = useState(
    user?.latest_subscription
  );
  const [ isSubscribed, setIsSubscribed ] = useState(
    user?.latest_subscription ? true : false
  );

  const GoToCheckout = ( type ) => {
    navigate( `/checkout/${ type }/${ plan.slug }` );
  };

  const handleClick = ( planItem ) => {
    //if user is subscribed or not subscribed and no free trial
    if ( isSubscribed ) {
      //if its the same plan as mine and I didn't start yet with(I was on a free trial)
      if (
        userSubscription?.plan_slug.id === planItem.id &&
        !userSubscription.package_starts_at
      ) {
        GoToCheckout( "subscribe" );
      }
      //if the plan isn't the same as mine.
      else if ( userSubscription?.plan_slug.id !== planItem.id ) {
        handleOpenFreeTrial(
          planItem,
          true,
          planItem?.trial_duration === 0 ? false : true
        );
      } else {
        GoToCheckout( "upgrade" );
      }
    } else {
      //if no free trial => checkout subscribe
      if ( planItem?.trial_duration === 0 ) {
        GoToCheckout( "subscribe" );
      } else {
        //open free trial dialog
        handleOpenFreeTrial(
          planItem,
          false,
          planItem?.trial_duration === 0 ? false : true
        );
      }
    }
  };

  useEffect( () => {
    setUserSubscription( user?.latest_subscription );
    setIsSubscribed( user?.latest_subscription ? true : false );
  }, [ user ] );

  return (
    <Card className="plan-card-component">
      <CardContent className="plan-card-content">
        <div className="centered-element">
          <div>
            <TitleComponent
              title={ plan.name.toUpperCase() }
              classNames="form-title plan-title"
            />
          </div>
          <div className="trial-section">
            { plan.trial_duration === 0
              ? "NO"
              : plan.trial_duration +
              " " +
              plan.trial_duration_type?.charAt( 0 ).toUpperCase() +
              plan.trial_duration_type.slice( 1 ) }{ " " }
            FREE TRIAL
          </div>
          <div className="description-section">{ plan.description }</div>
        </div>
        <hr className="plan-component-seperator" />
        <div className={ `plan-package-price ` }>
          { plan.plan_price.map( ( price, index ) => (
            <>
              <div
                key={ index }
                className={ `price-element ${ sideBar ? "sideBarOpen" : "SideBarClose"
                  }` }
              >
                <div className="price-currency">
                  { price.price }
                  { price.currency.symbol }
                </div>
                <div className="duration">
                  { `/${ price.package_duration } ${ price.package_duration_type }` }
                </div>
              </div>
              { plan?.plan_price.length !== 1 &&
                index !== plan?.plan_price?.length - 1 && (
                  <div className="plan-package-seperator ">
                    <Divider
                      orientation="vertical"
                      sx={ {
                        background: palette.primaryColorDark40,
                      } }
                      style={ {
                        height: "100%",
                        width: "1px",
                      } }
                    />
                  </div>
                ) }
            </>
          ) ) }
        </div>
        <hr className="plan-component-seperator" />
        <div className="plan-component-items">
          { plan?.items?.map( ( item, index ) => (
            <div key={ index } className="plan-item">
              <span>
                <CheckCircle />
              </span>
              <div>
                { `${ item.pivot.is_unlimited === 1
                  ? "Unlimited"
                  : item.pivot.value }  ${ item?.name }` }

              </div>
            </div>
          ) )
          }
        </div>
        <div className="plan-actions">
          <div>
            { !pricingLayout &&
              ( userSubscription
                ? userSubscription?.package_starts_at
                  ? userSubscription?.plan_slug?.sort_order < plan?.sort_order
                  : userSubscription?.plan_slug?.sort_order <= plan?.sort_order
                : true ) && (
                <>
                  <PrimaryButton
                    type="submit"
                    variant="contained"
                    text={
                      isSubscribed
                        ? userSubscription?.plan_slug?.sort_order ===
                          plan?.sort_order
                          ? `Subscribe`
                          : `Upgrade`
                        : `Subscribe`
                    }
                    onClick={ () => handleClick( plan ) }
                    loading={ false }
                    disabled={ false }
                  />
                  { ( !isSubscribed ||
                    userSubscription?.plan_slug?.sort_order <
                    plan?.sort_order ) && (
                      <div className="no-credit-card-message">
                        { plan.trial_duration === 0
                          ? "NO FREE TRIAL"
                          : `${ plan.trial_duration } 
                        ${ plan.trial_duration_type?.charAt( 0 ).toUpperCase() +
                          plan.trial_duration_type.slice( 1 )
                          } Free - No credit card required` }
                      </div>
                    ) }
                </>
              ) }
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default SubscriptionsCards;
