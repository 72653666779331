import React, { createContext, useEffect, useState } from "react";
import { GetSelfUser } from "../core/apis/users";
import { isUserLoggedIn } from "../core/apis/auth";
import { useCookies } from "react-cookie";

const UserContext = createContext( {
  user: null,
  updateUser: () => { },
} );

const UserContextProvider = ( props ) => {
  const [ cookies, setCookie ] = useCookies( [ "session", "isSubscribed" ] );
  const { children } = props;
  const [ user, setUser ] = useState( null );

  const GetUserData = async () => {
    const response = await GetSelfUser();
    if ( response?.data?.success ) {
      setCookie( "isSubscribed", Boolean( response.data?.data?.latest_subscription ), { path: "/" } );
      setUser( response.data.data );
    }

  };

  useEffect( () => {
    isUserLoggedIn().then( ( res ) => {
      setCookie( "session", Boolean( res?.data?.data?.is_loggedIn ), { path: "/" } );
    } )
  }, [] )

  useEffect( () => {
    if ( cookies?.session ) {
      GetUserData();
    }

  }, [ cookies?.session ] );

  return (
    <UserContext.Provider value={ { isSubscribed: cookies?.isSubscribed, session: cookies?.session, user, updateUser: () => GetUserData() } }>
      { children }
    </UserContext.Provider>
  );
};
export { UserContextProvider, UserContext };
