//UTILITIES
import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Pricing.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";

import SwiperCore, {
  EffectFlip,
  FreeMode,
  Navigation,
  Pagination,
} from "swiper";
import { getAllActivePlans } from "../../../core/apis/subscriptions";
import LoaderComponent, { PlansSkeleton } from "../../../components/loader-component/LoaderComponent";
import TitleComponent from "../../../components/title-component/TitleComponent";
import SubscriptionsCards from "../../../components/subscription-card/SubscriptionsCards";

SwiperCore.use( [ EffectFlip, FreeMode, Navigation, Pagination ] );

function useSwiperBreakpoints() {
  const breakpoints = {
    900: {
      slidesPerView: 3,
    },
    700: {
      slidesPerView: 2,
    },
    0: {
      slidesPerView: 1,
    },
  };

  return breakpoints;
}

export function Pricing( props ) {
  const [ plans, setPlans ] = useState( [] );
  const [ loading, setLoading ] = useState( true );
  const breakpoints = useSwiperBreakpoints();

  const GetData = () => {
    try {
      getAllActivePlans().then( ( res ) => {
        if ( res?.data?.success ) {
          setPlans( res.data.data );
        }
        setLoading( false );
      } );
    } catch ( e ) {
      setLoading( false );
    }
  };
  useEffect( () => {
    GetData();
  }, [] );

  return (
    <div className="pricing">
      <TitleComponent title="Our Plans" classNames="form-title main-title" />
      { loading ? (
        <PlansSkeleton />
      ) : (
        <Swiper
          spaceBetween={ 30 }
          freeMode={ true }
          navigation={ true }
          breakpoints={ breakpoints }
        >
          { plans.map( ( plan, index ) => {
            return (
              <SwiperSlide key={ index }>
                <SubscriptionsCards pricingLayout={ true } plan={ plan } />
              </SwiperSlide>
            );
          } ) }
        </Swiper>
      ) }
    </div>
  );
}
export default Pricing;
