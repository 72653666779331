import React from "react"
import { IconButton, Tooltip } from "@mui/material";
import { HintSVG, } from "../../assets/svg/SVG";
import "./HintMessages.scss"
import useTheme from '@mui/material/styles/useTheme';

export function HintTooltip( props ) {
    const { text } = props;
    const theme = useTheme();



    return (
        <><Tooltip title={ text } placement="top">
            <IconButton className="hint-popper-button"  >
                <HintSVG />
            </IconButton>
        </Tooltip>


        </>
    )


}

export default HintTooltip;