//UTILITIES
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
//COMPPONENT
import TitleComponent from "../../../components/title-component/TitleComponent";
import "./Subscriptions.scss";
import { LinkButton, PrimaryButton } from "../../../components/buttons/Buttons";
import { NoticeDialog } from "../../../assets/theme/SpecialComponent";
import { DialogContent } from "@mui/material";
import { FormSwitch } from "../../../components/form-component/FormComponent";
import DialogCustomTitle from "../../../components/dialog-component/DialogCustomTitle";

export function TrialModal( { freeTrialSpec, proceed, onClose, loading, plan } ) {
  const [ upgradeNow, setUpgradeNow ] = useState( false );
  return (
    <NoticeDialog open={ true }>
      <DialogCustomTitle onClose={ () => onClose() } />
      <TitleComponent
        title={ `${ !freeTrialSpec?.hasFreeTrial ? "NO" : "" } FREE TRIAL` }
        classNames="form-title main-title"
      />
      <DialogContent>
        { freeTrialSpec?.hasFreeTrial ? (
          <div className="text-justify">
            There's{ " " }
            <span className="highlighted-text">no credit card required</span>{ " " }
            for your{ " " }
            { plan?.trial_duration +
              " " +
              plan?.trial_duration_type?.charAt( 0 ).toUpperCase() +
              plan?.trial_duration_type.slice( 1 ) }{ " " }
            trial.
          </div>
        ) : (
          <div className="text-justify">
            The <span className="highlighted-text">{ plan?.name }</span> Plan
            doesn't include a free trial.
          </div>
        ) }
        { freeTrialSpec?.isUpgrade && freeTrialSpec.hasFreeTrial && (
          <div className="upgrade-now-section">
            <div className="text">
              Upgrade now or wait until subscription ends.
            </div>{ " " }
            <FormSwitch
              noFormComponent
              labelI="Upgrade Now"
              value={ upgradeNow }
              onChange={ ( e ) => {
                setUpgradeNow( e );
              } }
            />
          </div>
        ) }
      </DialogContent>
      <div className="trial-button">
        <PrimaryButton
          type="submit"
          text="Proceed"
          onClick={ () => proceed( plan, upgradeNow, freeTrialSpec ) }
          loading={ loading }
          disabled={ loading }
        />
      </div>
    </NoticeDialog>
  );
}
export function AutoRenew( {
  closeModal,
  proceed,

  loading,

  user,
  subscription,
} ) {
  const navigate = useNavigate();

  return (
    <NoticeDialog open={ true }>
      <DialogCustomTitle
        title="Auto Renew"
        onClose={ closeModal }
      />
      <div className="centered-text margin-up-20">
        { user?.default_card === 0
          ? "Please add a default card in order to proceed"
          : subscription?.next_plan
            ? "You already have a next plan"
            : subscription?.latest_subscription?.is_auto_renew
              ? "Are you sure you to disable auto renew?"
              : "Are you sure you want to auto renew?" }
      </div>
      { !subscription?.next_plan ? (
        user?.default_card === 1 ? (
          <div className="modal-button-action renew-buttons">
            <PrimaryButton
              variant={ "outlined" }
              onClick={ closeModal }
              text={ "No" }
              className="save-button"
            />
            <PrimaryButton
              type="submit"
              text={ "Yes" }
              className="save-button"
              onClick={ proceed }
              loading={ loading }
              disabled={ loading ? true : false }
            />
          </div>
        ) : (

          <LinkButton
            onClick={ () => navigate( "/account/card-management" ) }
            text={ "Go to card management" }
            className="save-button underline"
          />

        )
      ) : null }
    </NoticeDialog>
  );
}
