import { api } from "./main";
import { APIURL } from "./main";

export const checkCoupon = ( payload ) => {
  let encodedPayload = encodeURIComponent( payload )
  return api
    .get( APIURL + `/coupon/${ encodedPayload }`, { params: { code: payload } } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};

export const getUserCoupon = () => {
  return api
    .get( APIURL + `/user-coupon` )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};
export const generatePrice = ( payload ) => {
  return api
    .get( `generate-price`, { params: { ...payload } } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};
