//COMPONENT
import { Card, CardContent, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EmailSVG } from "../../../assets/svg/SVG";
import "./AuthenticationMessages.scss"

export function LinkSent( props ) {

  let navigate = useNavigate();

  useEffect( () => {
    setTimeout( function () {
      navigate( "/login" );
    }, 5000 );
  }, [] );

  return (
    <div className="page-container">
      <Card className="authmessages-container">
        <CardContent className="content">
          <div>
            <EmailSVG size={ 50 } />
          </div>
          <div className="title">Reset Successfully</div>
          <div className="text">
            An Email has been sent. Please check your inbox
          </div>

        </CardContent>
      </Card>
    </div>

  );
}
export default LinkSent;
