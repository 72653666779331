//UTILITIES
import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//COMPONENT
import { Tab, Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

//CSS
import "./TabComponent.scss";

export function RoutingTabComponent(props) {
  let navigate = useNavigate();

  const { data, tabContent, withBorder } = props;

  //FUNCTIONSn
  const handleTabChange = (newValue) => {
    let selectedTab = tabContent.find((d) => d.id.toString() === newValue);
    navigate(selectedTab.path, { state: data });
  };

  const pathname = useLocation().pathname;

  const activeItem = tabContent.find((item) => pathname.includes(item.path));

  return (
    // <TabContext value={activeItem ? activeItem.id.toString() : "1"}>
    //   <Box sx={{ borderBottom: withBorder ? 1 : null, borderColor: "divider" }}>
    //     <TabList
    //       variant="scrollable"
    //       // scrollButtons="auto"
    //       onChange={handleTabChange}
    //       aria-label="lab API tabs example"
    //     >
    //       {tabContent.map((d) => {
    //         return <Tab value={d.id.toString()} label={d.title} key={d.id} />;
    //       })}
    //     </TabList>
    //   </Box>
    // </TabContext>
    <div className="tab-bar">
      {tabContent.map(tab => (
        <button
          key={tab.id}
          className={(tab.id).toString() === (activeItem ? activeItem.id.toString() : "1") ? 'active' : ''}
          onClick={() => handleTabChange(tab.id.toString())}
        >
          {tab.title}
        </button>
      ))}
    </div>
  );
}

export default RoutingTabComponent;
