//UTILITIES
import React, { useContext, useState } from "react";

//COMPONENT
import ContactImage from "../../../assets/images/contactUs.png";
import {
  FormInput,
  FormPhoneInput,
  FormTextArea,
} from "../../../components/form-component/FormComponent";
import { PrimaryButton } from "../../../components/buttons/Buttons";
import * as yup from "yup";

//CSS
import "./Contact.scss";

//API
import { AlertContext } from "../../../context/AlertContext";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactUs } from "../../../core/apis/auth";
import TitleComponent from "../../../components/title-component/TitleComponent";
import {
  emailSchema,
  nameRequiredLimited,
  phoneNumberSchema,
} from "../../../core/validators/form-validators";

const ContactSchema = yup.object( {
  email: emailSchema,
  first_name: nameRequiredLimited( true, 255 ),
  last_name: nameRequiredLimited( true, 255 ),
  phone_number: phoneNumberSchema,
  message: yup.string().nullable().required( "Field is required" ),
} );

export function Contact( props ) {
  const [ saveLoading, setSaveLoading ] = useState( false );

  const { setAlert } = useContext( AlertContext );

  //INITIATE USE FORM
  const { control, handleSubmit, reset } = useForm( {
    defaultValues: {
      email: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      message: "",
    },
    resolver: yupResolver( ContactSchema ),
  } );

  const handleSubmitForm = ( formValues ) => {
    setSaveLoading( true );
    let name = formValues.first_name + " " + formValues.last_name;
    delete formValues.last_name;
    delete formValues.first_name;
    contactUs( { ...formValues, name } ).then( ( response ) => {
      if ( response?.data?.success ) {
        reset();
      }
      setAlert( {
        text: response.data ? response.data.message : response.message,
        error: response?.data?.success ? false : true,
      } );
      setSaveLoading( false );
    } );
  };

  return (
    <body className="whiteBg">
      <div className="contact-component">
        <div className="introduction-section">
          <div className="form">
            <TitleComponent
              title={ "Contact Us" }
              classNames="page-title main-title"
            />

            <form onSubmit={ handleSubmit( handleSubmitForm ) }>
              <div className="form-inputs-double-children">
                <Controller
                  render={ ( {
                    field: { onChange, value },
                    fieldState: { error },
                  } ) => (
                    <FormInput
                      isAuth
                      name="first_name"
                      placeholder={ "Enter first name" }
                      label={ "First Name" }
                      value={ value }
                      onChange={ ( value ) => {
                        onChange( value );
                      } }
                      helperText={ error?.message }
                    />
                  ) }
                  name="first_name"
                  control={ control }
                />

                <Controller
                  render={ ( {
                    field: { onChange, value },
                    fieldState: { error },
                  } ) => (
                    <FormInput
                      isAuth
                      name="last_name"
                      placeholder={ "Enter last name" }
                      label={ "Last Name" }
                      value={ value }
                      onChange={ ( value ) => {
                        onChange( value );
                      } }
                      helperText={ error?.message }
                    />
                  ) }
                  name="last_name"
                  control={ control }
                />

                <Controller
                  render={ ( {
                    field: { onChange, value },
                    fieldState: { error },
                  } ) => (
                    <FormInput
                      isAuth
                      label="Email"
                      name="email"
                      placeholder={ "Enter email" }
                      value={ value }
                      onChange={ ( value ) => {
                        onChange( value );
                      } }
                      helperText={ error?.message }
                    />
                  ) }
                  name="email"
                  control={ control }
                />

                <Controller
                  render={ ( {
                    field: { onChange, value },
                    fieldState: { error },
                  } ) => (
                    <FormPhoneInput
                      name="phone_number"
                      label={ "Phone Number" }
                      placeholder={ "Enter phone number" }
                      value={ value }
                      onChange={ ( value ) => {
                        onChange( value );
                      } }
                      helperText={ error?.message }
                    />
                  ) }
                  name="phone_number"
                  control={ control }
                />
              </div>
              <div className="message">
                <Controller
                  render={ ( {
                    field: { onChange, value },
                    fieldState: { error },
                  } ) => (
                    <FormTextArea
                      name="message"
                      label={ "Message" }
                      placeholder={ "Write your message..." }
                      value={ value }
                      onChange={ ( value ) => {
                        onChange( value );
                      } }
                      helperText={ error?.message }
                    />
                  ) }
                  name="message"
                  control={ control }
                />
              </div>

              <div className="form-footer-style display-footer-right">
                <div className="form-footer-elements">
                  <PrimaryButton
                    variant="outlined"
                    text="Cancel"
                    onClick={ () =>
                      reset( {
                        email: "",
                        first_name: "",
                        last_name: "",
                        phone_number: "",
                        message: "",
                      } )
                    }
                  />
                  <PrimaryButton
                    text="Send"
                    type="submit"
                    loading={ saveLoading }
                    disabled={ saveLoading ? true : false }
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="image">
            <img src={ ContactImage } />
          </div>
        </div>
      </div>
    </body>
  );
}
export default Contact;
