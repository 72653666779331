import "./variables.scss";
import {
  Drawer,
  Table,
  TableHead,
  TableCell,
  Dialog,
  Menu,
  Tooltip,
} from "@mui/material";
import styled from "@emotion/styled";
import palette from "../theme/color.scss";
import theme from "./MuiTheme";
import { MobileOverBreakPoint } from "../../core/variables/CoreVariables";

export const SideBarDrawer = styled( Drawer )( {
  "& .MuiDrawer-paper": {
    // marginTop: "60px",
    boxSizing: "border-box",
    maxWidth: "300px",
    minHeight: "300px",
    minWidth: "220px",
    maxHeight: "100vh",
    fontSize: "18px",
    overflow: "overlay",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: palette.bluePrimary,
    color: "#fff",
  },
  "& .Mui-selected": {
    backgroundColor: palette.darkBluePrimary,
    "&:hover": { backgroundColor: palette.darkBluePrimary },
  },
  "& .MuiListItem-root": {
    "&:hover": { backgroundColor: palette.darkBluePrimary },
  },
  "& .MuiList-root": {
    paddingTop: "0px"
  },

} );
export const DrawerHeader = styled( "div" )( {
  display: "flex",
  alignItems: "center",

  padding: theme.spacing( 0, 1 ),
  ...theme.mixins.toolbar,
} );

export const LandingDrawer = styled( Drawer )( {
  "& .MuiDrawer-paper": {
    [ theme.breakpoints.up( MobileOverBreakPoint ) ]: {
      display: "none",
    },
    // marginTop: "60px",
    boxSizing: "border-box",
    maxWidth: "300px",
    minHeight: "300px",
    minWidth: "220px",
    maxHeight: "100vh",
    fontSize: "18px",
    overflow: "overlay",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: palette.whiteColor,
    color: palette.primaryColorDark,
  },
  "& .Mui-selected": {
    backgroundColor: palette.primaryColorLight + " !important",
    color: palette.whiteColor,
  }, "& .MuiListItem-root": {
    "&:hover": { backgroundColor: palette.primaryColorLight, color: palette.whiteColor, },
  },
} );

export const InvoiceTable = styled( Table )( () => ( {
  "&.MuiTable-root": {
    boxShadow: "0px 1px 4px 0px #1D212D26",
  },
  "&.MuiTableHead-root": {
    backgroundColor: palette.greyColor5,
  },
} ) );

export const InvoiceTableHead = styled( TableHead )( () => ( {
  "&.MuiTableHead-root": {
    background: palette.greyColor5,
    color: palette.primaryColorDark,
    fontWeight: 700,
  },
} ) );

export const InvoiceTableCell = styled( TableCell )( () => ( {
  "&.MuiTableCell-head": {
    color: palette.primaryColorDark,
    border: `1px solid ${ palette.mediumGreyColor }`,

    fontSize: "17px",
    "@media (max-width:1501px)": { fontSize: "15px" },
    "@media (max-width:500px)": { fontSize: "15px" },
    "@media (max-width:860px)": { fontSize: "13px" },
    "@media (max-width:791px)": { fontSize: "13px" },
  },
  "&.MuiTableCell-body": {
    color: palette.primaryColorDark,
    border: `1px solid ${ palette.mediumGreyColor }`,
    fontWeight: 500,
    fontSize: "17px",
    "@media (max-width:1501px)": { fontSize: "15px" },
    "@media (max-width:500px)": { fontSize: "15px" },
    "@media (max-width:860px)": { fontSize: "13px" },
    "@media (max-width:791px)": { fontSize: "13px" },
  },
} ) );

export const StyledFilterMenu = styled( ( props ) => (
  <Menu
    elevation={ 0 }
    anchorOrigin={ {
      vertical: "bottom",
      horizontal: "right",
    } }
    transformOrigin={ {
      vertical: "top",
      horizontal: "right",
    } }
    { ...props }
  />
) )( ( { theme } ) => ( {
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing( 1 ),
    minWidth: 300,
    maxWidth: 300,
    backgroundColor: palette.whiteColor,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",

    "& .MuiList-root": {
      backgroundColor: palette.whiteColor,
    },

    "& .MuiMenuItem-root": {
      cursor: "default",
      backgroundColor: "transparent",
    },
  },
} ) );
export const NoticeDialog = styled( Dialog )( {
  "& .MuiDialogTitle-root": {
    border: "none",
    padding: "unset",
    fontWeight: 600,
    color: palette.primaryColorDark,
    marginBottom: "15px",
  },
  "& .MuiDialog-paper": {
    borderRadius: 24,
    padding: "20px 30px",
  },
} );

export const FooterTooltip = styled( ( { className, ...props } ) => (
  <Tooltip { ...props } componentsProps={ { tooltip: { className: className } } } />
) )( `
    color: ${ palette.primaryColorDark };
    background-color: ${ palette.whiteColor };
    font-size: 14px;
`);
