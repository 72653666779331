import React from "react";

//COMPONENT
import { BackIcon } from "../../assets/svg/SVG";
import { IconsButton } from "../buttons/Buttons";

//CSS
import "./TitleComponent.scss";

export function TitleComponent( props ) {
  const { back, endIcon, title, handleBack, handleEndAction, classNames } =
    props;

  return (
    <div className={ `${ classNames ? classNames : "" }` }>
      <div className="start">
        { back ? (
          <>
            <div className="icon-space">
              <IconsButton icon={ <BackIcon /> } onClick={ () => handleBack() } />
            </div>
            <div onClick={ () => handleBack() } className="title-text cursor-pointer">
              { title }
            </div>
          </>
        ) : (
          <div className="title-text">{ title }</div>
        ) }
      </div>
      <div className="end">{ handleEndAction ? handleEndAction : endIcon }</div>
    </div>
  );
}
export default TitleComponent;
