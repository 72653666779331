//utilities
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//component
import { Checkbox, FormControlLabel } from "@mui/material";
import { PrimaryButton } from "../../../components/buttons/Buttons";
import { FormInput } from "../../../components/form-component/FormComponent";

//scss
import "./Checkout.scss";
import { UserContext } from "../../../context/UserContext";
import { nameRequiredLimited } from "../../../core/validators/form-validators";

export function AccountInformation( { handleStep, setFormValues, formValues } ) {
  const { type } = useParams();
  const { user } = useContext( UserContext );
  const HandleSchema = yup.object( {
    fiscal_number: nameRequiredLimited( true, 255 ),
    company_name: yup.string().nullable().when( "has_company", {
      is: true,
      then: nameRequiredLimited( true, 255 ),
      otherwise: nameRequiredLimited( false, 255 ),
    } ),
    has_company: yup.boolean(),
  } );

  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    reset,
    setValue,
    formState: { errors },
  } = useForm( {
    resolver: yupResolver( HandleSchema ),
  } );

  useEffect( () => {
    let form = {
      fiscal_number:
        formValues.fiscal_number === "" && user?.fiscal_number
          ? user?.fiscal_number
          : formValues.fiscal_number,
      has_company: formValues?.has_company_changed
        ? formValues?.has_company
        : user?.company_id
          ? true
          : false,
      company_name: formValues?.has_company_changed
        ? formValues?.company_name
        : user?.company ? user?.company?.name : "",
    };

    reset( form );
  }, [ formValues, user ] );

  const nextStep = ( data ) => {
    setFormValues( {
      ...formValues,
      ...data,
      ...( data?.company_name !== formValues?.company_name && {
        has_company_changed: true,
      } ),
    } );
    handleStep( { next: true } );
  };

  return (
    <div>
      <form onSubmit={ handleSubmit( nextStep ) }>
        <div className="fields check-information">
          <Controller
            name="has_company"
            defaultValue={ false }
            control={ control }
            render={ ( { field: { onChange, value }, fieldState: { error } } ) => {
              return (
                <FormControlLabel
                  disabled={ type === "renew" }
                  control={
                    <Checkbox
                      checked={ value }
                      onChange={ ( e ) => {
                        onChange( e );
                        setValue( "company_name", "", { shouldValidate: true } );
                      } }
                      name="has_company"
                    />
                  }
                  label="Company"
                />
              );
            } }
          />
        </div>
        <div className="form-inputs-double-children">
          <div style={ { flexBasis: !watch( "has_company" ) && "100%" } }>
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  isAuth
                  disabled={ type === "renew" }
                  name="fiscal_number"
                  label="Fiscal Number"
                  placeholder={ "Enter Number" }
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  hint="If your country does not have a fiscal number, kindly input 0000"
                  helperText={ error?.message }
                />
              ) }
              name="fiscal_number"
              control={ control }
            />
          </div>

          { watch( "has_company" ) && (
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  isAuth
                  disabled={ type === "renew" }
                  name="company_name"
                  label="Company Name"
                  placeholder={ "Enter Comapny name" }
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );

                    clearErrors( "company_name" );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="company_name"
              control={ control }
            />
          ) }
        </div>
        <div className="modal-button-action">
          <PrimaryButton
            type="submit"
            text={ "Proceed" }
            className="save-button"
          />
        </div>
      </form>
    </div>
  );
}
