import { api } from "./main";
import { APIURL } from "./main";

export const getAllAddresses = () => {
  return api
    .get( `addresses` )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};

export const createAddress = ( body ) => {
  return api
    .post( APIURL + `/address`, body )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};

export const deleteAddress = ( id ) => {
  return api
    .delete( APIURL + `/address/${ id }` )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};

export const getAddressById = async ( id ) => {
  return api
    .get( APIURL + `/address/${ id }` )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};

export const updateAddress = async ( id, body ) => {
  return api
    .put( APIURL + `/address/${ id }`, body )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};
export const getAllCountries = async ( body ) => {
  return api
    .get( `get-all-countries` )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};
