import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { PrimaryButton } from "../../../components/buttons/Buttons";
import { FormInput } from "../../../components/form-component/FormComponent";
import { Card, CardContent } from "@mui/material";
import "./ForgotPassword.scss";

//API
import TitleComponent from "../../../components/title-component/TitleComponent";
import { resetPassword } from "../../../core/apis/auth";
import { AlertContext } from "../../../context/AlertContext";

const HandleSchema = yup.object( {
  email: yup.string().email().required( "Field is required" ),
} );

export function ForgotPassword( props ) {
  let navigate = useNavigate();
  const { setAlert } = useContext( AlertContext );
  const [ saveLoading, setSaveLoading ] = useState( false );

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,

    formState: { errors },
  } = useForm( {
    defaultValues: {
      email: "",
    },
    resolver: yupResolver( HandleSchema ),
  } );

  //FUNCTIONS

  const handleSubmitForm = ( formData ) => {
    setSaveLoading( true );
    resetPassword( {
      ...formData,
      url: `${ process.env.REACT_APP_USER }reset-password/`,
    } ).then( ( response ) => {
      if ( response?.data?.success ) {
        navigate( "/link-sent" );
      } else {
        setAlert( {
          text: response.data ? response.data.message : response.message,
          error: true,
        } );
      }
      setSaveLoading( false );
    } );
  };
  return (
    <div className="page-container">
      <Card className="forgotpassword-container">
        <CardContent className="content">
          <TitleComponent
            title="Forgot Password"
            classNames="form-title main-title"
          />
          <form onSubmit={ handleSubmit( handleSubmitForm ) } className='forgot-form'>
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  isAuth
                  label="Email"
                  name="email"
                  placeholder={ "Enter email" }
                  value={ value }
                  onChange={ ( value ) => {
                    onChange( value );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="email"
              control={ control }
            />

            <div className="flex-column-wrap forgotpassword-action">
              <PrimaryButton
                type="submit"
                text="Send Link"
                className="forgot-button"
                loading={ saveLoading }
                disabled={ saveLoading ? true : false }
              />
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default ForgotPassword;
