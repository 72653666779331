import { api } from "./main";

export const userLogin = async ( payload ) => {
  return await api
    .post( "/login", payload )
    .then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
};

export const resetPassword = async ( payload ) => {
  return await api
    .post( "/reset-password", payload )
    .then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
};

export const resetPasswordForm = async ( payload ) => {
  return await api
    .post( "reset-password-form", payload )
    .then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
};

export const changePassword = async ( payload ) => {
  return await api
    .post( "change-password", payload )
    .then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
};

export const userLogout = async ( payload ) => {
  return await api
    .get( "logout" )
    .then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
};

export const refreshToken = async ( payload ) => {
  return await api
    .get( "refresh-token", { params: { payload } } )
    .then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
};

export const contactUs = async ( payload ) => {
  return await api
    .post( "contact-us", payload )
    .then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
};


export const isUserLoggedIn = async () => {
  return await api.get( 'is-loggedin' ).then( ( res ) => res )
    .catch( ( err ) => console.error( err ) );
}