//UTILITIES
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
//COMPONENT
import {
  CircularProgress,
  FormHelperText,
  Skeleton,
  debounce,
} from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  FormPromoCode,
  FormSwitch,
} from "../../../components/form-component/FormComponent";
import { PrimaryButton } from "../../../components/buttons/Buttons";

//SCSS
import "./Checkout.scss";

//API
import { getPlanBySlug } from "../../../core/apis/subscriptions";
import {
  checkCoupon,
  generatePrice,
  getUserCoupon,
} from "../../../core/apis/coupon";
import { AlertContext } from "../../../context/AlertContext";

const formControlLabelStyle = {
  "& .MuiFormControlLabel-label": {
    fontWeight: 700,
    marginRight: 5,
  },
};

export function PlanOption( { handleStep, setFormValues, formValues } ) {
  const { slug, type } = useParams();
  const { setAlert } = useContext( AlertContext )
  const [ plan, setPlan ] = useState( null );
  const [ loading, setLoading ] = useState( true );
  const [ valid, setValid ] = useState( formValues.coupon_code ? true : false );
  const [ loadingPrice, setLoadingPrice ] = useState( false );
  const [ loadingCoupon, setLoadingCoupon ] = useState( false );

  const HandleSchema = yup.object( {
    plan_price_id: yup.object().nullable().required( "Please Choose a package" ),//the selected plan
    coupon_code: yup.string().nullable(), //selected coupon code
    upgrade_now: yup.boolean(),
    total: yup.string(),
  } );

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    getValues,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm( {
    resolver: yupResolver( HandleSchema ),
    defaultValues: {
      has_coupon: formValues.has_coupon, //if the user has already a saved coupon
      plan_price_id: formValues.plan_price_id, //selected plan
      coupon_code: formValues.coupon_code, //selected coupon code
      upgrade_now: formValues.upgrade_now,
      total: formValues.total,
    },
  } );


  //api that gets the plan package options
  const GetPlanData = () => {
    getPlanBySlug( slug ).then( ( res ) => {
      if ( res?.data?.success ) {
        setPlan( res.data.data );
        //IF NO PLAN SELECTED YET AND THERE IS ONLY ONE OPTION THEN ADD IT ELSE THEN THAT DON'T CHOOSE A DEFAULT OPTION
        //USER SHOULD CHOOSE
        if (
          !formValues.plan_price_id &&
          res.data.data?.plan_price?.length === 1
        ) {
          setValue( "plan_price_id", res.data.data.plan_price[ 0 ] );
        }

        handleGeneratePrice();
      }
      setLoading( false );
    } );
  };


  ///api that check if user has already a coupon , 
  // because cecilio wants if the user has used for once a coupon , all his following invoices should have that discount coupon
  const GetUserCoupon = () => {
    getUserCoupon().then( ( res ) => {
      if ( res?.data?.success ) {
        let data = res?.data?.data
        let couponToCheck = formValues?.coupon_code;
        if ( data ) {
          //if the user has a coupon we change the value of has coupon to true
          setValue( "has_coupon", data?.code );
          if ( !formValues.coupon_code_changed ) {
            //if the user has already enter a new code we should not take the old coupon code
            couponToCheck = data?.code
            setValue( "coupon_code", data?.code || "" );
          }
          setFormValues( {
            ...formValues,
            ...( !formValues.coupon_code_changed && data && {
              coupon_code: data?.code,
            } ),
            has_coupon: data?.code,
          } );


        }


        //after checking if that user has a valid coupon , checking if the coupon is still valid
        CheckCouponValidity( couponToCheck );

      }
    } );
  };
  useEffect( () => {
    GetPlanData();
    GetUserCoupon();
  }, [] );

  const nextStep = ( data ) => {
    if ( loadingCoupon ) return;
    if ( !errors.coupon_code ) {
      clearErrors( "coupon_code" );
      if ( data.coupon_code === "" ) {
        delete data.coupon_code;
        if ( formValues.coupon_code ) delete formValues.coupon_code;
      }
      setFormValues( {
        ...formValues,
        ...data,
      } );
      handleStep( { next: true } );
    } else {
      setError( "coupon_code", {
        type: "custom",
        message: "Coupon is invalid",
      } );
    }
  };

  const CheckCouponValidity = ( value ) => {

    if ( value?.trim() !== "" ) { //checking that the value is not empty
      setLoadingCoupon( true );
      try {

        checkCoupon( value ).then( ( res ) => {
          if ( res?.data?.success ) {
            setValid( true );
            clearErrors( "coupon_code" );
          } else {
            setValid( false );
            setError( "coupon_code", {
              type: "custom",
              message: "Coupon is invalid",
            } );
          }

          setLoadingCoupon( false );
          handleGeneratePrice();
        } );
      } catch ( e ) {
        clearErrors( "coupon_code" );
        setLoadingCoupon( false );
      }
    } else {
      handleGeneratePrice()
    }
  };

  const debouncedOnChange = useCallback(
    debounce( ( value ) => {
      //If the plan price is not selected, there won't be a check, as it depends on the chosen package
      if ( !watch( 'plan_price_id' ) ) {
        setAlert( {
          text: 'Please choose a package first',
          error: true
        } )
      } else {
        CheckCouponValidity( value );
      }

    }, 500 ),
    []
  );

  const handleGeneratePrice = () => {
    if ( watch( "plan_price_id" ) ) {
      setLoadingPrice( true );
      generatePrice( {
        plan_price_id: watch( "plan_price_id" )?.id,
        code: watch( "coupon_code" ) === "" ? watch( 'has_coupon' ) : watch( "coupon_code" ),
      } ).then( ( res ) => {
        if ( res?.data?.success ) {
          setValue(
            "total",
            res?.data?.data?.price + " " + res?.data?.data?.currency?.symbol
          );
        } else {
          setValue(
            "total",
            watch( "plan_price_id" )?.price +
            " " +
            watch( "plan_price_id" )?.currency?.symbol
          );
        }
        setLoadingPrice( false );
      } );
    } else {
      setValue( "total", "0" );
    }
  };

  return (
    <div>
      { loading ? (
        <Skeleton variant="rectangular" height={ 20 } />
      ) : (
        <form onSubmit={ handleSubmit( nextStep ) }>
          <div className="plan-options-packages ">
            <Controller
              name="plan_price_id"
              defaultValue={ "" }
              control={ control }
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => {
                return (
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={ value?.id }
                    name="plan_price_id"
                    className="radio-row"
                  >
                    { plan?.plan_price?.map( ( price, index ) => {
                      return (
                        <FormControlLabel
                          sx={ { ...formControlLabelStyle } }
                          key={ index }
                          value={ price?.id }
                          control={
                            <Radio
                              onClick={ () => {
                                onChange( price );
                                //maybe the user enter the code and then selected the plan package, we should then recheck the coupon code
                                CheckCouponValidity( watch( 'coupon_code' ) );
                              } }
                              checked={ value?.id == price?.id }
                            />
                          }
                          label={
                            <span className="plan-package-options-font-style">
                              { price?.price +
                                price?.currency.symbol +
                                " / " +
                                price?.package_duration_type +
                                ( price?.description
                                  ? " (" + price?.description + ")"
                                  : "" ) }
                            </span>
                          }
                        />
                      );
                    } ) }
                  </RadioGroup>
                );
              } }
            />
          </div>
          <div className="plan-options-packages">
            { errors?.plan_price_id?.message !== "" && (
              <FormHelperText>{ errors?.plan_price_id?.message }</FormHelperText>
            ) }
          </div>

          { type === "upgrade" && (
            <div style={ { display: "flex", justifyContent: "flex-end" } }>
              <Controller
                name="upgrade_now"
                defaultValue={ false }
                control={ control }
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => {
                  return (
                    <div className="upgrade-now-section">
                      <div className="text">
                        Upgrade now or wait until subscription ends
                      </div>

                      <FormSwitch
                        noFormComponent
                        labelI="Upgrade Now"
                        value={ value }
                        onChange={ ( e ) => {
                          onChange( e );
                        } }
                      />
                    </div>
                  );
                } }
              />
            </div>
          ) }
          <Controller
            name="coupon_code"
            defaultValue={ "" }
            control={ control }
            render={ ( { field: { onChange, value }, fieldState: { error } } ) => {
              return (
                <FormPromoCode
                  helperText={ error?.message }
                  loading={ loadingCoupon }
                  value={ value }
                  is_valid={ valid }
                  hasCoupon={ getValues( "has_coupon" ) }
                  onChange={ ( e ) => {
                    setFormValues( { ...formValues, coupon_code_changed: true, coupon_code: e?.target?.value } );
                    onChange( e?.target?.value );
                    clearErrors( "coupon_code" );
                    debouncedOnChange( e?.target?.value === "" && getValues( "has_coupon" ) ? getValues( "has_coupon" ) : e?.target?.value );
                  } }
                  label={ "Coupon" }
                />
              );
            } }
          />
          <div className="total-section">
            { `Amount to be paid : ` }{ " " }
            { loadingPrice ? (
              <CircularProgress color="inherit" size={ 20 } />
            ) : (
              getValues( "total" )
            ) }
          </div>
          <div className="modal-button-action">
            <PrimaryButton
              onClick={ () => handleStep( { next: false } ) }
              variant={ "outlined" }
              text={ "Back" }
              className="save-button"
            />
            <PrimaryButton
              type="submit"
              text={ "Proceed" }
              disabled={ loadingCoupon || loadingPrice || loading }
              className="save-button"
            />
          </div>
        </form>
      ) }
    </div>
  );
}
