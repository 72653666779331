//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
//COMPONENY
import TitleComponent from "../../../components/title-component/TitleComponent";
import TableComponent from "../../../components/table-component/TableComponent";
import TableUpsideSection from "../../../components/table-component/TableUpsideSection";
//API
import { dataPerPage } from "../../../core/variables/CoreVariables";
import TablePaginationSection from "../../../components/table-component/TablePaginationSection";
import { TableCell } from "@mui/material";
import RowComponent from "../../../components/table-component/RowComponent";
import { AlertContext } from "../../../context/AlertContext";
import { getAllInvoices } from "../../../core/apis/invoices";
import { DisplayDateTime } from "../../../components/display-components/DisplayComponent";
import { groupParamsByKey, } from "../../../core/functions/useDebounce";

export function Invoices( props ) {
  //VARIABLES
  let navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const { page, per_page, name, min_amount, max_amount, start_date, end_date } = groupParamsByKey( searchParams ) || {};
  const { setAlert } = useContext( AlertContext );
  const [ loading, setLoading ] = useState( false );
  const [ total, setTotal ] = useState( 0 );
  const [ data, setData ] = useState( [] );
  const [ totalPage, setTotalPage ] = useState( 0 );


  const tableHeaders = [
    {
      name: "Invoice",
    },

    {
      name: "Date",
    },

    {
      name: "Amount",
      align: "right",
    },
    {
      name: "Discount",
      align: "right",
    },
    {
      name: "Net",
      align: "right",
    },

    {
      name: "Coupon",
    },
  ];
  //Functions
  const GetData = () => {
    setLoading( true );
    getAllInvoices( {
      per_page: searchParams.get( 'per_page' ) || dataPerPage,
      page: +searchParams.get( 'page' ) || 1,
      search: searchParams.get( 'name' ) || "",
      min_amount: searchParams.get( 'min_amount' ) || null,
      max_amount: searchParams.get( 'max_amount' ) || null,
      ...searchParams.get( 'start_date' ) && { start_date: searchParams.get( 'start_date' ) },
      ...searchParams.get( 'end_date' ) && { end_date: searchParams.get( 'end_date' ) || "" },
    } ).then( ( response ) => {
      if ( response?.data?.success ) {
        setTotal( response.data.data?.invoices?.total );
        setTotalPage( response.data.data?.invoices?.last_page );
        setData(
          response.data.data?.invoices ? response.data.data.invoices?.data : []
        );
      } else {
        setAlert( {
          text: response.data ? response.data.message : response.message,
          error: true,
        } );
      }
      setLoading( false );
    } );
  };








  useEffect( () => {
    GetData();
  }, [ page, per_page, name, start_date, end_date, min_amount, max_amount ] );

  return (
    <>
      <TitleComponent title={ `Invoices` } classNames="page-title main-title" />
      <TitleComponent
        title={ `Total Invoices : ${ total ? total : 0 }` }
        classNames="page-title mini-title"
      />

      <TableUpsideSection
        filterable
        placeholder={ "Search by invoice number" }
        filterByDateRange
        filterByAmountRange
        search
        type="receipts"
      />
      <div className="margin-up-20">
        <TableComponent
          tableHeaders={ tableHeaders }
          loading={ loading }
          tableData={ data }
          sorted
        >
          { data?.map( ( element ) => (
            <RowComponent
              key={ element?.id }
              row={ element }
              handleRowClick={ () => navigate( `/invoices/detail/${ element?.id }` ) }
            >
              <TableCell >{ element.invoice_number }</TableCell>
              <TableCell>
                <DisplayDateTime data={ element.created_at } date />
              </TableCell>
              <TableCell>
                <div className="invoice-amount-display">
                  <div>{ element.subtotal }</div>
                  <div>{ element?.currency?.symbol }</div>
                </div>
              </TableCell>
              <TableCell>
                <div className="invoice-amount-display">
                  <div>{ element.coupon?.value }%</div>

                </div>
              </TableCell>
              <TableCell>
                <div className="invoice-amount-display">
                  <div>{ element.total }</div>
                  <div>{ element?.currency?.symbol }</div>
                </div>
              </TableCell>

              <TableCell className='long-tablecell-description'>
                { element.coupon?.code ? element.coupon?.code : "-" }
              </TableCell>
            </RowComponent>
          ) ) }
        </TableComponent>
      </div>
      <TablePaginationSection
        data={ data }
        total={ total }
        totalPage={ totalPage }

      />
    </>
  );
}

export default Invoices;
