//COMPONENT

import { PrimaryButton } from "../buttons/Buttons";
import { LinearLoaderComponent } from "../loader-component/LoaderComponent";

export function LoadMorePagination(props) {
  const { data, handleLoadChange, pageTotal, page, loading } = props;

  return (
    data?.length !== 0 && (
      <div className="load-more-pagination-section">
        {!loading ? (
          page < pageTotal && (
            <div>
              <PrimaryButton
                text={`Load More`}
                onClick={() => handleLoadChange("more")}
                loading={loading}
              />
            </div>
          )
        ) : (
          <LinearLoaderComponent />
        )}
      </div>
    )
  );
}

export default LoadMorePagination;
