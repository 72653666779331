//UTILITIES
import React from "react";

//COMPONENT
import { ThemeProvider } from "@mui/material/styles";
import AppRouter from "./core/routes/AppRouter";
import appTheme from "../src/assets/theme/MuiTheme";
import { CookiesProvider } from "react-cookie";
//CSS
import "./App.scss";

//SERVICES
import { SideBarContextProvider } from "./context/SideBarContext";
import { AlertContextProvider } from "./context/AlertContext";
import { UserContextProvider } from "./context/UserContext";
import { SubscriptionContextProvider } from "./context/SubscriptionContext";

function App() {
  return (
    <>
      <ThemeProvider theme={ appTheme }>
        <SideBarContextProvider>  {/*to trigger the open/close sidebar css*/ }
          <CookiesProvider>  {/*for using cookies to save user status*/ }
            <UserContextProvider>
              <SubscriptionContextProvider> {/*to save subscription info*/ }
                <AlertContextProvider> {/*trigger alert message*/ }
                  <AppRouter />
                </AlertContextProvider>
              </SubscriptionContextProvider>
            </UserContextProvider>
          </CookiesProvider>
        </SideBarContextProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
