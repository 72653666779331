//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AlertContext } from "../../../context/AlertContext";
import { UserContext } from "../../../context/UserContext";
import { SubscriptionContext } from "../../../context/SubscriptionContext";

//SCSS
import "./Cards.scss";

//COMPONENT
import StripeContainer from "./StripeContainer";
import ResponseMessage from "../../../components/response-messages/ResponseMessage";
import NoDataFound from "../../../components/response-messages/NoDataFound";
import NoticeConfirmation from "../../../components/dialog-component/NoticeConfirmation";
import { DeleteSVG } from "../../../assets/svg/SVG";
import { FormSwitch } from "../../../components/form-component/FormComponent";
import {
  DeleteCard,
  GetAllCard,
  UpdateDefaultCard,
} from "../../../core/apis/cardManagement";
import { Button, Radio, Skeleton, Tooltip } from "@mui/material";
import { PrimaryButton } from "../../../components/buttons/Buttons";
import NotSavedImage from "../../../assets/svg/save-off.svg";

//API
import {
  subscribePlan,
  renewPlan,
  upgradeSubscription,
} from "../../../core/apis/subscriptions";

const HandleSchema = yup.object( {
  card: yup.object().nullable().required( "Please select a card or add new" ), //selected card
  unsaved_card: yup.object().nullable(), //unsaved card if user enter it(to saved it incase needed)
  auto_renew: yup.boolean(),
} );

//card management is used in account details cards and in checkout
export function CardManagement( props ) {
  let navigate = useNavigate();

  let { is_checkout, handleStep, formValues, setFormValues } = props;
  //VARIABLES
  const { setAlert } = useContext( AlertContext );
  const { user, updateUser } = useContext( UserContext );
  const { updateSubscriptionItems } = useContext( SubscriptionContext )
  const { type } = useParams();
  const {
    setValue,
    getValues,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm( {
    defaultValues: {
      card: formValues?.card, //the selected card
      unsaved_card: formValues?.unsaved_card, //because if there is an unsaved card it should always be saved
      auto_renew: formValues?.auto_renew,
    },
    resolver: yupResolver( HandleSchema ),
  } );

  const [ cards, setCards ] = useState( [] );
  const [ loading, setLoading ] = useState( false );
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ openCardHandle, setOpenCardHandle ] = useState( false );

  const [ deleteAdd, setDeleteAdd ] = useState( {
    open: false,
    id: null,
  } );


  const GetCardsData = () => {
    setLoading( true );
    GetAllCard().then( ( res ) => {
      if ( res?.data?.success ) {
        setCards( res.data.data );
        //if we are in checkout and the user didn't select yet a payment method id
        if ( !formValues?.card && is_checkout ) {
          let defaultSelected = res?.data?.data?.find( ( el ) => el?.is_default === 1 );
          setFormValues( {
            ...formValues,
            card: defaultSelected,
          } );
          setValue(
            "card",
            defaultSelected,
            { shouldValidate: true }
          );
        }
      }
      setLoading( false );
    } );
  };

  useEffect( () => {
    GetCardsData();
  }, [] );


  //to not use setFormValues each time, unified
  useEffect( () => {
    //if user added a new unsaved card , auto renew should be false , if the user added a new card and save it
    //then the auto renew should be based on the previous value
    if ( is_checkout ) {
      setFormValues( {
        ...formValues,
        card: watch( 'card' ),
        auto_renew: watch( 'auto_renew' ),
        unsaved_card: watch( 'unsaved_card' )
      } )
    }
  }, [ getValues( 'card' ), getValues( 'auto_renew' ), getValues( 'unsaved_card' ) ] )


  const handleSetUnsaved = () => {
    setValue( "card", watch( "unsaved_card" ), {
      shouldValidate: true,
    } );
  }


  const handleChange = ( cardElement ) => {
    setValue( "card", cardElement, {
      shouldValidate: true,
    } );
    //if he select a card but he initially doesn't have a default card
    if ( user?.default_card === 0 ) {
      setValue( 'auto_renew', false, { shouldValidate: true } )
    }
  };

  const handleDeleteResponse = ( type, id ) => {
    if ( type === "yes" ) {
      setLoading( true );
      DeleteCard( id ).then( ( res ) => {
        if ( res.data?.success ) {
          GetCardsData();
          updateUser();
        }

        setAlert( {
          text: res.data ? res.data.message : res.message,
          error: !res?.data?.success,
        } );
      } );
      setDeleteAdd( {
        open: false,
        id: null,
      } );
    } else {
      setDeleteAdd( {
        open: false,
        id: null,
      } );
    }
  };

  const UpdateDefault = ( id ) => {
    setLoading( true );
    UpdateDefaultCard( id ).then( ( res ) => {
      if ( res?.data?.success ) {
        GetCardsData();
        updateUser(); //to update the field default_card
      } else {
        setLoading( false );
      }

      setAlert( {
        text: res.data ? res.data.message : res.message,
        error: !res?.data?.success,
      } );
    } );
  };

  const handleSubmitForm = ( formData ) => {
    let all_values = {
      fiscal_number: formValues?.fiscal_number,
      has_company: formValues?.has_company,
      ...( formValues?.has_company && {
        company_name: formValues?.company_name,
      } ),
      address_id: formValues?.address_id,
      payment_method_slug: 'card',
      plan_price_id: formValues?.plan_price_id?.id,
      auto_renew: formData.auto_renew,
      upgrade_now: formValues?.upgrade_now,
      coupon_code: formValues?.coupon_code,
      ...formData?.card?.id ? { user_payment_method_id: formData?.card?.id } : { stripe_payment_method_id: formData?.card?.payment_method_id },
    };

    if ( formValues?.auto_renew && user?.default_card === 0 ) {
      //another recheck
      setAlert( {
        text: "Auto-renewal is not possible as there is no default card.",
        error: true,
      } );
      setValue( 'auto_renew', false, { shouldValidate: true } );
      setFormValues( { ...formValues, auto_renew: false, } )
    }
    else {


      try {
        setSaveLoading( true );
        if ( type === "renew" ) {
          renewPlan( all_values )
            .then( async ( res ) => {
              if ( res?.data?.success ) {
                await updateUser();
                navigate( `/invoices/detail/${ res.data.data }` );

                updateSubscriptionItems();
              }
              setAlert( {
                text: res.data ? res.data.message : res.message,
                error: !res?.data?.success,
              } );
              setSaveLoading( false );
            } )
            .catch( ( e ) => setSaveLoading( false ) );
        } else if ( type === "subscribe" ) {
          subscribePlan( all_values )
            .then( async ( res ) => {
              if ( res?.data?.success ) {
                await updateUser();
                navigate( `/invoices/detail/${ res.data.data }` );

                updateSubscriptionItems();
              }
              setAlert( {
                text: res.data ? res.data.message : res.message,
                error: !res?.data?.success,
              } );
              setSaveLoading( false );
            } )
            .catch( ( e ) => setSaveLoading( false ) );
        } else if ( type === "upgrade" ) {
          upgradeSubscription( all_values )
            .then( async ( res ) => {
              if ( res?.data?.success ) {
                await updateUser();
                navigate( `/invoices/detail/${ res.data.data }` );
                updateSubscriptionItems();
              }
              setAlert( {
                text: res.data ? res.data.message : res.message,
                error: !res?.data?.success,
              } );
              setSaveLoading( false );
            } )
            .catch( ( e ) => setSaveLoading( false ) );
        }
      } catch ( e ) {
        setSaveLoading( false );
      }
    }
  };

  const handleSaveCard = ( value ) => {
    GetCardsData();
    if ( is_checkout ) {
      reset( {
        unsaved_card: null,
        card: value?.card,
        auto_renew: value?.auto_renew,
      } );
      setFormValues( {
        ...formValues,
        unsaved_card: null,
        card: value?.card,
        auto_renew: value?.auto_renew,
      } )
    }
  }


  const handleUnsavedCard = ( value ) => {
    reset( {
      card: value?.card,
      unsaved_card: value?.card,
      auto_renew: false,
    } );
    setFormValues( {
      ...formValues,
      card: value?.card,
      auto_renew: false,
      unsaved_card: value?.card
    } )
    setOpenCardHandle( false )
  };

  const prevStep = () => {
    handleStep( { next: false } );
  };

  const RemoveNotSaved = () => {
    let check_card = cards?.length !== 0 ? !getValues( 'card' )?.id ? cards?.find( ( el ) => el?.is_default === 1 ) : getValues( "card" ) : null
    reset( {
      unsaved_card: null,
      auto_renew: formValues?.auto_renew,
      card: check_card,
    } );
    setFormValues( {
      ...formValues,
      card: check_card,
      auto_renew: formValues?.auto_renew,
      unsaved_card: null,
    } )

  };


  return (
    <div className="card-management-component">

      { !loading ? (
        <>
          { is_checkout && errors?.card?.message !== "" && (
            <ResponseMessage
              message={ errors?.card?.message }
            />
          ) }
          <form onSubmit={ handleSubmit( handleSubmitForm ) }>
            <div
              className={ `card-management-header ${ ( is_checkout && !watch( 'card' )?.id || !is_checkout ) ? "only-add" : ""
                }` }
            >
              { is_checkout && watch( "card" )?.id && (

                <Controller
                  render={ ( {
                    field: { onChange, value },
                    fieldState: { error },
                  } ) => (
                    <FormSwitch
                      noFormComponent
                      labelI="Auto Renew"
                      value={ value }
                      onChange={ ( e ) => {
                        //check if user doesn't have a default card , he cannot proceed with auto renew
                        if ( e === true && user?.default_card === 0 ) {

                          setAlert( {
                            text: "Auto-renewal is not possible as there is no default card.",
                            error: true,
                          } );
                        } else {

                          onChange( e );
                        }

                      } }
                    />
                  ) }
                  name="auto_renew"
                  control={ control }
                />

              ) }
              { ( !is_checkout || !watch( 'unsaved_card' ) ) && (
                <div>
                  <PrimaryButton
                    onClick={ () => setOpenCardHandle( true ) }
                    text={ "New Card" }
                    className="save-button"
                  />
                </div>

              ) }
            </div>
            { watch( 'unsaved_card' ) || cards?.length !== 0 ? (
              <div className="card-management-radio-group">
                <div className="card-management-radio-group">
                  { watch( 'unsaved_card' ) && (
                    <div className="card-management-element">
                      { is_checkout && (
                        <Radio
                          className="radio-button"
                          onChange={ ( e ) => handleSetUnsaved() }
                          checked={ !watch( "card" )?.id }
                        />
                      ) }
                      <div className="card-detail-section">
                        <div className={ `card-name not-saved` }>
                          { `${ getValues( "unsaved_card" )?.name }, ${ getValues( 'unsaved_card' )?.brand
                            } **** ****
                        **** ${ getValues( "unsaved_card" )?.last4 }` }

                          <div className="icon">
                            <img
                              style={ { widthsetDeleteAdd: "100%", height: "100%" } }
                              src={ NotSavedImage }
                            />
                          </div>
                        </div>
                        <div className={ `action-section radio-default-end` }>
                          <div>
                            <Button
                              sx={ { color: "red" } }
                              onClick={ () => RemoveNotSaved() }
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) }
                  { cards?.map( ( cardElement, index ) => {
                    return (
                      <div key={ index } className="card-management-element">
                        { is_checkout && (
                          <Radio
                            className="radio-button"
                            onChange={ ( e ) => handleChange( cardElement ) }
                            checked={ cardElement.id === watch( "card" )?.id }
                          />
                        ) }
                        <div className="card-detail-section">
                          <div className={ `card-name` }>
                            { `${ cardElement.card_info.name },
                        ${ cardElement.card_info.brand } **** **** ****
                        ${ cardElement.card_info.last_4 }` }
                          </div>
                          <div className={ `action-section` }>

                            { cardElement.is_default === 0 && ( <div
                              className="default"
                              onClick={ () => UpdateDefault( cardElement.id ) }
                            >
                              Set as default
                            </div> ) }
                            { !is_checkout && (
                              <Tooltip title={ user?.latest_subscription?.is_auto_renew && cardElement.is_default === 1 ? "You need to disable auto-renewal to delete the default card." : "" }>
                                <DeleteSVG
                                  className={ `cursor-pointer ${ user?.latest_subscription?.is_auto_renew && cardElement.is_default === 1 ? "disabled-icon" : "" }` } //if user subscription is auto renew and this card is the default
                                  onClick={ () =>
                                    ( !user?.latest_subscription?.is_auto_renew || ( user?.latest_subscription?.is_auto_renew && cardElement.is_default === 0 ) )
                                    &&
                                    setDeleteAdd( {
                                      open: true,
                                      id: cardElement.id,
                                    } )
                                  }
                                /> </Tooltip> ) }


                          </div>
                        </div>
                      </div>
                    );
                  } ) }
                </div>
              </div>
            ) : (
              <NoDataFound
                variant="text"
                text="No Saved Card Found"
                normal={ true }
              />
            ) }
            { is_checkout && (
              <div className="modal-button-action">
                <PrimaryButton
                  onClick={ prevStep }
                  variant={ "outlined" }
                  text={ "Back" }
                  className="save-button"
                />
                <PrimaryButton
                  type="submit"
                  text={ "Proceed" }
                  loading={ saveLoading }
                  disabled={ saveLoading ? true : false }
                  className="save-button"
                />
              </div>
            ) }
          </form>
          { openCardHandle && (
            <StripeContainer
              is_checkout={ is_checkout }
              closeModal={ () => {
                setDeleteAdd( {
                  ...deleteAdd,
                  id: null,
                } );
                setOpenCardHandle( false );
              } }
              formValues={ formValues }
              handleSaveCard={ handleSaveCard }
              handleUnsavedCard={ handleUnsavedCard }
            />
          ) }
          { deleteAdd.open && (
            <NoticeConfirmation
              handleResponse={ handleDeleteResponse }
              data={ deleteAdd?.id }
            />
          ) }
        </>
      ) : (
        <Skeleton
          variant="rectangular"
          height={ 20 }
          style={ { marginTop: "3%" } }
        />
      ) }
    </div>
  );
}

export default CardManagement;
