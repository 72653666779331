import React, { useState } from "react";
import palette from "../../assets/theme/color.scss";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { groupParamsByKey, objectCleaner } from "../../core/functions/useDebounce";
//COMPONENT
import FilterComponent from "../filter-component/FilterComponent";
import { FormSearchBar } from "../form-component/FormComponent";
import { PrimaryButton } from "../buttons/Buttons";
import { ExportSVG } from "../../assets/svg/SVG";
import { useDebouncedCallback } from "use-debounce";
//CSS
import "./TableComponent.scss";



const TableUpsideSection = (props) => {
  const {
    filterable,
    placeholder,
    addButtonTitle,
    addButton,
    handleDrag,
    exportFunctionality,
    search,
    handleDelete,
    dragChecked,
    loadingDrag,
    children,
  } = props;
  //VARIABLES
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setname] = useState(searchParams.get("name") || "");

  const debounced = useDebouncedCallback(
    // function
    (value) => {
      let url = {
        ...groupParamsByKey(searchParams),
        name: value,
        page: 1,
      };
      setSearchParams(objectCleaner(url));

    },
    // delay in ms
    500
  );

  return (
    <div className="header-section">
      <div className="search-bar-section">
        {search && (
          <FormSearchBar
            placeholder={placeholder ? placeholder : "Search..."}
            variant="outlined"
            value={name}
            onChange={(value) => {
              setname(value);
              debounced(value);
            }}
          />
        )}
      </div>
      <div className="button-section">
        {filterable && (
          <div>
            <FilterComponent {...props}>{children}</FilterComponent>
          </div>
        )}
        {handleDelete && (
          <div>
            <PrimaryButton text={"Delete"} onClick={handleDelete} />
          </div>
        )}
        {handleDrag && (
          <div>
            <PrimaryButton
              text={dragChecked ? "Save" : "Reorder"}
              onClick={handleDrag}
              loading={dragChecked ? loadingDrag : false}
            />
          </div>
        )}
        {exportFunctionality && (
          <div>
            <PrimaryButton
              icon={<ExportSVG fill={palette.whiteColor} />}
              onClick={exportFunctionality}
            />
          </div>
        )}
        {addButton && (
          <div>
            <PrimaryButton
              text={addButtonTitle ? addButtonTitle : "New"}
              onClick={addButton}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default TableUpsideSection;
TableUpsideSection.defaultProps = {
  search: true,
  filterable: true,
  filterByAmountRange: false,
  filterByDateRange: false,
  filterBySubStatus: false,
  filterByJoinDate: false,
  filterByPlan: false,
  loadingDrag: false,
};
TableUpsideSection.propTypes = {
  search: PropTypes.bool,
  loadingDrag: PropTypes.bool,
  filterable: PropTypes.bool,
  filterByAmountRange: PropTypes.bool,
  filterByDateRange: PropTypes.bool,
  filterBySubStatus: PropTypes.bool,
  filterByJoinDate: PropTypes.bool,
  filterByPlan: PropTypes.bool,
  handleDateChange: PropTypes.func,
  handleSearchFilter: PropTypes.func,
  exportFunctionality: PropTypes.func,
};
