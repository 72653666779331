//RPIVATE ROUTES FOR EACH ROUTE
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import LoaderComponent from "../../components/loader-component/LoaderComponent"
import { UserContext } from "../../context/UserContext";

const ConditionalRoutes = ( props ) => {

  const { children } = props;
  const { isSubscribed } = useContext( UserContext )
  /*because without that check , if is subscribed is undefined the user is redirected to the subscription
  for an instant then to the sessions*/
  return isSubscribed === undefined ? <LoaderComponent /> : isSubscribed ? children : <Navigate to="/subscriptions" replace />;

};

export default ConditionalRoutes;
