import { api } from "./main";

export const getAllSessions = (payload) => {
  let page = payload?.page ? `?page=${payload.page}` : "";
  return api
    .get(`get-all-sessions${page}`, {
      params: { ...payload },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const checkSessionName = async (payload) => {
  return await api
    .get(`check-session-name`, { params: { ...payload } })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const createSession = async (payload) => {
  return await api
    .post(`create-session`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const sendSessionInvitation = async (payload) => {
  return await api
    .post(`send-invitation`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteSession = async (payload) => {
  return await api
    .delete(`delete-session/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const startSession = async (payload) => {
  return await api
    .patch(`start-session/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};