//UTILITIES
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import palette from "../assets/theme/color.scss";
//COMPONENT
import { List } from "@mui/material";
import { LandingDrawer, DrawerHeader, } from "../assets/theme/SpecialComponent";
import { LandingPageItems } from "../core/variables/LandingPageItems";
import CustomizedListItem from "../components/side-bar-component/CustomizedListItem";
import logo from "../assets/images/Ceci_tool_white.png";
import { BurgerSVG } from "../assets/svg/SideBarSVG";
import { LinkButton } from "../components/buttons/Buttons";

//CSS
import "./NavigationBar.scss";


export function LandingNavbar( props ) {
  //VARIABLES
  let navigate = useNavigate();
  let { session, user, } = useContext( UserContext );


  const [ openSideBar, setOpenSideBar ] = React.useState( false );
  //FUNCTIONS
  const CheckSelected = ( title ) => {
    let boolean = false;

    if ( window.location.pathname.startsWith( `/${ title }` ) ) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  const handleCloseDrawer = () => {
    setOpenSideBar( false );
  };
  const handleSideBar = () => {
    setOpenSideBar( !openSideBar );
  };

  return (
    <>
      <div className="landing-navigation-bar">
        <div className="landing-navigation-bar-container">
          { !openSideBar && (
            <div className="landing-logo">

              <BurgerSVG
                onClick={ () => handleSideBar() }
                fill={ "white" }
                className="landing-burger cursor-pointer"
              />
              <div>
                <img src={ logo } alt="LOGO" onClick={ () => navigate( "/" ) } />
              </div>
            </div>
          ) }
          <div className="nav-links">
            <div>
              <LinkButton
                className={
                  CheckSelected( "home" )
                    ? "link-selected text-underline"
                    : "text-underline"
                }
                text="Home"
                onClick={ () => navigate( "/home" ) }
                color="secondary"
              />
            </div>
            <div>
              <LinkButton
                className={
                  CheckSelected( "pricing" )
                    ? "link-selected text-underline"
                    : "text-underline"
                }
                text="Pricing"
                onClick={ () => navigate( "/pricing" ) }
                color="secondary"
              />
            </div>
            <div>
              <LinkButton
                className={
                  CheckSelected( "about" )
                    ? "link-selected text-underline"
                    : "text-underline"
                }
                text="About Us"
                onClick={ () => navigate( "/about" ) }
                color="secondary"
              />
            </div>
            <div>
              <LinkButton
                className={
                  CheckSelected( "contact" )
                    ? "link-selected text-underline"
                    : "text-underline"
                }
                text="Contact"
                onClick={ () => navigate( "/contact" ) }
                color="secondary"
              />
            </div>
          </div>

          <div className="side-items">
            { !session ? (
              <>
                <div>
                  <LinkButton
                    color="white"
                    text="Sign Up"
                    className="side-items-text"
                    onClick={ () => {
                      navigate( "/signup" );
                    } }
                  />
                </div>
                <div className="items-seperator"></div>
                <div>
                  <LinkButton
                    color="white"
                    text="Log In"
                    className="side-items-text"
                    onClick={ () => {
                      navigate( "/login" );
                    } }
                  />
                </div>
              </>
            ) : (
              <div>
                <LinkButton
                  color="white"
                  text="My Account"
                  className="side-items-text"
                  onClick={ () => {
                    navigate(
                      user?.latest_subscription ? "/sessions" : "/subscriptions"
                    );
                  } }
                />
              </div>
            ) }
          </div>
        </div>
      </div>
      <LandingDrawer
        sx={ {
          width: 300,
          flexShrink: 0,
        } }
        variant="persistent"
        anchor="left"
        open={ openSideBar }
        ModalProps={ {
          keepMounted: true,
        } }
      >
        <DrawerHeader className="drawer-header-container">
          <div className="text">
            <img
              src={ logo }
              alt="LOGO"
              onClick={ () => ( session ? navigate( "/" ) : navigate( "/home" ) ) }
            />
          </div>
          <div className="burger">
            <BurgerSVG
              onClick={ handleSideBar }
              className="cursor-pointer"
            /></div>
        </DrawerHeader>
        <List className="landing-list">
          { LandingPageItems.map( ( item, index ) => (
            <CustomizedListItem
              key={ item.id }
              item={ item }
              index={ index }
              length={ LandingPageItems.length }
              handleCloseDrawer={ handleCloseDrawer }
            />
          ) ) }{ " " }
        </List>
      </LandingDrawer>
    </>
  );
}
export default LandingNavbar;
