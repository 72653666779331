//UTILITIES
import React, { useState, } from "react";
import PropTypes from "prop-types";
import moment from 'moment'
//COMPONENT
import { StyledFilterMenu } from "../../assets/theme/SpecialComponent";
import { LinkButton, PrimaryButton } from "../buttons/Buttons";
import { FilterIcon } from "../../assets/svg/SVG";
import { MenuItem } from "@mui/material";

import NoDataFound from "../response-messages/NoDataFound";
import LoaderComponent from "../loader-component/LoaderComponent";
import { FormRadioButton } from "../form-component/FormComponent";
import {
  FormSearchBar,
  FormCheckBox,
  FormFilterDateRange,
  FormFilterAmountRange,
} from "../form-component/FormComponent";
//CSS
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css fi
import "./FilterComponent.scss";
import { groupParamsByKey, objectCleaner } from "../../core/functions/useDebounce";
import { useSearchParams } from "react-router-dom";
//API

const FilterComponent = (props) => {
  //STATES
  const {
    filterByDateRange,
    filterByDoneBy,
    filterByAmountRange,
    rangeOn,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [filterSearch, setFilterSearch] = useState({
    status: "",
    subscriptionPlanId: "",
  });

  const DoneByData = [
    {
      name: "All",
      id: null,
    },
    {
      name: "Admin",
      id: "true",
    },
    {
      name: "Me",
      id: "false",
    },
  ];

  const openFilterMenu = Boolean(anchorEl);

  const FilterArray = [
    {
      type: "radio",
      field: "is_admin",
      title: "Uploaded By",
      data: DoneByData,
      defaultValue: null,
      textSeach: false,
      visible: filterByDoneBy,
    },
    {
      type: "date",
      field: rangeOn,
      title: rangeOn,
      textSeach: false,
      visible: filterByDateRange,
    },

    {
      type: "number-range",
      field: "amount",
      title: "Amount Range",
      textSeach: false,
      visible: filterByAmountRange,
    },
  ];

  const FilterBox = FilterArray?.filter((el) => el.visible);

  //FUNCTIONS

  const handleMenuPopClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuPopClose = () => {
    setAnchorEl(null);
  };

  //FUNCTIONS
  const handleAmountChange = (value) => {

    let newFilters = {
      ...groupParamsByKey(searchParams),
      min_amount: value[0],
      max_amount: value[1],
      page: 1,
    };
    setSearchParams(objectCleaner(newFilters));
  };



  const handleDateChange = (value) => {
    let newFilters = {
      ...groupParamsByKey(searchParams),
      start_date: value.startDate
        ? moment(value.startDate).format("YYYY-MM-DD")
        : null,
      end_date: value.endDate
        ? moment(value.endDate).format("YYYY-MM-DD")
        : null,
      page: 1,
    };
    setSearchParams(objectCleaner(newFilters));

  };
  const handleResetFilter = () => {
    let newFilters = {
      ...groupParamsByKey(searchParams),
      start_date: "",
      end_date: "",
      is_admin: null,
      min_amount: null,
      max_amount: null,
      page: 1,
    };
    setSearchParams(objectCleaner(newFilters));
  };

  const handleCheckboxChange = (field, value) => {
    const fieldValue = searchParams.getAll([field]);
    if (fieldValue?.length !== 0) {
      if (value?.value === false) {
        let find_item = fieldValue.indexOf(value.name?.toString());
        fieldValue.splice(find_item, 1);
      } else {
        fieldValue.push(value.name?.toString());
      }
      let url = {
        ...groupParamsByKey(searchParams),
        [field]: fieldValue,
        page: 1,
      };
      setSearchParams(objectCleaner(url));
    } else {
      let url = {
        ...groupParamsByKey(searchParams),
        [field]: [value.name?.toString()],
        page: 1,
      };

      setSearchParams(objectCleaner(url));
    }
  };

  const handleRadioChange = (field, value) => {
    let url = {
      ...groupParamsByKey(searchParams),
      [field]: value?.id,
      page: 1,
    };
    setSearchParams(objectCleaner(url));

  };


  const FilteredData = (data, field, filter_boolean) => {
    if (filterSearch[field] !== "" && filter_boolean) {
      return data.filter((item) => {
        item.name.toLowerCase().includes(filterSearch[field].toLowerCase());
      });
    } else {
      return data;
    }
  };

  return (
    <>
      <PrimaryButton
        id="basic-button"
        icon={<FilterIcon />}
        aria-haspopup="true"
        aria-expanded={openFilterMenu ? "true" : undefined}
        onClick={handleMenuPopClick}
      />
      <StyledFilterMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openFilterMenu}
        onClose={handleMenuPopClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {FilterBox?.map((item, index) =>

          <MenuItem
            divider={index === FilterBox?.length - 1 ? false : true}
            disableRipple
            key={index}
          >
            <div className="filter-item-container">
              <div className="filter-item-header">
                <div className="title"> {item?.title}</div>
                {index === 0 && (
                  <div>
                    <LinkButton
                      text="Clear"
                      default={true}
                      onClick={handleResetFilter}
                      color="primary"
                    />
                  </div>
                )}
              </div>
              {item?.type === "text" ? (<div className="filter-item-body">
                {item.textSeach && (
                  <FormSearchBar
                    variant="standard"
                    placeholder="Search..."
                    value={filterSearch[item.field]}
                    onChange={(value) => {
                      setFilterSearch({
                        ...filterSearch,
                        [item?.field]: value,
                      });
                    }}
                  />
                )}
                <div className="checkboxes-spaces">
                  {!loading ? (
                    item.data && item.data.length !== 0 ? (
                      FilteredData(item.data, item.field, item.textSeach).map(
                        (sub_item, sub_index) => (
                          <FormCheckBox
                            value={sub_item?.value}
                            key={sub_index}
                            checked={searchParams
                              .getAll(item?.field)
                              ?.includes(sub_item?.id?.toString())}
                            label={sub_item.name}
                            onChange={(value) =>
                              handleCheckboxChange(item?.field, {
                                name: sub_item.value,
                                value: value,
                              })
                            }
                          />
                        )
                      )
                    ) : (
                      <NoDataFound variant="text" />
                    )
                  ) : (
                    <LoaderComponent />
                  )}
                </div>
              </div>) : item?.type === "radio" ? (<div className="checkboxes-spaces">
                {!loading ? (
                  item?.data && item?.data.length !== 0 ? (
                    <FormRadioButton
                      row={false}
                      data={item?.data || []}
                      value={
                        searchParams.get(item?.field) && item?.data
                          ? item?.data?.find(
                            (val) => val?.id == searchParams.get(item?.field)
                          )
                          : item?.data?.find(
                            (val) => val?.id == item?.defaultValue
                          )
                      }
                      onChange={(value) =>
                        handleRadioChange(item?.field, value)
                      }
                    />
                  ) : (
                    <NoDataFound variant="text" />
                  )
                ) : (
                  <LoaderComponent />
                )}
              </div>) : item?.type === 'date' ? (<div>
                <FormFilterDateRange
                  onChange={(value) => {
                    handleDateChange(value);
                  }}
                  value={
                    !searchParams.get('start_date') && !searchParams.get('end_date')
                      ? {
                        startDate: new Date(),
                        endDate: new Date(),
                      }
                      : {
                        startDate: searchParams.get('start_date')
                          ? new Date(searchParams.get('start_date'))
                          : null,
                        endDate: searchParams.get('end_date')
                          ? new Date(searchParams.get('end_date'))
                          : null,
                      }
                  }
                />
              </div>) : item?.type === 'number-range' ? (<FormFilterAmountRange
                value={[
                  searchParams.get('min_amount') ? searchParams.get('min_amount') : null,
                  searchParams.get('max_amount') ? searchParams.get('max_amount') : null,
                ]}
                onChange={(value) => handleAmountChange(value)}
              />) : ""}
            </div>
          </MenuItem>
        )}
      </StyledFilterMenu>
    </>
  );
};

export default FilterComponent;

FilterComponent.defaultProps = {
  filterByAmountRange: false,
  filterByDateRange: false,
  rangeOn: "Date",
};
FilterComponent.propTypes = {
  rangeOn: PropTypes.string,
  filterByAmountRange: PropTypes.bool,
  filterByDateRange: PropTypes.bool,
};
