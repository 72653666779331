//UTILITIES
import React from "react";
import PropTypes from "prop-types";
//COMPONENT
import { DialogContent, DialogActions } from "@mui/material";
import DialogCustomTitle from "./DialogCustomTitle";
import { NoticeDialog } from "../../assets/theme/SpecialComponent";
import { PrimaryButton } from "../buttons/Buttons";

//CSS
import "./DialogComponent.scss";
export function NoticeConfirmation(props) {
  //VARIABLES
  const { data, handleResponse, message, actions } = props;

  return (
    <NoticeDialog open={true}>
      <DialogCustomTitle
        title="Notice"
        onClose={() => handleResponse("cancel", data)}
      />
      <DialogContent>
        <div className="delete-without-reason-prompt">
          <div>{message}</div>
        </div>
      </DialogContent>
      {actions && (
        <DialogActions>
          <PrimaryButton
            text="No"
            variant="outlined"
            onClick={() => {
              handleResponse("no", data);
            }}
          />
          <PrimaryButton
            text="Yes"
            onClick={() => {
              handleResponse("yes", data);
            }}
          />
        </DialogActions>
      )}
    </NoticeDialog>
  );
}
export default NoticeConfirmation;
NoticeConfirmation.defaultProps = {
  actions: true,
  message: "Are you sure you want to delete?",
};
NoticeConfirmation.propTypes = {
  actions: PropTypes.bool,
  message: PropTypes.string,
};
