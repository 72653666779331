import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
//COMPONENT
import { PrimaryButton } from "../../../components/buttons/Buttons";

import { FormPassword } from "../../../components/form-component/FormComponent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
//CSS
import "./ResetPassword.scss";
//API
import { ResetUserPassword } from "../../../core/apis/users";
import { AlertContext } from "../../../context/AlertContext";
import TitleComponent from "../../../components/title-component/TitleComponent";
import { Card, CardContent } from "@mui/material";

export function ResetPassword( props ) {
  let navigate = useNavigate();
  const { setAlert } = useContext( AlertContext );
  const [ token, setToken ] = useState( null );
  const [ saveLoading, setSaveLoading ] = useState( false );

  const HandleSchema = yup.object( {
    password: yup
      .string( "Invalid Field" )
      .min( 6, "Field should be of minimum 6 characters length" )
      .required( "Field is required" ),
    password_confirmation: yup
      .string( "Invalid Field" )
      .oneOf( [ yup.ref( "password" ), null ], "Passwords must match" )
      .required( "Field is required" ),
  } );

  //INITIATE USE FORM
  const { control, handleSubmit } = useForm( {
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
    resolver: yupResolver( HandleSchema ),
  } );

  const handleSubmitForm = ( formValues ) => {
    setSaveLoading( true );
    ResetUserPassword( { ...formValues, token: token } ).then( ( response ) => {
      if ( response?.data?.success ) {
        navigate( "/password-changed" );
      } else {
        setAlert( {
          text: response.data ? response.data.message : response.message,
          error: true,
        } );
      }
      setSaveLoading( false );
    } );
  };

  useEffect( () => {
    let id = window.location.href.split( "/" );
    id = id[ id.length - 1 ];
    setToken( id );
  }, [] );

  return (
    <div className="page-container">
      <Card className="resetpassword-container">
        <CardContent className="content">
          <TitleComponent
            title="Reset Password"
            classNames="form-title main-title"
          />
          <form
            className="reset-form"
            title={ "Reset Password" }
            onSubmit={ handleSubmit( handleSubmitForm ) }
          >

            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormPassword
                  required
                  isAuth
                  name="password"
                  label="Password"
                  placeholder={ "Enter password" }
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name={ "password" }
              control={ control }
            />


            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormPassword
                  required
                  isAuth
                  name="password_confirmation"
                  label="Confirm Password"
                  placeholder={ "Enter password" }
                  value={ value }
                  onChange={ ( e ) => {
                    onChange( e );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="password_confirmation"
              control={ control }
            />


            <div className="flex-column-wrap authentication-action">
              <PrimaryButton
                type="submit"
                text="Save"
                className="login-button"
                loading={ saveLoading }
                disabled={ saveLoading ? true : false }
              />
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default ResetPassword;
