//UTILITIES
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
//COMPONENT
import TitleComponent from "../../../components/title-component/TitleComponent";

//API
import RoutingTabComponent from "../../../components/tab-component/RoutingTabComponent";

export function AccountProfile( props ) {
  //VARIABLES



  const [ tabContent, setTabContent ] = useState( [
    {
      id: 1,
      title: "Personal Info",
      path: `/account/personal-info`,
    },
    { id: 2, title: "Security", path: `/account/change-password` }, {
      id: 3,
      title: "Billing Address",
      path: `/account/billing-address`,
    },
    {
      id: 4,
      title: "Card Management",
      path: `/account/card-management`,
    }
  ] );



  return (
    <div>
      <TitleComponent title={ `Account` } classNames="page-title main-title" />
      <RoutingTabComponent withBorder tabContent={ tabContent } />

      <div className="margin-up-20">
        <Outlet />
      </div>
    </div>
  );
}

export default AccountProfile;
