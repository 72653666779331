//REACT UTILITIES
import React, { useContext, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//COMPONENT

//ACCOUNT
import AccountInfo from "../../pages/portal-pages/account/AccountInfo";
import AccountProfile from "../../pages/portal-pages/account/AccountProfile";
import AccountPassword from "../../pages/portal-pages/account/AccountPassword";

//SUBSCRIPTIONS
import Subscriptions from "../../pages/portal-pages/subscriptions/Subscriptions";

//SESSION
import SessionsHandle from "../../pages/portal-pages/session/SessionHandle";
import Sessions from "../../pages/portal-pages/session/Sessions";

//IMAGES
import ImagesCategories from "../../pages/portal-pages/image-management/ImagesCategories";
import CategoryImages from "../../pages/portal-pages/image-management/CategoryImages";

//AUTHENTICATION
import ResetPassword from "../../pages/authentication-pages/reset-password/ResetPassword";
import PasswordChanged from "../../pages/authentication-pages/authentication-messages/PasswordChanged";
import LinkSent from "../../pages/authentication-pages/authentication-messages/LinkSent";
import Login from "../../pages/authentication-pages/login/Login";
import ForgotPassword from "../../pages/authentication-pages/forgot-password/ForgotPassword";
import SignUpForm from "../../pages/authentication-pages/singup/SignUpForm";

//LANDING
import Pricing from "../../pages/landing-pages/pricing/Pricing";
import Home from "../../pages/landing-pages/home/Home";
import Contact from "../../pages/landing-pages/contact/Contact";
import About from "../../pages/landing-pages/about/About";

//INVOICES
import Invoices from "../../pages/portal-pages/invoices/Invoices";
import InvoicesDetail from "../../pages/portal-pages/invoices/InvoicesDetail";

import LayoutWithNavbar from "../../layout/LayoutWithNavbar";

//COMMON
import NoPageFound from "../../components/response-pages/NoPageFound";
import ProtectedRoutes from "./ProtectedRoutes";
import EmailVerification from "../../pages/authentication-pages/singup/verify-email/EmailVerification";
import EmailSent from "../../pages/authentication-pages/authentication-messages/EmailSent";
import CardManagement from "../../pages/portal-pages/cards/CardManagement";
import CheckoutHandle from "../../pages/portal-pages/checkout/CheckoutHandle";
import AddressHandle from "../../pages/portal-pages/address/AddressHandle";
import ConditionalRoutes from "./ConditionalRoutes";
import SubscriptionDetail from "../../pages/portal-pages/subscriptions/SubscriptionDetail";

export function AppRouter( props ) {


  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <LayoutWithNavbar
              login={ false }
              landing={ true }
              authentication={ false }
            />
          }
        >
          <Route index element={ <Navigate to="/home" /> } />
          <Route path="home" element={ <Home /> } />
          <Route path="about" element={ <About /> } />
          <Route path="contact" element={ <Contact /> } />
          <Route path="pricing" element={ <Pricing /> } />
        </Route>
        <Route element={ <ProtectedRoutes shouldbeloggedIn={ true } /> }>
          <Route
            path="/"
            element={
              <LayoutWithNavbar
                login={ true }
                landing={ false }
                authentication={ false }
              />
            }
          >
            <Route
              index
              element={
                <ConditionalRoutes>
                  <Sessions />
                </ConditionalRoutes>
              }
            />

            <Route
              path="/sessions"
              element={
                <ConditionalRoutes>
                  <Sessions />
                </ConditionalRoutes>
              }
            />
            <Route
              path="/sessions/new"
              element={
                <ConditionalRoutes>
                  <SessionsHandle />
                </ConditionalRoutes>
              }
            />
            <Route path="/checkout/:type/:slug" element={ <CheckoutHandle /> } />
            <Route path="/" element={ <Navigate replace to="/sessions" /> } />
            <Route
              path="/invoices"
              element={
                <ConditionalRoutes>
                  <Invoices />
                </ConditionalRoutes>
              }
            />
            <Route
              path="/invoices/detail/:id"
              element={
                <ConditionalRoutes>
                  <InvoicesDetail />
                </ConditionalRoutes>
              }
            />
            {/* <Route
              path="/background-categories"
              element={
                <ConditionalRoutes>
                  <ImagesCategories background />
                </ConditionalRoutes>
              }
            /> */}
            {/* <Route
              path="/board-categories"
              element={
                <ConditionalRoutes>
                  <ImagesCategories />
                </ConditionalRoutes>
              }
            /> */}
            <Route
              path="/background-images"
              element={
                <ConditionalRoutes>
                  <CategoryImages background />
                </ConditionalRoutes>
              }
            />
            <Route
              path="/board-images"
              element={
                <ConditionalRoutes>
                  <CategoryImages />
                </ConditionalRoutes>
              }
            />
            <Route path="/account" element={ <AccountProfile /> }>
              <Route index element={ <AccountInfo type="personal" /> } />
              <Route
                path="personal-info"
                element={ <AccountInfo type="personal" /> }
              />
              <Route path="change-password" element={ <AccountPassword /> } />
              <Route
                path="billing-address"
                element={
                  <AddressHandle />

                }
              />
              <Route
                path="card-management"
                element={
                  <CardManagement />

                }
              />
              <Route
                path="subscription-info"
                element={ <AccountInfo type="subscription" /> }
              />
            </Route>

            <Route path="/account" element={ <AccountProfile /> } />
            <Route path="/subscriptions" element={ <Subscriptions /> } />
            <Route
              path="/user-subscriptions"
              element={
                <ConditionalRoutes>
                  <SubscriptionDetail />
                </ConditionalRoutes>
              }
            />
            <Route path="*" element={ <NoPageFound /> } />
          </Route>
        </Route>
        <Route element={ <ProtectedRoutes shouldbeloggedIn={ false } /> }>
          <Route
            path="/"
            element={
              <LayoutWithNavbar
                login={ false }
                landing={ false }
                authentication={ true }
              />
            }
          >
            <Route
              path="/reset-password/:id"
              element={
                <ResetPassword />

              }
            />
            <Route
              path="/login"
              element={

                <Login />

              }
            />
            <Route
              path="/password-changed"
              element={

                <PasswordChanged />

              }
            />
            <Route
              path="/signup-form/:token"
              element={

                <SignUpForm />

              }
            />
            <Route
              path="/signup"
              element={

                <EmailVerification />

              }
            />
            <Route
              path="/email-sent"
              element={

                <EmailSent />

              }
            />
            <Route
              path="/reset"
              element={

                <ForgotPassword />

              }
            />
            <Route
              path="/link-sent"
              element={

                <LinkSent />

              }
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default AppRouter;
