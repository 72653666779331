import React, { createContext, useContext, useEffect, useState } from "react";
import { planUsage } from "../core/apis/subscriptions";
import { UserContext } from "./UserContext";

const SubscriptionContext = createContext( {
    subscriptionItem: null,
    updateSubscriptionItems: () => { },
} );

const SubscriptionContextProvider = ( props ) => {
    const { children } = props;
    const { session } = useContext( UserContext )
    const [ subscriptionItems, setSubscriptionItems ] = useState( null );

    const GetSubscription = () => {
        planUsage().then( ( response ) => {
            setSubscriptionItems( response?.data?.data );
        } );
    };

    const updateSubscriptionItems = () => {
        GetSubscription();
    };

    useEffect( () => {
        if ( session ) {
            GetSubscription();
        }
    }, [] );

    return (
        <SubscriptionContext.Provider
            value={ { subscriptionItems, updateSubscriptionItems } }
        >
            { children }
        </SubscriptionContext.Provider>
    );
};
export { SubscriptionContextProvider, SubscriptionContext };
