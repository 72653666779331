export const LandingPageItems = [
  {
    id: "home",
    text: "Home",
    route: "/home",
  },
  {
    id: "pricing",
    text: "Pricing",
    route: "/pricing",
  },
  {
    id: "about",
    text: "About Us",
    route: "/about",
  },
  {
    id: "contact",
    text: "Contact",
    route: "/contact",
  },
];
