import CecilioProfile from "../../../assets/images/cecilio.png";
//CSS
import "./About.scss";
export function About(props) {
  return (
    <div className="about-component">
      <div className="about-us-title">About Us</div>
      <div className="introduction-section">
        <div className="image">
          <img src={CecilioProfile} />
        </div>
        <div className="text">
          <div className="section">
            <div className="title">Who are we</div>
            <div className="body">
              {" "}
              We are a group of investors, IT experts and constellations
              professionals. All together we developed an online tool for the
              use of our founder and other professionals’ customers. As the
              results were amazing and we had excellent costumers’ feedback, we
              decided to share this sophisticated online tool with the community
              of coaches, facilitators, constellators, consultants and other
              professionals, so everybody can take advantage of all the features
              developed and with a very low price.
            </div>
          </div>

          <div className="section">
            <div className="title"> Founder</div>
            <div className="body">
              {" "}
              Cecilio Fernandez Regojo is a Mechanical Engineer with
              post-graduation in Business Administration, IST & UC. Master
              Trainer certified by INFOSYON. Investor, Entrepreneur, Consultant
              & Manager in different business areas. Top business experience in
              international context (almost 50 years). Scientific Director of
              Certifications in Universities of Europe & Latin America. Systemic
              & Business Consultant – international market reference. Lecturer
              and speaker at several International Conferences. Expert and
              International Trainer on Systemic Management and Organizational
              Constellations worldwide. Top CEO advisor.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
