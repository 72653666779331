//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { groupParamsByKey, objectCleaner } from "../../../core/functions/useDebounce";
import { UserContext } from "../../../context/UserContext";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
//COMPONENT
import TitleComponent from "../../../components/title-component/TitleComponent";
import TableComponent from "../../../components/table-component/TableComponent";
import TableUpsideSection from "../../../components/table-component/TableUpsideSection";
//COMPONENT
import NoticeConfirmation from "../../../components/dialog-component/NoticeConfirmation";
import { SceneURL } from "../../../core/apis/main";
import TablePaginationSection from "../../../components/table-component/TablePaginationSection";
import { dataPerPage } from "../../../core/variables/CoreVariables";
import RowComponent from "../../../components/table-component/RowComponent";
import { TableCell } from "@mui/material";
import { AlertContext } from "../../../context/AlertContext";
import { deleteSession, getAllSessions, startSession } from "../../../core/apis/session";
import { DisplayDateTime } from "../../../components/display-components/DisplayComponent";
import clipboardCopy from 'clipboard-copy';
import isDateExpired from "../../../core/functions/checkExpiryDate";



export function Sessions( props ) {
  //VARIABLES
  let navigate = useNavigate();
  const { user, updateUser } = useContext( UserContext );
  const { setAlert } = useContext( AlertContext );
  const { subscriptionItems, updateSubscriptionItems } = useContext( SubscriptionContext )
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { page, per_page, name, start_date, end_date } = groupParamsByKey( searchParams ) || {};
  const [ totalPage, setTotalPage ] = useState( 0 );
  const [ total, setTotal ] = useState( 0 );
  const [ data, setData ] = useState( [] );
  const [ loading, setLoading ] = useState( false );
  const [ sessionInfo, setSessionInfo ] = useState( null );
  const [ openConfirmation, setOpenConfirmation ] = useState( {
    open: false,
    error: false,
  } );



  const tableHeaders = [
    {
      name: "Session Name",
    },
    {
      name: "Date",
    },

  ];
  //Functions
  const handleAddSession = () => {
    let userSub = user?.latest_subscription

    if (
      subscriptionItems?.[ 'created-sessions' ] &&
      (
        (
          userSub?.package_ends_at && // Check if the user has a package_ends_at
          !isDateExpired( userSub?.package_ends_at ) // Check if the package_ends_at is not expired
        ) ||
        (
          !userSub?.package_ends_at && // Check if there is no package_ends_at
          userSub?.trial_ends_at && // Check if there is a trial_ends_at
          !isDateExpired( userSub?.trial_ends_at ) // Check if the trial_ends_at is not expired
        )
      )
    ) {
      navigate( "/sessions/new" );
    } else {
      setOpenConfirmation( {
        open: true,
        error: true
      } )
    }
  };

  const handleDeleteResponse = ( type, value ) => {
    if ( type === "yes" ) {
      deleteSession( value.slug ).then( ( response ) => {
        if ( response?.data?.success ) {
          //!!!! to check later if we can remove this !!!
          updateUser();
          updateSubscriptionItems();
          if ( searchParams.get( 'page' ) == 1 ) {
            GetData();
          } else {
            setSearchParams( objectCleaner( { page: 1, per_page: dataPerPage } ) );
          }
        }
        setAlert( {
          text: response.data ? response.data.message : response.message,
          error: response?.data?.success ? false : true,
        } );
      } );
      setOpenConfirmation( { open: false, error: false } );
    } else {
      setOpenConfirmation( { open: false, error: false } );
    }
  };

  const GetData = () => {
    setLoading( true );

    getAllSessions( {
      per_page: searchParams.get( 'per_page' ) || dataPerPage,
      page: +searchParams.get( 'page' ) || 1,
      ...searchParams.get( 'start_date' ) && { start_date: searchParams.get( 'start_date' ) },
      ...searchParams.get( 'end_date' ) && { end_date: searchParams.get( 'end_date' ) || "" },
      name: searchParams.get( 'name' ) || "",
      is_deleted: false,
    } ).then( ( response ) => {
      if ( response?.data?.success ) {
        setTotalPage( response.data.data?.sessions?.last_page );
        setData(
          response.data.data.sessions ? response.data.data.sessions?.data : []
        );
        setTotal( response.data.data?.sessions?.total );
      } else {
        setAlert( {
          text: response.data ? response.data.message : response.message,
          error: true,
        } );
      }
      setLoading( false );
    } );
  };

  const handleDelete = ( value ) => {
    setSessionInfo( value );
    setOpenConfirmation( { open: true, error: false } );
  };

  const handlePlay = ( value ) => {
    startSession( value?.slug ).then( ( res ) => {
      if ( res?.data?.success ) {
        GetData();
        window.open( SceneURL + value?.slug + "/" + value?.user_id, "_blank" );
      }
      navigate( "/sessions" );
    } )

  };

  const handleCopy = ( value ) => {
    clipboardCopy( SceneURL + value.slug ); //without portal to let it be a user link not admin
    setAlert( {
      text: "Link Copied",
      error: false,
    } );
  };


  useEffect( () => {
    GetData();
  }, [ page, per_page, name, start_date, end_date ] );



  return (
    <>
      <TitleComponent title={ `Sessions` } classNames="page-title main-title" />
      <TitleComponent
        title={ `Total Sessions : ${ total ? total : 0 }` }
        classNames="page-title mini-title"
      />

      <TableUpsideSection
        filterable
        filterByDateRange
        placeholder={ "Search by session name" }
        addButtonTitle={ "New session" }
        addButton={ handleAddSession }
        search
        type="sessions"
      />
      <div className="margin-up-20">
        <TableComponent
          tableHeaders={ tableHeaders }
          loading={ loading }
          tableData={ data }
          actions
          sorted
        >
          { data?.map( ( element ) => (
            <RowComponent
              handlePlay={ () => handlePlay( element ) }
              handleCopy={ () => handleCopy( element ) }
              handleDelete={ () => handleDelete( element ) }
              row={ element }
              key={ element?.id }
            >
              <TableCell className='long-tablecell-description'>{ element.name }</TableCell>
              <TableCell>
                <DisplayDateTime data={ element.created_at } date />
              </TableCell>

            </RowComponent>
          ) ) }
        </TableComponent>
      </div>
      <TablePaginationSection
        total={ total }
        data={ data }
        totalPage={ totalPage }

      />

      { openConfirmation?.open && (
        <NoticeConfirmation
          message={ openConfirmation?.error ? 'To access this feature, kindly review your subscription status' : 'Are you sure you want to delete?' }
          actions={ !openConfirmation?.error }
          handleResponse={ handleDeleteResponse }
          data={ sessionInfo }
        />
      ) }
    </>
  );
}

export default Sessions;
