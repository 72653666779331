//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import { AlertContext } from "../../../context/AlertContext";
import { SubscriptionContext } from "../../../context/SubscriptionContext";
import { useNavigate } from "react-router-dom";
//COMPONENT
import TitleComponent from "../../../components/title-component/TitleComponent";
import {
  Card,
  CardContent,
  LinearProgress,
  Skeleton,
  Switch,
} from "@mui/material";
import {
  autoRenewPlan,
  getSelfSubscription,
} from "../../../core/apis/subscriptions";
import NoticeConfirmation from "../../../components/dialog-component/NoticeConfirmation";
import { AutoRenew } from "./SubscriptionMessages";
import { DisplayDateTime, convertTimeFromUTC } from "../../../components/display-components/DisplayComponent";
import { PrimaryButton } from "../../../components/buttons/Buttons";

//CSS

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "./Subscriptions.scss";
import { CouponSVG, PackageSVG, TotalSVG } from "../../../assets/svg/SVG";
import isDateExpired from "../../../core/functions/checkExpiryDate";


export function SubscriptionDetail( props ) {
  let navigate = useNavigate();
  const { updateSubscriptionItems } = useContext( SubscriptionContext )
  let { updateUser, user } = useContext( UserContext );
  let { setAlert } = useContext( AlertContext );
  const [ loading, setLoading ] = useState( true );
  const [ loadingButton, setLoadingButton ] = useState( false );
  const [ openRenew, setOpenRenew ] = useState( false );
  const [ subscription, setSubscription ] = useState( null );
  const [ renewId, setRenewId ] = useState( null );
  const [ openNotice, setOpenNotice ] = useState( false );
  const [ openNoticeMessage, setOpenNoticeMessage ] = useState( "" );
  const [ userUsage, setUserUsage ] = useState( {
    sessions: { used: 0, allowed: 0 },
    images: { used: 0, allowed: 0 },
  } );

  const GetData = () => {
    setLoading( true );
    try {
      getSelfSubscription().then( ( res ) => {
        if ( res?.data?.success ) {
          let sessions_allowed =
            res.data.data?.latest_subscription.plan_subscription_items?.find(
              ( item ) => item?.item_slug === "created-sessions"
            );
          let images_allowed =
            res.data.data?.latest_subscription.plan_subscription_items?.find(
              ( item ) => item?.item_slug === "uploaded-images"
            );
          setSubscription( res.data.data );

          setUserUsage( {
            sessions: {
              used: res.data.data?.latest_subscription.created_sessions_count,
              allowed:
                sessions_allowed?.is_unlimited === 1
                  ? -1
                  : sessions_allowed?.value,
            },
            images: {
              used: res.data.data?.latest_subscription.created_images_count,
              allowed:
                images_allowed?.is_unlimited === 1 ? -1 : images_allowed?.value,
            },
          } );
        }
        setLoading( false );
      } );
    } catch ( e ) {
      setLoading( false );
    }
  };
  useEffect( () => {
    GetData();
  }, [ user?.latest_subscription ] );

  const SetAutoRenew = () => {
    try {
      setLoadingButton( true );
      autoRenewPlan( {
        id: renewId,
        auto_renew: subscription?.latest_subscription?.is_auto_renew
          ? false
          : true,
      } ).then( ( res ) => {
        if ( res?.data?.success ) {
          updateUser();
          updateSubscriptionItems();
          CloseNewModal();
        }
        setAlert( {
          text: res.data ? res.data.message : res.message,
          error: !res?.data?.success,
        } );
        setLoadingButton( false );
      } );
    } catch ( error ) {
      setLoadingButton( false );
    }
  };
  const OpenRenew = ( id ) => {
    setRenewId( id );
    setOpenRenew( true );
  };

  const CloseNewModal = () => {
    setOpenRenew( false );
    setRenewId( null );
  };


  return (
    <div className="subscription-form-Details">
      <TitleComponent
        title="Subscription Details"
        classNames="form-title main-title"
      />
      { loading ? (
        <Skeleton />
      ) : (
        <>
          <Card className="portal-form-container">
            <CardContent className="portal-form-content">
              <TitleComponent
                title={ "Current Plan Detail" }
                classNames="page-title sub-title no-margin"
                handleEndAction={
                  <div>{ subscription?.latest_subscription?.plan_name }</div>
                }
              />
              { subscription?.latest_subscription && (
                <div className="subscription-detail-content">
                  <div className="section left-section">
                    <div className="section-item">
                      <div className="label">Description</div>
                      <div className="value">
                        { subscription?.latest_subscription?.plan_description
                          ? subscription?.latest_subscription?.plan_description
                          : "-" }
                      </div>
                    </div>
                    { subscription?.latest_subscription?.trial_starts_at && (
                      <>
                        <div className="section-item">
                          <div className="label">Trial Period</div>
                          <div className="value">
                            { subscription?.latest_subscription?.plan_slug
                              ?.trial_duration +
                              " " +
                              subscription?.latest_subscription?.plan_slug
                                ?.trial_duration_type }
                          </div>
                        </div>
                        <div className="section-item">
                          <div className="label">Trial Start Date</div>
                          <div className="value">
                            <DisplayDateTime
                              data={
                                subscription?.latest_subscription
                                  ?.trial_starts_at
                              }

                            />
                          </div>
                        </div>
                        <div className="section-item">
                          <div className="label">Trial End Date</div>
                          <div className="value">
                            <DisplayDateTime
                              data={
                                subscription?.latest_subscription?.trial_ends_at
                              }

                            />
                          </div>
                        </div>
                      </>
                    ) }

                    { subscription?.latest_subscription?.package_starts_at && (
                      <>
                        <div className="section-item">
                          <div className="label">Start Date</div>
                          <div className="value">
                            <DisplayDateTime
                              data={
                                subscription?.latest_subscription
                                  ?.package_starts_at
                              }

                            />
                          </div>
                        </div>
                        <div className="section-item">
                          <div className="label">Expiry Date</div>
                          <div className="value">
                            <DisplayDateTime
                              data={
                                subscription?.latest_subscription
                                  ?.package_ends_at
                              }

                            />
                          </div>
                        </div>
                      </>
                    ) }
                  </div>
                  <div className="section right-section">
                    <div className="section-item ">
                      <div className="label">Images Uploaded</div>
                      <div className="value">{ `${ userUsage?.images?.used
                        } Out of  
                        ${ userUsage?.images?.allowed === -1
                          ? "Unlimited"
                          : userUsage?.images?.allowed
                        }` }</div>
                    </div>

                    {/* if not unlimited */ }
                    { userUsage?.images?.allowed !== -1 && (
                      <div className="section-item">
                        <Card className="section-progress">
                          <CardContent>
                            <LinearProgress
                              className="progress"
                              variant="determinate"
                              value={
                                ( userUsage?.images?.used /
                                  userUsage?.images?.allowed ) *
                                100
                              }
                            />
                          </CardContent>
                        </Card>
                      </div>
                    ) }

                    <div className="section-item">
                      <div className="label">Sessions Created</div>
                      <div className="value">
                        { " " }
                        { `${ userUsage?.sessions?.used } Out of  
                        ${ userUsage?.sessions?.allowed === -1
                            ? "Unlimited"
                            : userUsage?.sessions?.allowed
                          }` }
                      </div>
                    </div>
                    { userUsage?.sessions?.allowed !== -1 && (
                      <div className="section-item">
                        <Card className="section-progress">
                          <CardContent>
                            <LinearProgress
                              className="progress"
                              variant="determinate"
                              value={
                                ( userUsage?.sessions?.used /
                                  userUsage?.sessions?.allowed ) *
                                100
                              }
                            />
                          </CardContent>
                        </Card>
                      </div>
                    ) }
                    <div className="section-item sub-payment-info">
                      <div className="label">
                        <PackageSVG /> Package Name
                      </div>
                      <div className="value">
                        { user?.is_active === 1
                          ? subscription?.latest_subscription?.package_ends_at
                            ? subscription?.latest_subscription?.plan_price
                              ?.price +
                            subscription?.latest_subscription?.plan_currency +
                            "/" +
                            subscription?.latest_subscription?.plan_price
                              ?.package_duration_type
                            : "Free Trial"
                          : "Expired" }
                      </div>
                    </div>
                    <div className="section-item">
                      <div className="label">
                        <CouponSVG /> Coupon
                      </div>
                      <div className="value">
                        { subscription?.latest_subscription?.coupon
                          ? subscription?.latest_subscription?.coupon?.code
                          : "No Coupon Used" }
                      </div>
                    </div>
                    <div className="section-item sub-payment-info">
                      <div className="label">
                        <TotalSVG /> Total
                      </div>
                      <div className="value">
                        { subscription?.latest_subscription?.itemable?.invoice
                          ?.total || 0 }
                      </div>
                    </div>
                  </div>
                </div>
              ) }
              <div
                className={ `detail-footer ${ !subscription?.latest_subscription.package_starts_at
                  ? "renew-enabled-footer"
                  : ""
                  }` }
              >
                { subscription?.latest_subscription.package_starts_at &&
                  !subscription?.next_plan && (
                    <div className="renew">
                      <div className="auto">Auto Renew</div>
                      <Switch
                        checked={
                          subscription?.latest_subscription.is_auto_renew
                            ? true
                            : false
                        }
                        onChange={ ( e ) => {
                          e.preventDefault();
                          //if subscription has expired he shouldn't be able to auto renew 
                          const isSubscriptionExpired = isDateExpired( subscription?.latest_subscription.package_ends_at );
                          if ( isSubscriptionExpired ) {
                            setOpenNoticeMessage( 'Your Subscription has expired, Please renew it manually' );
                            setOpenNotice( true )
                          } else {
                            OpenRenew( subscription?.latest_subscription.id );
                          }

                        } }
                        name="renew"
                      />
                    </div>
                  ) }
                { !subscription?.next_plan && (
                  <div
                    className={ `action-buttons ${ subscription?.latest_subscription.is_auto_renew ||
                      !subscription?.latest_subscription.package_starts_at
                      ? "renew-enabled"
                      : ""
                      }` }
                  >
                    { subscription?.latest_subscription.package_starts_at && (
                      <PrimaryButton
                        type="submit"
                        variant={ "outlined" }
                        onClick={ () => {
                          if ( subscription?.latest_subscription.is_auto_renew ) {
                            setOpenNoticeMessage(
                              "Unable to Proceed , Please disable Auto Renew Feature"
                            );
                            setOpenNotice( true )
                          } else {
                            navigate(
                              `/checkout/renew/${ subscription?.latest_subscription.plan_slug?.slug }`
                            );
                          }
                        } }
                        text={ "Renew Plan" }
                        className="save-button"
                      />
                    ) }

                    {/* if the subscription is running and is the last subscirption then no need for this action button */ }

                    { subscription.latest_subscription?.is_latest_subscription && subscription?.latest_subscription?.package_starts_at ? "" : <PrimaryButton
                      type="submit"
                      onClick={ () => {
                        if ( subscription?.latest_subscription.is_auto_renew ) {
                          setOpenNoticeMessage(
                            "Unable to Proceed , Please disable Auto Renew Feature"
                          );
                          setOpenNotice( true );
                        } else {
                          navigate( "/subscriptions" );
                        }
                      } }
                      //if subscription is in trial then he can either subscribe or upgrade
                      //if the subscription isn't the latest subscription(in order) then 
                      text={
                        `${ !subscription?.latest_subscription?.package_starts_at ? "Subscribe" : ""
                        }${ !subscription?.latest_subscription?.package_starts_at && !subscription.latest_subscription?.is_latest_subscription ? " or" : ""
                        }${ subscription.latest_subscription?.is_latest_subscription ? "" : " Upgrade"
                        }`
                      }

                      className="save-button"
                    /> }
                  </div>
                ) }
              </div>
              { user?.latest_subscription?.is_auto_renew === 1 && ( <div className="autorenew-disclaimer">
                <div>
                  Disclaimer
                </div>
                <div>{ `To cancel the subscription on ${ convertTimeFromUTC( "date", subscription?.latest_subscription?.package_ends_at ) }, kindly turn off the auto-renewal option` }</div>

              </div> ) }
            </CardContent>
          </Card>
          { subscription?.next_plan && (
            <Card className="portal-form-container">
              <CardContent className="portal-form-content">
                <TitleComponent
                  title={ "Next Plan Detail" }
                  classNames="page-title sub-title"
                  handleEndAction={ <div>{ user?.next_plan?.plan_name }</div> }
                />
                <div className="subscription-detail-content">
                  <div className="section left-section">
                    <div className="section-item">
                      <div className="label">Description</div>
                      <div className="value">
                        { user?.next_plan?.plan_description
                          ? user?.next_plan?.plan_description
                          : "-" }
                      </div>
                    </div>
                    { user?.next_plan?.trial_starts_at && (
                      <>
                        <div className="section-item">
                          <div className="label">Trial Start Date</div>
                          <div className="value">
                            <DisplayDateTime
                              data={ user?.next_plan?.trial_starts_at }

                            />
                          </div>
                        </div>
                        <div className="section-item">
                          <div className="label">Trial End Date</div>
                          <div className="value">
                            <DisplayDateTime
                              data={ user?.next_plan?.trial_ends_at }

                            />
                          </div>
                        </div>
                      </>
                    ) }

                    { user?.next_plan?.package_starts_at && (
                      <>
                        <div className="section-item">
                          <div className="label">Start Date</div>
                          <div className="value">
                            <DisplayDateTime
                              data={ user?.next_plan?.package_starts_at }

                            />
                          </div>
                        </div>
                        <div className="section-item">
                          <div className="label">Expiry Date</div>
                          <div className="value">
                            <DisplayDateTime
                              data={ user?.next_plan?.package_ends_at }

                            />
                          </div>
                        </div>

                        <div className="section-item">
                          <div className="label">Auto Renew</div>
                          <div className="value">
                            { user?.next_plan?.is_auto_renew === 1
                              ? "Enabled"
                              : "Disabled" }
                          </div>
                        </div>
                      </>
                    ) }
                  </div>
                  <div className="section right-section">
                    <div className="section-item">
                      <div className="label">Images' Limit</div>
                      <div className="value">
                        { user?.next_plan?.plan_subscription_items?.find(
                          ( i ) => i.item_slug == "uploaded-images"
                        ).is_unlimited === 1
                          ? "Unlimited"
                          : user?.next_plan?.plan_subscription_items?.find(
                            ( i ) => i.item_slug == "uploaded-images"
                          ).value }
                      </div>
                    </div>
                    <div className="section-item">
                      <div className="label">Sessions' Limit</div>
                      <div className="value">
                        { user?.next_plan?.plan_subscription_items?.find(
                          ( i ) => i.item_slug == "created-sessions"
                        ).is_unlimited === 1
                          ? "Unlimited"
                          : user?.next_plan?.plan_subscription_items?.find(
                            ( i ) => i.item_slug == "created-sessions"
                          ).value }
                      </div>
                    </div>
                    <div className="section-item sub-payment-info">
                      <div className="label">
                        <PackageSVG /> Package Name
                      </div>
                      <div className="value">
                        { subscription?.next_plan?.package_ends_at
                          ? subscription?.next_plan?.plan_price?.price +
                          subscription?.next_plan?.plan_currency +
                          "/" +
                          subscription?.next_plan?.plan_price
                            .package_duration_type
                          : "Free Trial" }
                      </div>
                    </div>
                    <div className="section-item">
                      <div className="label">
                        <CouponSVG /> Coupon
                      </div>
                      <div className="value">
                        { user?.next_plan?.coupon
                          ? user?.next_plan?.coupon?.code
                          : "No Coupon Used" }
                      </div>
                    </div>
                    <div className="section-item sub-payment-info">
                      <div className="label">
                        <TotalSVG /> Total
                      </div>
                      <div className="value">
                        { user?.next_plan?.itemable?.invoice?.total || 0 }
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          ) }

          { openRenew && (
            <AutoRenew
              closeModal={ CloseNewModal }
              proceed={ SetAutoRenew }
              user={ user }
              subscription={ subscription }
              loading={ loadingButton }
            />
          ) }
        </>
      ) }
      { openNotice && (
        <NoticeConfirmation
          message={ openNoticeMessage }
          actions={ false }
          handleResponse={ ( type, value ) => setOpenNotice( false ) }
        />
      ) }
    </div>
  );
}

export default SubscriptionDetail;
