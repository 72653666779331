import {
  FacebookIcon,
  FacebookIcon1,
  InstaInIcon,
  InstaInIcon1,
  LinkedInIcon,
  LinkedInIcon1,
  TwitterIcon,
  TwitterIcon1,
} from "../assets/svg/SVG";
import { FooterTooltip } from "../assets/theme/SpecialComponent";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/Ceci_tool_white.png";

export function LandingFooter(props) {
  let navigate = useNavigate();
  return (
    <>
      <div className="landing-footer-container">
        <div className="footer-element">
          <div className="title cursor" onClick={() => navigate("/about")}>
            About
          </div>
          <div>
            <img src={logo} alt="LOGO" onClick={() => navigate("/")} />
          </div>
        </div>
        <div className="footer-element">
          <div className="support-footer-element">
            <div className="title support-element">Support</div>

            <div className="body support-body">
              <a href="mailto:info@cecitool.online">info@cecitool.online</a>
            </div>
          </div>
        </div>

        <div className="footer-element social-media-element">
          <div className="title">Connect With Us</div>
          <FooterTooltip title="Coming Soon">
            <div className="social-media cursor-pointer">
              <FacebookIcon /> <TwitterIcon />
              <LinkedInIcon />
              <InstaInIcon />
            </div>
          </FooterTooltip>
        </div>
      </div>
      <div className="divider-section">
        <Divider style={{ backgroundColor: "white" }} />
      </div>
      <div className="landing-footer-container-responsive">
        <div className="footer-element">
          <img src={logo} alt="LOGO" onClick={() => navigate("/")} />
        </div>
        <div className="footer-element">
          <div className="support-footer-element">
            <div className="title" onClick={() => navigate("/about")}>
              About
            </div>
            <div class="footer-divider"></div>
            <div className="body support-body">
              <a href="mailto:info@cecitool.online">info@cecitool.online</a>
            </div>
          </div>
        </div>
      </div>
      <div className="divider-section-responsive">
        <Divider style={{ backgroundColor: "white" }} />
      </div>
      <FooterTooltip title="Coming Soon">
        <div className="icons">
          <FacebookIcon1 /> <TwitterIcon1 />
          <LinkedInIcon1 />
          <InstaInIcon1 />
        </div>
      </FooterTooltip>
      <div className="footer-footer-container">
        <div>2023 Ceci tool. All Rights Reserved</div>
      </div>
    </>
  );
}
export default LandingFooter;
