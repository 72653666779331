//ALL API ARE FROM HERE INITIALIZE TO CALL TOKEN ONE TIME
import axios from "axios";
import { Cookies } from "react-cookie";
export const imagesURL = process.env.REACT_APP_IMAGE_URL;
export const APIURL = process.env.REACT_APP_API + "/v1";
export const SceneURL = process.env.REACT_APP_SCENE;

export const StripePublishKey = process.env.REACT_APP_STRIPE_KEY;

//new
export let api = axios.create( {
  withCredentials: true,
  baseURL: process.env.REACT_APP_API + "/v1/",
  headers: {
    "X-Authorization":
      "Ioxjw7iwq9UslFrrwtMlwK5CKVwrSMHzyA7UTl6SFz6t8pBDsZVxhK0nIOBfkU5G",
  },
} );

// Set the AUTH token for any request //new
api.interceptors.response.use(
  ( response ) => response,
  async ( error ) => {
    if ( error.response.status === 401 ) {
      await axios
        .get( "refresh-token", {
          withCredentials: true,
          baseURL: process.env.REACT_APP_API + "/v1/",
          headers: {
            "X-Authorization":
              "Ioxjw7iwq9UslFrrwtMlwK5CKVwrSMHzyA7UTl6SFz6t8pBDsZVxhK0nIOBfkU5G",
          },
        } )
        .catch( () => {
          const cookies = new Cookies();
          cookies.remove( "session" );
          cookies.remove( "isSubscribed" );
          window.location.replace( "/login" );
        } );
      return axios( error.config );
    } else {
      return Promise.reject( error );
    }
  }
);
