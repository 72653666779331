import { api } from "./main";
import { APIURL } from "./main";

export const GetSelfUser = () => {
  return api
    .get(APIURL + `/self-user`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const UpdateUser = (body) => {
  return api
    .put(APIURL + `/update`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const UserLogout = (payload) => {
  return api
    .get(APIURL + `/logout`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const ExportSubscribersData = (payload) => {
  return api
    .get(APIURL + `/admin/export-user`, {
      responseType: "blob",
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const RegisterUser = (payload) => {
  return api
    .post(APIURL + "/sign-up", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const checkEmailValidity = (payload) => {
  return api
    .get(APIURL + "/check-email", { params: { ...payload } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const ForgotUserPassword = (payload) => {
  return api
    .post(APIURL + "/auth/forgot-password", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
export const ResetUserPassword = (payload) => {
  return api
    .post(APIURL + "/reset-password-form", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const ChangeSelfUserPassword = (payload) => {
  return api
    .put(APIURL + "/admin/change-password", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const emailVerification = (payload) => {
  return api
    .post(APIURL + "/verify-email", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
