//UTILITIES
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//COMPONENT
import { Card, CardContent } from "@mui/material";

import "./AuthenticationMessages.scss"

export function PasswordChanged( props ) {
  let navigate = useNavigate();

  useEffect( () => {
    setTimeout( function () {
      navigate( "/login" );
    }, 5000 );
  }, [] );

  return (
    <div className="page-container">
      <Card className="authmessages-container">
        <CardContent className="content">
          <div className="title">Password Changed</div>
          <div className="text">
            You can now sign in with your new password!
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
export default PasswordChanged;
