import { api } from "./main";

export const getAllImages = (payload) => {
  let page = payload?.page ? `?page=${payload.page}` : "";
  return api
    .get(`get-all-images${page}`, {
      params: { ...payload },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const uploadImage = async (payload) => {
  return await api
    .post(`upload-image`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const deleteImage = async (payload) => {
  return await api
    .delete(`delete-image/${payload}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
