//UTILITIES
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { imagesURL } from "../../core/apis/main";
import PropTypes from "prop-types";
//COMPONENT
import "react-phone-number-input/style.css";
import DatePicker from "react-datepicker";
import { FileUploader } from "react-drag-drop-files";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Cropper from "react-cropper";
import { CardElement } from "@stripe/react-stripe-js";
import HintTooltip from "../hint-messages/HintTooltip";
import "cropperjs/dist/cropper.css";
import { DateRange } from "react-date-range";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import PhoneInput from "react-phone-number-input";
import palette from "../../assets/theme/color.scss";
import {
  IconButton,
  Slider,
  TextField,
  InputAdornment,
  InputLabel,
  FormGroup,
  FormControl,
  FormHelperText,
  Switch,
  Stack,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Typography,
  Radio,
  Tooltip,
  CardMedia,
  LinearProgress,
} from "@mui/material";
import {
  BlockEyeSVG,
  CalendarSVG,
  DeleteSVG,
  EyeSVG,
  MoreInfoSVG,
  SearchIcon,
  UploadIcon,
} from "../../assets/svg/SVG";
import { IconsButton, LinkButton } from "../buttons/Buttons";
//CSS
import "react-datepicker/dist/react-datepicker.css";
import "./FormComponent.scss";


const RenderFileInfo = ( sizeMax, fileType, aspectRatio ) => {
  return (
    <div className="file-requirements-tooltip">
      <div>Requirements :</div>
      <li>{ fileType?.toString() }</li>
      <li>Up to { sizeMax } MB </li>
      { aspectRatio && <li>Ratio { aspectRatio }</li> }
    </div>
  );
};

const getMarksArray = ( min, max, step ) => {
  let array = [];
  for ( let i = min; i <= max; i = i + step ) {
    array.push( { value: i, label: i } );
  }

  return array;
};
export const FormInput = ( props ) => {
  const {
    placeholder,
    value,
    onKeyDown,
    onBlur,
    label,
    required,
    error,
    type,
    hint,
    onChange,
    helperText,
    isAuth,
    disabled,
  } = props;

  const handleOnChange = ( e ) => {
    onChange( e.target.value );
  };

  return (
    <div className={ `form-input-wrapper ${ isAuth ? "transparent-bg" : "" }` }>
      { label && (
        <InputLabel style={ { ...props.style, } } >
          { label }
          <span className="required-start">{ required ? "*" : "" }</span>
          { hint && ( <HintTooltip text={ hint } /> ) }
        </InputLabel>
      ) }
      <TextField
        className={ `${ props.className }` }
        fullWidth
        type={ type }
        name={ props.name }
        size="small"
        onBlur={ onBlur }
        autoComplete="off"
        value={ value }
        error={ error }
        placeholder={ placeholder }
        variant="outlined"
        onChange={ handleOnChange }
        helperText={ helperText }
        disabled={ disabled ? disabled : false }
        inputProps={ {
          style: {
            backgroundColor: "transparent",
          },
          autocomplete: "new-password",
          form: {
            autocomplete: "off",
          },
        } }
        onKeyDown={ onKeyDown }
      />
    </div>
  );
};

FormInput.defaultProps = {
  value: "",
};
FormInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export const FormPassword = ( props ) => {
  const {
    name,
    label,
    placeholder,
    value,
    onChange,
    required,
    isAuth,
    helperText,
  } = props;

  const [ showPassword, setShowPassword ] = useState( false );

  const handleClickShowPassword = () => {
    setShowPassword( !showPassword );
  };

  const handleOnChange = ( e ) => {
    onChange( e.target.value );
  };

  return (
    <div className={ `form-input-wrapper ${ isAuth ? "transparent-bg" : "" }` }>
      { label && (
        <InputLabel>
          { label }
          <span className="required-start">{ required ? "*" : "" }</span>
        </InputLabel>
      ) }
      <TextField
        fullWidth
        name={ name }
        size="small"
        placeholder={ placeholder }
        variant="outlined"
        type={ showPassword ? "text" : "password" }
        onChange={ handleOnChange }
        value={ value }
        helperText={ helperText }
        InputProps={ {
          endAdornment: (
            <InputAdornment
              position={ "end" }
              sx={ {
                backgroundColor: isAuth
                  ? "rgba(255, 255, 255, 0.2) !important"
                  : palette.whiteColor,
              } }
            >
              <IconButton onClick={ handleClickShowPassword }>
                { showPassword ? (
                  <BlockEyeSVG className="icon" />
                ) : (
                  <EyeSVG className="icon" />
                ) }
              </IconButton>
            </InputAdornment>
          ),
        } }
      />
    </div>
  );
};

export const FormSwitch = ( props ) => {
  const { value, labelI, noFormComponent, onChange, labelII, disabled, label } =
    props;
  return (
    <div className={ `${ noFormComponent ? "" : "form-input-wrapper" }` }>
      { label && <InputLabel>{ label }</InputLabel> }
      <FormGroup aria-label="position" row>
        <Stack direction="row" spacing={ 1 } alignItems="center">
          { labelI && (
            <Typography className="switch-labels">{ labelI }</Typography>
          ) }
          <Switch
            disabled={ disabled }
            checked={ value }
            color="primary"
            value={ value }
            onChange={ ( e ) => onChange( e.target.checked ) }
          />
          { labelII && (
            <Typography className="switch-labels">{ labelII }</Typography>
          ) }
        </Stack>
      </FormGroup>
    </div>
  );
};
FormInput.defaultProps = {
  noFormComponent: false,
};
FormInput.propTypes = {
  noFormComponent: PropTypes.bool,
};
export const FormTextArea = ( props ) => {
  const {
    rows,
    label,
    value,
    required,
    onChange,
    helperText,
    placeholder,
    disabled,
  } = props;

  const handleOnChange = ( e ) => {
    onChange( e.target.value );
  };

  return (
    <div className="form-input-wrapper">
      { label && (
        <InputLabel>
          { " " }
          { label }
          <span className="required-start">{ required ? "*" : "" }</span>
        </InputLabel>
      ) }
      <TextField
        fullWidth
        multiline
        rows={ rows ? rows : 4 }
        size="small"
        value={ value }
        placeholder={ placeholder }
        variant="outlined"
        onChange={ handleOnChange }
        helperText={ helperText }
        disabled={ disabled ? disabled : false }
      />
    </div>
  );
};
export const FormPhoneInput = ( props ) => {
  const { required, onChange, label, value, helperText, className, isAuth } =
    props;

  const handleOnChange = ( value ) => {
    onChange( value );
  };

  return (
    <div
      className={ `form-input-wrapper ${ isAuth ? "transparent-bg" : "" } ${ className ? className : ""
        }` }
    >
      <InputLabel>
        { " " }
        { label }
        <span className="required-start">{ required ? " * " : "" }</span>
      </InputLabel>
      <PhoneInput
        international
        className="phone-input-style"
        defaultCountry="ES"
        value={ value }
        onChange={ ( value, country ) => handleOnChange( value, country ) }
      />

      { helperText !== "" && <FormHelperText>{ helperText }</FormHelperText> }
    </div>
  );
};
export const FormDropdownList = ( props ) => {
  const {
    label,
    value,

    placeholder,
    data,
    isAuth,
    disabled,
    formComponent,
    noOptionsMessage,
    loading,
    onChange,
    required,
    helperText,
  } = props;

  const handleOnChange = ( selected ) => {
    onChange( selected ? selected : null );
  };

  return (
    <div className={ `form-input-wrapper ${ isAuth ? "transparent-bg" : "" }` }>
      { label && (
        <InputLabel>
          { label }
          <span className="required-start">
            { required && !formComponent ? "*" : "" }
          </span>
        </InputLabel>
      ) }

      <Autocomplete
        size="small"
        disabled={ disabled ? disabled : false }
        fullWidth
        disableClearable={ required ? true : false }
        ListboxProps={ { style: { maxHeight: 200, overflow: "auto" } } }
        getOptionLabel={ ( option ) => option?.name }
        options={ data }
        value={ value }
        isOptionEqualToValue={ ( option, value ) => option.id === value?.id }
        loadingText={ "Loading..." }
        noOptionsText={ noOptionsMessage ? noOptionsMessage : "No Options" }
        loading={ loading }
        onChange={ ( event, selected ) => {
          handleOnChange( selected );
        } }
        renderInput={ ( params ) => (
          <TextField
            { ...params }
            placeholder={ placeholder }
            helperText={ helperText }
            InputProps={ {
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  { loading ? (
                    <CircularProgress color="inherit" size={ 20 } />
                  ) : null }
                  { params.InputProps.endAdornment }
                </React.Fragment>
              ),
            } }
          />
        ) }
      />
    </div>
  );
};
export const FormCheckBox = ( props ) => {
  const { onChange, checked, label, value, required, helperText } = props;

  const handleChange = ( value ) => {
    onChange( value );
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={ checked }
            value={ value }
            onChange={ ( e, value ) => handleChange( value ) }
            inputProps={ { "aria-label": "controlled" } }
          />
        }
        label={
          <span>
            { label }
            <span className="required-start">{ required ? "*" : "" }</span>
          </span>
        }
      />
      { helperText !== "" && (
        <FormControl>
          <FormHelperText>{ helperText }</FormHelperText>
        </FormControl>
      ) }
    </FormGroup>
  );
};
export const FormSearchBar = ( props ) => {
  const { placeholder, variant, value, onChange } = props;

  const handleInputChange = ( e ) => {
    onChange( e.target.value );
  };

  return (
    <TextField
      type="text"
      fullWidth
      size="small"
      placeholder={ placeholder }
      variant={ variant }
      onChange={ ( e ) => handleInputChange( e ) }
      value={ value }
      InputProps={ {
        startAdornment: (
          <InputAdornment className="input-adorment" position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      } }
    />
  );
};
export const FormNumberInput = ( props ) => {
  const {
    label,
    value,
    placeholder,

    required,
    percent,
    disabled,
    price,

    onChange,
    helperText,
  } = props;

  const handleOnChange = ( e ) => {
    onChange( e.target.value );
  };

  return (
    <div className="form-input-wrapper">
      { label && (
        <InputLabel style={ props.style }>
          { label }
          <span className="required-start">{ required ? "*" : "" }</span>
        </InputLabel>
      ) }
      <TextField
        type="number"
        className={ props.className }
        fullWidth
        size="small"
        value={ value }
        placeholder={ placeholder }
        variant="outlined"
        onChange={ handleOnChange }
        helperText={ helperText }
        disabled={ disabled ? disabled : false }
        InputProps={ {
          startAdornment: percent ? (
            <InputAdornment position="start">%</InputAdornment>
          ) : price ? (
            <InputAdornment position="start">{ price }</InputAdornment>
          ) : (
            ""
          ),
        } }
      />
    </div>
  );
};

export const FormCalendarInput = ( props ) => {
  const {
    required,
    label,
    value,
    onChange,
    helperText,

    inputFormat,

    disabledAfter,
    disabledBefore,
    tooltip,

    views,
  } = props;

  const disabledDate = ( date ) => {
    if ( views.includes( "year" ) ) {
      const currentYear = new Date().getFullYear();
      const selectedYear = new Date( date ).getFullYear();

      if ( selectedYear < currentYear ) {
        return true;
      }
    }

    if ( disabledAfter ) {
      if ( date > disabledAfter ) {
        return true;
      } else {
        return false;
      }
    } else if ( disabledBefore ) {
      if ( date < disabledBefore ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return (
    <div className="form-input-wrapper">
      { label && (
        <InputLabel>
          { " " }
          { label }
          <span className="required-start">{ required ? "*" : "" }</span>
          { tooltip && (
            <Tooltip title={ tooltip } placement={ "right-start" } arrow={ true }>
              <span className="cursor-pointer">
                <MoreInfoSVG />
              </span>
            </Tooltip>
          ) }
        </InputLabel>
      ) }
      <LocalizationProvider dateAdapter={ AdapterMoment }>
        <DesktopDatePicker
          className="date-picker-style"
          views={ views }
          fullWidth
          disablePast
          inputFormat={ inputFormat }
          value={ value }
          onChange={ onChange }
          renderInput={ ( params ) => (
            <TextField
              size="small"
              fullWidth
              { ...params }
              error={ false }
              helperText={ helperText }
            />
          ) }
          PaperProps={ {
            sx: {
              "& .MuiPickersDay-root": {
                "&.Mui-selected": {
                  color: palette.whiteColor,
                },
              },
            },
          } }
        />
      </LocalizationProvider>{ " " }
    </div>
  );
};

export const FormFilterDateRange = ( props ) => {
  const { required, label, value, onChange } = props;

  const handleChange = ( dates ) => {
    onChange( dates.selection );
  };
  return (
    <div className="form-input-wrapper">
      { label && (
        <InputLabel>
          { label }
          <span className="required-start">{ required ? "*" : "" }</span>
        </InputLabel>
      ) }
      <DateRange
        ranges={ [
          { ...value, key: "selection", color: palette.primaryColorLight },
        ] }
        onChange={ handleChange }
        rangeColors={ "#3d91ff" }
      />
    </div>
  );
};
export const FormRadioButton = ( props ) => {
  const { onChange, data, required, label, value, helperText } = props;
  const { disabled, name, loading, row, optionLabel } = props;

  const [ val, setVal ] = useState( null );
  useEffect( () => {
    setVal( value );
  }, [ value, data ] );

  return (
    <div className="form-input-wrapper">
      <InputLabel>
        { label }
        <span className="required-start">{ required ? "*" : "" }</span>
      </InputLabel>
      { !loading ? (
        <FormControl>
          <RadioGroup row={ row } value={ val?.id } name={ name }>
            { data?.map( ( item ) => {
              return (
                <>
                  <FormControlLabel
                    key={ item.id }
                    disabled={ disabled }
                    value={ item.id }
                    control={ <Radio checked={ val?.id == item.id } /> }
                    label={ optionLabel ? optionLabel : item.name }
                    onClick={ ( e ) => ( disabled ? null : onChange( item ) ) }
                  />
                  { item.icon && (
                    <div className="radio-button-icon">{ item.icon }</div>
                  ) }
                </>
              );
            } ) }
          </RadioGroup>

          { helperText !== "" && <FormHelperText>{ helperText }</FormHelperText> }
        </FormControl>
      ) : (
        <LinearProgress color="primary" />
      ) }
    </div>
  );
};
FormRadioButton.defaultProps = {
  row: true,
};
FormRadioButton.propTypes = {
  row: PropTypes.bool,
};

export const FormFilterAmountRange = ( props ) => {
  const { required, label, value, onChange } = props;

  const handleNumberChange = ( event, newValue ) => {
    onChange( [ newValue[ 0 ], newValue[ 1 ] ] );
  };
  return (
    <div className="form-input-wrapper">
      { label && (
        <InputLabel>
          { " " }
          { label }
          <span className="required-start">{ required ? "*" : "" }</span>
        </InputLabel>
      ) }
      <Slider
        min={ 0 }
        step={ 100 }
        marks={ getMarksArray( 0, 500, 100 ) }
        max={ 500 }
        valueLabelDisplay="auto"
        getAriaLabel={ () => "Temperature range" }
        value={ value }
        onChange={ handleNumberChange }
      />
    </div>
  );
};
export const FormSingleDashedUpload = ( props ) => {
  const {
    type,
    downloadTitle,
    required,
    onChange,
    accept,
    aspectRatio,
    cropped,
    value,
    maxSize,
    name,
    helperText,
  } = props;

  const cropperRef = useRef();
  const [ croppedImage, setCroppedImage ] = useState( null );
  const handleOnChange = ( files ) => {
    onChange( files );
    setCroppedImage( URL.createObjectURL( files ) );
  };

  const urlToFile = async ( url, filename, mimeType ) => {
    const res = await fetch( url );
    const buf = await res.arrayBuffer();
    return new File( [ buf ], filename, { type: mimeType } );
  };

  const handleOnCrop = async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    let cropped_image = cropper?.getCroppedCanvas().toDataURL();
    if ( cropped_image ) {
      let upload_image = await urlToFile(
        cropped_image,
        value?.name,
        "text/plain"
      );

      onChange( upload_image );
    }
  };

  const handleRemoveImage = () => {
    onChange( null );
  };

  let file_type =
    type === "File"
      ? accept
      : type === "Video"
        ? [ "MP4", "MOV", "OGG", "QT" ]
        : [ "JPG", "PNG" ];

  let size_max = type === "File" ? 10 : type === "Video" ? 5 : 1;

  return (
    <div className="form-input-wrapper">
      <>
        { !value ? (
          <div className="form-upload-section">
            <FileUploader
              maxSize={ size_max }
              types={ file_type }
              name={ name }
              onTypeError={ ( error ) => { } }
              onSizeError={ ( error ) => { } }
              classes={ "multiple-file-upload" }
              multiple={ false }
              handleChange={ ( files ) => {
                handleOnChange( files );
              } }
              children={
                <>
                  <UploadIcon />

                  <span>
                    { downloadTitle }
                    <span className="error-start">
                      { required ? "*" : "" }
                      <Tooltip
                        title={ RenderFileInfo(
                          size_max,
                          file_type,
                          aspectRatio == 16 / 9 ? "16/9" : "1/1"
                        ) }
                        placement={ "bottom-end" }
                        arrow={ true }
                      >
                        <span className="cursor-pointer">
                          <MoreInfoSVG />
                        </span>
                      </Tooltip>
                    </span>
                  </span>
                </>
              }
            />
          </div>
        ) : cropped ? (
          <>
            <Cropper
              ref={ cropperRef }
              style={ { height: 300, width: "100%" } }
              zoomTo={ 0.5 }
              initialAspectRatio={ 1 }
              aspectRatio={ aspectRatio }
              src={ croppedImage }
              viewMode={ 1 }
              crop={ handleOnCrop }
              background={ true }
              responsive={ true }
              autoCropArea={ 1 }
              checkOrientation={ false }
              guides={ false }
            />
            <div
              style={ {
                display: "flex",
                flexFlow: "row nowrap",
                alignItems: "flex-end",
              } }
            >
              <LinkButton
                startIcon={ <DeleteSVG /> }
                text="Remove"
                deleteButton
                onClick={ () => handleRemoveImage() }
              />
            </div>
          </>
        ) : (
          <div>
            <CardMedia
              component="img"
              alt="Image"
              height="200px"
              image={
                value
                  ? value instanceof File
                    ? URL.createObjectURL( value )
                    : imagesURL + value
                  : null
              }
            />
            <div
              style={ {
                display: "flex",
                flexFlow: "row nowrap",
                alignItems: "center",
                justifyContent: "flex-end",
              } }
            >
              <LinkButton
                startIcon={ <DeleteSVG /> }
                deleteButton
                text="Remove"
                default
                onClick={ () => handleRemoveImage() }
              />
            </div>
          </div>
        ) }
        { helperText && (
          <FormControl>
            <FormHelperText>{ helperText }</FormHelperText>
          </FormControl>
        ) }
      </>
    </div>
  );
};
FormSingleDashedUpload.defaultProps = {
  downloadTitle: "Drop your file(s) here or browse",
};
FormSingleDashedUpload.propTypes = {
  downloadTitle: PropTypes.string,
};

export const FormDateRange = ( props ) => {
  const datepickerRef = useRef( null );

  const { label, value, onChange } = props;
  const [ show, setShow ] = useState( false );
  const startDate = value[ 0 ] ? new Date( value[ 0 ] ) : null;

  const endDate = value[ 1 ] ? new Date( value[ 1 ] ) : null;

  const handleOnChange = ( dates ) => {
    const [ start, end ] = dates;

    onChange( [
      start ? moment( start ).format( "YYYY-MM-DD" ) : null,
      end ? moment( end ).format( "YYYY-MM-DD" ) : null,
    ] );
  };

  useEffect( () => {
    if ( show ) {
      const datepickerElement = datepickerRef.current;
      datepickerElement.setFocus( true );
    } else {
      const datepickerElement = null;
    }
  }, [ show ] );

  return (
    <div className="form-input-wrapper">
      { label && <InputLabel>{ label }</InputLabel> }
      <div style={ { position: "relative" } }>
        <DatePicker
          wrapperClassName="react-date-range-picker"
          selected={ startDate }
          onChange={ handleOnChange }
          startDate={ startDate }
          endDate={ endDate }
          selectsRange
          ref={ datepickerRef } //
        />
        <div style={ { position: "absolute", right: "0%", bottom: "20%" } }>
          <IconsButton
            onClick={ () => {
              setShow( !show );
            } }
            icon={ <CalendarSVG /> }
          />
        </div>
      </div>
    </div>
  );
};
export const FormPromoCode = ( props ) => {
  const {
    helperText,
    disabled,
    hasCoupon,
    label,
    placeholder,
    className,
    is_valid,
    loading,
  } = props;
  const { value, onChange, required, type, name, maxLength, variant } = props;

  return (
    <div className="form-input-wrapper" style={ { width: "100%" } }>
      { label && (
        <>
          <InputLabel>
            { label }
            <span className="required-start">{ required ? " * " : "" }</span>
          </InputLabel>

          { hasCoupon ? (
            <InputLabel
              style={ { color: palette.primaryColorLight, marginTop: "-5px" } }
            >
              You already have a valid coupon. Even if this field is empty
              <span className="highlighted-text"> "{ hasCoupon }" </span>
              will be applied
            </InputLabel>
          ) : (
            <InputLabel style={ { color: "grey", marginTop: "-5px" } }>
              If you have a valid promo code,please write it down, otherwise
              leave this field empty
            </InputLabel>
          ) }
        </>
      ) }
      <TextField
        fullWidth
        size="small"
        variant={ variant }
        type={ type }
        className={ className }
        value={ value }
        maxLength={ maxLength }
        placeholder={ placeholder }
        helperText={ helperText }
        disabled={ disabled }
        onChange={ onChange }
        name={ name }
        InputProps={ {
          endAdornment: (
            <InputAdornment
              position={ "end" }
              sx={ {
                backgroundColor: palette.whiteColor,
              } }
            >
              { loading ? (
                <CircularProgress color="inherit" size={ 20 } />
              ) : (
                <IconButton>
                  { value !== "" && value !== null ? (
                    is_valid ? (
                      <CheckCircleIcon style={ { color: palette.greenColor } } />
                    ) : (
                      <CancelIcon style={ { color: "red" } } />
                    )
                  ) : null }
                </IconButton>
              ) }
            </InputAdornment>
          ),
        } }
      />
    </div>
  );
};
FormPromoCode.defaultProps = {
  variant: "outlined",
  maxLength: null,
};
FormPromoCode.propTypes = {
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  maxLength: PropTypes.any,
};
export const FormStripeCardElement = ( props ) => {
  const {
    ref,
    label, required, onChange, helperText,
  } = props;



  return (
    <div className={ `form-input-wrapper` }>
      { label && (
        <InputLabel style={ props.style }>
          { label }
          <span className="required-start">{ required ? "*" : "" }</span>
        </InputLabel>
      ) }
      <div className={ 'stripe-card-element' }>
        <CardElement ref={ ref } options={ { hidePostalCode: true } } onChange={ onChange } /></div>  {
        helperText && (
          <FormControl>
            <FormHelperText>{ helperText }</FormHelperText>
          </FormControl>
        )
      }
    </div>

  );
};

