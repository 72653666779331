//UTILITIES
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { AlertContext } from "../../../../context/AlertContext";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
//COMPONENT
import { PrimaryButton } from "../../../../components/buttons/Buttons";
import { FormInput } from "../../../../components/form-component/FormComponent";

//CSS
import "./EmailVerification.scss";

//API
import {
  emailVerification,
} from "../../../../core/apis/users";
import { Card, CardContent } from "@mui/material";
import TitleComponent from "../../../../components/title-component/TitleComponent";
import { emailSchema } from "../../../../core/validators/form-validators";

const HandleSchema = yup.object( {
  email: emailSchema,
} );

export function EmailVerification() {
  //VARIABLES
  let navigate = useNavigate();
  const { setAlert } = useContext( AlertContext );
  const [ saveLoading, setSaveLoading ] = useState( false );
  //INITIATE USE FORM
  const { control, handleSubmit, reset, getValues } = useForm( {
    resolver: yupResolver( HandleSchema ),
  } );



  //SUBMIT FORM
  const handleSubmitForm = ( formData ) => {
    setSaveLoading( true );
    emailVerification( {
      ...formData,
      url: `${ process.env.REACT_APP_USER }signup-form/`,
    } ).then( ( response ) => {
      if ( response?.data?.success ) {
        navigate( "/email-sent" );
      }
      setAlert( {
        text: response.data ? response.data.message : response.message,
        error: response?.data?.success ? false : true,
      } );
      setSaveLoading( false );
    } );

  };

  const keyDownHandler = ( event ) => {
    if ( event.key === "Enter" ) {
      event.preventDefault();

      // 👇️ call submit function here
      handleSubmitForm( getValues() );
    }
  };

  useEffect( () => {
    reset( {
      email: "",
    } );
  }, [] );

  useEffect( () => {
    document.addEventListener( "keydown", keyDownHandler );
    return () => {
      document.removeEventListener( "keydown", keyDownHandler );
    };
  }, [ getValues() ] );

  return (
    <div className="page-container">
      <Card className="emailverify-container">
        <CardContent className="content">
          <TitleComponent
            title="Sign Up"
            classNames="form-title main-title"
          />
          <form onSubmit={ handleSubmit( handleSubmitForm ) } className="verify-form">

            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  isAuth
                  name="email"
                  label="Email"
                  placeholder={ "Enter email" }
                  value={ value }
                  onChange={ ( value ) => {
                    onChange( value );
                  } }
                  helperText={
                    error?.message
                  }
                />
              ) }
              name="email"
              control={ control }
            />

            <div className="flex-column-wrap verify-action">
              <PrimaryButton
                type="submit"
                className="verify-button"
                text={ "Submit" }
                loading={ saveLoading }
                disabled={ saveLoading ? true : false }
              />
              <div className="account-question">
                Already have an account?{ " " }
                <span className="action" onClick={ () => navigate( "/login" ) }>
                  Login
                </span>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default EmailVerification;
