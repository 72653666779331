//UTILTIIES
import React, { useState, useEffect, useRef, useContext } from "react";
import ReactToPrint from "react-to-print";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

//COMPONENT
import CeciToolLogo from "../../../assets/images/ceci_tool_logo.png";

import TitleComponent from "../../../components/title-component/TitleComponent";
import { PrimaryButton } from "../../../components/buttons/Buttons";

import { Skeleton, TableBody, TableContainer, TableRow } from "@mui/material";
import { PrintIcon } from "../../../assets/svg/SVG";
import { AlertContext } from "../../../context/AlertContext";

//CSS
import "./Invoices.scss";
//API
import { getInvoiceById } from "../../../core/apis/invoices";
import {
  InvoiceTable,
  InvoiceTableCell,
  InvoiceTableHead,
} from "../../../assets/theme/SpecialComponent";

export function InvoicesDetail( props ) {
  //VARIABLES
  const printRef = useRef();
  let params = useParams();
  let navigate = useNavigate();
  const { setAlert } = useContext( AlertContext );
  let id = params?.id;
  const [ data, setData ] = useState( null );

  const [ loading, setLoading ] = useState( false );

  //FUNCTIONS
  const GetData = () => {
    setLoading( true );
    getInvoiceById( id ).then( ( response ) => {
      if ( response?.data?.success ) {
        setData( response?.data?.data );
      }
      setAlert( {
        text: response.data ? response?.data?.message : response?.message,
        error: response?.data?.success ? false : true,
      } );
      setLoading( false );
    } );
  };

  useEffect( () => {
    GetData();
  }, [] );

  return !loading ? (
    <>
      { " " }
      <TitleComponent
        classNames="page-title main-title"
        back
        handleBack={ () => navigate( -1 ) }
        handleEndAction={
          <ReactToPrint
            pageStyle="@page { margin: 30mm; }"
            content={ () => {
              return printRef.current;
            } }
            trigger={ () => (
              <PrimaryButton startIcon={ <PrintIcon /> } text={ "Print" } />
            ) }
          />
        }
      />
      <div className="invoice-detail-component">
        <div ref={ printRef }>
          <div className="invoice-content">
            <div className="invoice-detail-header">
              <div className="invoice-detail-ln">
                <div className="invoice-detail-logo">
                  { " " }
                  <div className="logo">
                    <img src={ CeciToolLogo } alt="LOGO" />
                  </div>
                </div>
                <div className="invoice-detail-number">
                  <div>Invoice</div>
                  <div>{ data?.invoice_number }</div>
                </div>
              </div>
              <div className="invoice-detail-company">
                <div className="main-company-title">
                  SYSTEMIC MANAGEMENT, INC.
                </div>
                <div>R.U.C. 155612763 2 2015 D.V. 18</div>
                <div className="main-company-address">
                  Torre IBC, Oficina 504
                </div>
                <div className="main-company-address">
                  Ave. Manuel Espinosa Batista
                </div>
                <div className="main-company-address">
                  Panamá,República de Panamá
                </div>
              </div>
            </div>
            <div className="invoice-detail-body">
              <div className="body-element">
                <div>Subscriber's Name</div>
                <div>{ `${ data?.user_first_name ? data?.user_first_name : "" } ${ data?.user_last_name ? data?.user_last_name : ""
                  }` }</div>
              </div>

              <div className="body-element">
                <div>Company's Name</div>
                <div>
                  { data?.company_name ? data?.company_name : "No Company" }
                </div>
              </div>

              <div className="body-element">
                <div>Full Address</div>
                <div>
                  { data?.user_country
                    ? data?.user_country +
                    " , " +
                    data?.user_city +
                    " , " +
                    data?.user_postal_code
                    : "Not Provided" }
                </div>
              </div>
              <div className="body-element">
                <div>Fiscal Number</div>
                <div>
                  { data?.fiscal_number ? data?.fiscal_number : "Not Provided" }
                </div>
              </div>
              <div className="body-element">
                <div>Phone Number</div>
                <div>{ data?.user_phone_number }</div>
              </div>
            </div>
            <div className="invoice-detail-body">
              <div className="body-element">
                <div>Card</div>
                <div>{ `${ data?.payments?.[ 0 ]?.charge?.card_brand } **** **** **** ${ data?.payments?.[ 0 ]?.charge?.latest_4 }` }</div>
              </div>
            </div>
            <div className="invoice-detail-table">
              <TableContainer className="invoice-table-container">
                <InvoiceTable aria-label="spanning table">
                  <InvoiceTableHead>
                    <TableRow>
                      <InvoiceTableCell colSpan={ 2 }>
                        Subscription Description
                      </InvoiceTableCell>

                      <InvoiceTableCell colSpan={ 2 }>Price</InvoiceTableCell>
                    </TableRow>
                  </InvoiceTableHead>

                  <TableBody>
                    <TableRow style={ { borderBottom: "none" } }>
                      <InvoiceTableCell
                        style={ {
                          borderBottom: "none",
                          borderRight: "none",
                          borderTop: "none",
                        } }
                      >
                        { `${ data?.invoice_items?.[ 0 ]?.item_name } | ${ data?.invoice_items?.[ 0 ]?.itemable?.plan_price?.package_duration } ${ data?.invoice_items?.[ 0 ]?.itemable?.plan_price?.package_duration_type }` }
                      </InvoiceTableCell>

                      <InvoiceTableCell style={ { border: "none" } } />

                      <InvoiceTableCell
                        style={ {
                          borderBottom: "none",
                          borderRight: "none",
                        } }
                      >
                        Net Total
                      </InvoiceTableCell>
                      <InvoiceTableCell
                        style={ {
                          borderBottom: "none",
                          borderLeft: "none",
                          borderTop: "none",
                          textAlign: "right",
                        } }
                      >
                        { data?.currency?.symbol + " " + data?.subtotal }
                      </InvoiceTableCell>
                    </TableRow>
                    <TableRow>
                      <InvoiceTableCell
                        style={ {
                          borderBottom: "none",
                          borderRight: "none",
                          borderTop: "none",
                        } }
                      >
                        Start Date | End Date
                      </InvoiceTableCell>

                      <InvoiceTableCell
                        style={ {
                          borderBottom: "none",
                          borderLeft: "none",
                          borderTop: "none",
                        } }
                      >
                        { `${ moment(
                          data?.invoice_items?.[ 0 ]?.itemable?.package_starts_at
                        ).format( "DD-MM-YYYY" ) } | ${ moment(
                          data?.invoice_items?.[ 0 ]?.itemable?.package_ends_at
                        ).format( "DD-MM-YYYY" ) }  ` }
                      </InvoiceTableCell>
                      <InvoiceTableCell style={ { border: "none" } }>
                        Discount
                      </InvoiceTableCell>
                      <InvoiceTableCell
                        style={ {
                          borderTop: "none",
                          borderLeft: "none",
                          textAlign: "right",
                        } }
                      >
                        { data?.currency?.symbol + " " + data?.discounted_value }
                      </InvoiceTableCell>
                    </TableRow>

                    <TableRow>
                      <InvoiceTableCell
                        style={ {
                          borderRight: "none",
                          borderTop: "none",
                        } }
                      ></InvoiceTableCell>

                      <InvoiceTableCell
                        style={ {
                          borderLeft: "none",
                          borderTop: "none",
                        } }
                      ></InvoiceTableCell>
                      <InvoiceTableCell style={ { borderRight: "none" } }>
                        Total
                      </InvoiceTableCell>
                      <InvoiceTableCell
                        style={ { borderLeft: "none", textAlign: "right" } }
                      >
                        { data?.currency?.symbol + " " + data?.total }
                      </InvoiceTableCell>
                    </TableRow>
                  </TableBody>
                </InvoiceTable>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Skeleton />
  );
}

export default InvoicesDetail;
