import React, { useContext, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { ImageDelete, StarIcon } from "../../../assets/svg/SVG";
import { UserContext } from "../../../context/UserContext";
import { imagesURL } from "../../../core/apis/main";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function ImageComponent( props ) {
  const { background, data, handleDelete, canDrag } = props;
  const { user } = useContext( UserContext );
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable( { id: data?.id } );

  const style = {
    transform: CSS.Transform.toString( transform ),
    transition,
    cursor: canDrag ? "grab" : "",
  };


  return (
    <div
      ref={ setNodeRef }
      style={ style }
      { ...attributes }
      className="image-component"
    >
      <div className="image-container">
        <LazyLoadImage
          key={ data?.id }
          alt={ data?.name || "scene-images" }
          src={ `${ imagesURL }${ data?.path }` }
          style={ {
            width: 120,
            height: background ? 80 : 120,
          } }
          className={ isDragging && "selected-image" }

        />
        {/* <img
          className={ isDragging && "selected-image" }
          src={ imagesURL + data.path }
          width={ "180px" }
          height={ background ? "80px" : "180px" } //the best for ratio 16/9
        /> */}

        { data?.is_admin === 0 && (
          <div className={ `image-vector` } onClick={ () => handleDelete( data ) }>
            <ImageDelete />
          </div>
        ) }
      </div>
      <div className="createdby-username">
        { data?.is_admin === 1 ? (
          <div className="admin-name">
            Admin
            <span className="admin-start">
              <StarIcon />
            </span>
          </div>
        ) : (
          "Me"
        ) }
      </div>
    </div>
  );
}
export default ImageComponent;
