//UTILITIES
import React, { useState } from "react";
import sizes from "../../../assets/theme/variables.scss"
import { useMediaQuery } from 'react-responsive'
import Check from "@mui/icons-material/Check";
import { PlanOption } from "./PlanOption";
import CardManagement from "../cards/CardManagement";
import AddressHandle from "../address/AddressHandle";
import TitleComponent from "../../../components/title-component/TitleComponent";
import {
  Step,
  StepLabel,
  Stepper,
  styled,
  StepConnector,
  stepConnectorClasses,
  StepContent,
} from "@mui/material";
import "./Checkout.scss";
import { AccountInformation } from "./AccountInformation";

const QontoConnector = styled( StepConnector )( ( { theme } ) => ( {
  [ `&.${ stepConnectorClasses.active }` ]: {
    [ `& .${ stepConnectorClasses.line }` ]: {
      borderColor: "#00172D",
    },
  },
  [ `&.${ stepConnectorClasses.completed }` ]: {
    [ `& .${ stepConnectorClasses.line }` ]: {
      borderColor: "#00172D",
    },
  },
  [ `& .${ stepConnectorClasses.line }` ]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[ 800 ] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
} ) );

const QontoStepIconRoot = styled( "div" )( ( { theme, ownerState } ) => ( {
  color: theme.palette.mode === "dark" ? theme.palette.grey[ 700 ] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...( ownerState.active && {
    color: "#00172D",
  } ),
  "& .QontoStepIcon-completedIcon": {
    color: "#00172D",
    zIndex: 1,
    fontWeight: 700,
    fontSize: 20,
  },
  "& .QontoStepIcon-circle": {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
} ) );

function QontoStepIcon( props ) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={ { active } } className={ className }>
      { completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      ) }
    </QontoStepIconRoot>
  );
}

export function CheckoutHandle( props ) {
  //VARIABLES
  const isMediumScreen = useMediaQuery( { query: `(max-width:900px)` } )
  const [ activeStep, setActiveStep ] = useState( 0 );

  const [ formValues, setFormValues ] = useState( {
    fiscal_number: "",
    company_name: "",
    address_id: "",
    has_company: false,
    //because has_company is boolean and to know if he has or hasn't or he didn't add input
    has_company_changed: false,
    upgrade_now: false,
    unsaved_card: null,
    card: null,
    auto_renew: true,
    plan_price_id: "",
    coupon_code_changed: false,
    has_coupon: "",
    coupon_code: "",
    total: "",
  } );

  const handleStep = ( { prev, next } ) => {
    if ( next ) {
      setActiveStep( activeStep + 1 );
    } else {
      setActiveStep( activeStep - 1 );
    }
  };

  const steps = [
    {
      label: "Edit Information",
      content: (
        <AccountInformation
          handleStep={ handleStep }
          formValues={ formValues }
          setFormValues={ setFormValues }
        />
      ),
    },
    {
      label: "Billing Address",
      content: (
        <AddressHandle
          is_checkout={ true }
          handleStep={ handleStep }
          formValues={ formValues }
          setFormValues={ setFormValues }
        />
      ),
    },
    {
      label: "Plan Package",
      content: (
        <PlanOption
          handleStep={ handleStep }
          formValues={ formValues }
          setFormValues={ setFormValues }
        />
      ),
    },
    {
      label: "Payment",
      content: (
        <CardManagement
          is_checkout={ true }
          handleStep={ handleStep }
          formValues={ formValues }
          setFormValues={ setFormValues }
        />
      ),
    },
  ];



  return (
    <>
      <TitleComponent title="Check Out" classNames="page-title main-title" />

      <div className="stepper-checkout">

        <Stepper
          orientation={ isMediumScreen ? "vertical" : "horizontal" }
          alternativeLabel={ !isMediumScreen }
          activeStep={ activeStep }
          connector={ <QontoConnector /> }
        >
          { steps.map( ( step, index ) => (
            <Step key={ step.label }>
              <StepLabel
                StepIconComponent={ QontoStepIcon }
                className="step-checkout-label"
              >
                { step.label }
              </StepLabel>
              { isMediumScreen && ( <StepContent>{ steps[ activeStep ].content }</StepContent> ) }
            </Step>
          ) ) }
        </Stepper>
      </div>
      { !isMediumScreen && ( <div className="margin-up-20 content">{ steps[ activeStep ].content }</div> ) }
    </>
  );
}
export default CheckoutHandle;
