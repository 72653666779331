import React, { useContext, useEffect } from "react";
import {
  AccountIcon,
  ImageIcon,
  ReceiptsSVG,
  SessionIcon,
  SubscriptionsIcon,
} from "../../assets/svg/SideBarSVG";

export const SideBarItems = [
  {
    id: "sessions",
    text: "Sessions",
    route: "/sessions",
    is_subscribed: true,
    role: "show-session",
    icon: <SessionIcon />,
  },
  {
    id: "images",
    text: "Upload",
    is_subscribed: true,
    icon: <ImageIcon />,
    children: [
      {
        id: "background-image",
        text: "Background",
        route: "/background-images",
        is_children: true,
      },
      {
        id: "board-image",
        text: "Board",
        route: "/board-images",
        is_children: true,
      },
    ],
  },
  {
    id: "subscriptions",
    text: "Subscriptions",
    route: "/user-subscriptions",
    is_subscribed: false,
    icon: <SubscriptionsIcon />,
  },

  {
    id: "invoices",
    text: "Invoices",
    route: "/invoices",
    role: "show-receipts",
    is_subscribed: true,
    icon: <ReceiptsSVG />,
  },

  {
    id: "account",
    text: "Account",
    route: "/account",
    role: "show-account",
    is_subscribed: false,
    icon: <AccountIcon />,
  },
];
