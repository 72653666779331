import { api } from "./main";

export const getAllInvoices = (payload) => {
  let page = payload?.page ? `?page=${payload.page}` : "";

  return api
    .get(`invoices${page}`, { params: { ...payload } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getInvoiceById = (payload) => {
  return api
    .get(`invoice/${payload}`, { params: { ...payload } })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const exportInvoices = (payload) => {
  return api
    .get(`export-invoices`, {
      params: { ...payload },
      responseType: "blob",
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
